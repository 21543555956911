import { IconButton, Grid, TextField, Button, Link } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import React, { createRef, useContext, useRef, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';

import FormatAlignLeftIcon from '@material-ui/icons/FormatAlignLeft';
import FormatAlignCenterIcon from '@material-ui/icons/FormatAlignCenter';
import FormatAlignRightIcon from '@material-ui/icons/FormatAlignRight';
import FormatBoldIcon from '@material-ui/icons/FormatBold';
import FormatItalicIcon from '@material-ui/icons/FormatItalic';

import TitleIcon from '@material-ui/icons/Title';
import TextFieldsIcon from '@material-ui/icons/TextFields';
import FormatListNumberedRtlIcon from '@material-ui/icons/FormatListNumberedRtl';

import WrapTextIcon from '@material-ui/icons/WrapText';
import ExposureIcon from '@material-ui/icons/Exposure';

import DeleteIcon from '@material-ui/icons/Delete';
import RotateRight from '@material-ui/icons/RotateRight';
import RotateLeft from '@material-ui/icons/RotateLeft';
import AddIcon from '@material-ui/icons/Add';

import Tooltip from '@material-ui/core/Tooltip';
import { SystemFieldType } from '../../../../api';
import classNames from 'classnames';
import { getValue, setValue } from './utils';
import { debounce, pick } from 'underscore';
import { mmToPx, pxTomm } from '../BadgeView/BadgeView';
import { AppContext } from '../../../../AppContext';
import FontAdd from './FontAdd'
import { defaultFonts } from 'common/fonts';
import { MeetupContext} from 'MeetupContext'

export const buildFieldSchema = fields => {
  // В этом методе мы строим список полей для меню выбора поля для добавления.
  // Сначала добавляем системные, встроенные производные поля - ИФ, ИО, ФИО (проверяя что отчество есть в схеме)
  // Затем добавляем все поля из редактируемого списка
  // Затем добавляем графические производные - qr/bar, image

  let ret = [];

  ret.push({
    value: '{{firstName}} {{lastName}}',
    label: 'Имя Фамилия',
    type: 'text',
  });

  if (fields.some(f => f.systemField === SystemFieldType.MiddleName)) {
    ret.push({
      value: '{{lastName}} {{firstName}} {{middleName}}',
      label: 'ФИО',
      type: 'text',
    });

    ret.push({
      value: '{{firstName}} {{middleName}}',
      label: 'ИО',
      type: 'text',
    });
  }

  fields.forEach(f => {
    if (f.systemField) {
      if (f.systemField === SystemFieldType.Group) {
        ret.push({
          value: '{{groupName}}',
          label: 'Группа',
          type: 'text',
        });
      } else if (f.systemField === SystemFieldType.Photo) {
        ret.push({
          value: '{{' + f.systemField + '}}',
          label: 'Фото',
          type: 'image',
        });
      } else {
        ret.push({
          value: '{{' + f.systemField + '}}',
          label: f.label,
          type: 'text',
        });
      }
    } else {
      ret.push({
        value: '{{extended.' + f.name + '}}',
        label: f.label,
        type: 'text',
      });
    }
  });

  ret.push({
    value: '',
    label: 'Текст',
    type: 'textarea',
  });

  ret.push({
    value: '',
    label: 'Изображение',
    type: 'image',
  });

  ret.push({
    value: '{{qrToken}}',
    label: 'BAR-код',
    type: 'bar',
  });
  ret.push({
    value: '{{qrToken}}',
    label: 'QR-код',
    type: 'qr',
  });
  ret.push({
    value: '{{qrToken}}',
    label: 'QR-код (текст)',
    type: 'text',
  });
  ret.push({
    value: '{{visitorId}}',
    label: 'ID посетителя',
    type: 'text',
  });
  ret.push({
    value: '{{makeContactLink}}',
    label: 'QR-код для визитки',
    type: 'qr',
  });

  return ret;
};

export const key = f => {
  return f.type + '-' + f.value + '-' + f.label + '-' + f.text;
};

export const typeFromKey = key => {
  if (!key || key.indexOf('-') === -1) {
    return 'text';
  }
  return key.split('-')[0];
};
export const textFromKey = key => {
  if (!key || key.indexOf('-') === -1) {
    return key;
  }
  return key.split('-')[1];
};

export const fieldByTypeAndText = (fields, type, value) => {
  const r = fields.filter(f => {
    return f.type === type && f.value === value;
  });
  if (r.length === 1) {
    return r[0];
  }
  if (type === 'image') {
    return fields[fields.length - 1];
  }
  if (type !== 'text') {
    const found = fields.find(f => f.type === type);
    if (found) {
      return found;
    }
  }

  return {
    value: value,
    type:  type,
    label: value
  };
};

const useStyles = makeStyles(theme => ({
  root: {},
  smallField: {
    margin: '0px',
    '& input': {
      padding: '3px',
    },
    '& div': {
      padding: '3px',
    },
  },
  iconButton: {
    display: 'inline-flex',
    padding: '2px',
    margin: '1px',
    color: 'blue',
    background: 'lightsteelblue',
    borderRadius: '4px',
    cursor: 'pointer',
  },
  iconButtonToggled: {
    color: 'red',
  },
}));



const FontField = props => {
  const { value, onChange } = props;
  const classes = useStyles();
  const appContext = useContext(AppContext)
  // console.log(appContext)
  let savedFonts =  JSON.parse(window.localStorage.getItem('fonts' + appContext.session.accountId)) || [] 
  return (
    <TextField
      className={clsx(classes.smallField)}
      select
      fullWidth
      label={'Шрифт'}
      margin='dense'
      variant='outlined'
      autoComplete='new-password'
      // helperText={error('phoneNumber')}
      // error={error('phoneNumber') != null}
      value={value}
      onChange={e => onChange(e.target.value)}
      style={{ width: '180px' }}
      data-test-id='badge-font-select'
    >
      {
        defaultFonts.map(font => (
          <MenuItem value={font} key={font}>{font}</MenuItem>
        ))
      }
      {
        savedFonts.map(font => (
          <MenuItem value={font.family} key={font.family}>{font.family}</MenuItem>
        ))
      }
      <MenuItem value={'add'} style={{borderTop: '1px solid black'}}>
        Добавить шрифт <AddIcon style={{marginLeft: 10}}/> 
      </MenuItem>
    </TextField>
  );
};

const barcodeFormats = [
  'CODE128',
  'CODE39',
  'EAN13',
  'EAN8',
  'EAN5',
  'EAN2',
  'UPC',
  'ITF',
  'ITF14',
  'MSI',
  'MSI10',
  'MSI11',
  'MSI1010',
  'MSI1110',
  'Pharmacode',
];

const BarcodeFormatField = props => {
  const { value, onChange } = props;
  const classes = useStyles();
  return (
    <TextField
      className={clsx(classes.smallField)}
      select
      fullWidth
      label={'Формат баркода'}
      margin='dense'
      variant='outlined'
      autoComplete='new-password'
      // helperText={error('phoneNumber')}
      // error={error('phoneNumber') != null}
      value={value}
      onChange={e => onChange(e.target.value)}
      style={{ width: '180px' }}
    >
      {barcodeFormats.map((format, index) => (
        <MenuItem key={format} value={format}>
          {format}
        </MenuItem>
      ))}
    </TextField>
  );
};

const SmallField = props => {
  const { value, onChange, label, title, valueType = 'px', ...rest } = props;
  const classes = useStyles();
  return (
    <Tooltip title={title}>
      <TextField
        className={clsx(classes.smallField)}
        {...rest}
        fullWidth
        label={label}
        margin='dense'
        variant='outlined'
        autoComplete='new-password'
        // helperText={error('phoneNumber')}
        // error={error('phoneNumber') != null}
        value={valueType === 'mm' ? Math.ceil(pxTomm(value, 72)) : value}
        onChange={e => {
          onChange(valueType === 'mm' ? mmToPx(e.target.value, 72) : e.target.value);
        }}
        style={{ width: '43px', marginRight: '2px' }}
      />
    </Tooltip>
  );
};

const SmallIcon = props => {
  const { value, onChange, children, title, ...rest } = props;
  const classes = useStyles();

  const toggle = () => {
    if (onChange) {
      onChange(!value);
    }
  };

  return (
    <Tooltip title={title}>
      <div className={clsx(classes.iconButton, value ? classes.iconButtonToggled : null)} onClick={toggle} {...rest}>
        {children}
      </div>
    </Tooltip>
  );
};

export const BadgeField = props => {
  const { fieldSchema, field, onChange } = props;

  const timeout = useRef(0);

  const appContext = useContext(AppContext);
  let savedFonts =  JSON.parse(window.localStorage.getItem('fonts' + appContext.session.accountId)) || [] 
  
  const [isShown, setIsShown] = useState(false);

  const [error, setError] = useState();

  const uploadImage = event => {
    const file = event.target.files[0]
    appContext.imageApi.uploadImage(undefined, undefined, file).then(({ data }) => {
      field.backgroundColor = data.imageId;
      setError();
      onChange(field);
    })
    .catch(error => setError(error));
  };


  if (!field) {
    return null;
  }

  const parseIntNotNan = v => {
    let value = parseInt(v);
    if (Number.isNaN(value)) {
      value = 0;
    }
    return value;
  };

  

  const changeFontFamily = value => {
    if (value === 'add') {
      setIsShown(true);
      return;
    }
    field.font = value;
    onChange(field);
  };

  const textareaInputChange = event => {
    field.text = event.target.value;
    const debounced = debounce(() => {
      onChange(field);
    }, 500);
    debounced();
  };

  const inputImageRef = createRef();

  const removeImage = () => {
    field.backgroundColor = 'default-background-image';
    inputImageRef.current.value = '';
    onChange(field);
  };

  const setColor = event => {
    if (timeout.current) {
      clearTimeout(timeout.current);
    }
    const value = event.target.value;
    timeout.current = setTimeout(() => {
      field.backgroundColor = value;
      onChange(field);
    }, 300);
  };

  const handleClose = () => {
    setIsShown(false)
  }
  return (
    <>
    <FontAdd isShown={isShown} handleClose={handleClose} changeFontFamily={changeFontFamily} currentFontList={savedFonts.map(font => font.family).concat(defaultFonts)}/>
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Typography variant={'h5'} style={{ paddingTop: '22px' }}>
          {fieldByTypeAndText(fieldSchema, field.type, field.text).label}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <SmallField
              label={'Пх'}
              valueType={'mm'}
              title={'Расположение по горизонтали (мм)'}
              value={field.position.x}
              onChange={v => {
                field.position.x = parseIntNotNan(v);
                onChange(field);
              }}
              style={{ marginRight: '8px' }}
            />
            <SmallField
              label={'Пy'}
              valueType={'mm'}
              title={'Расположение по вертикали (мм)'}
              value={field.position.y}
              onChange={v => {
                field.position.y = parseIntNotNan(v);
                onChange(field);
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <SmallField
              label={'Рх'}
              valueType={'mm'}
              title={'Ширина поля (мм)'}
              value={field.size.x}
              onChange={v => {
                field.size.x = parseIntNotNan(v);
                onChange(field);
              }}
            />
            <SmallField
              label={'Рy'}
              valueType={'mm'}
              title={'Высота поля (мм)'}
              value={field.size.y}
              onChange={v => {
                field.size.y = parseIntNotNan(v);
                onChange(field);
              }}
            />
          </Grid>
          {field.type === 'text' || field.type === 'textarea' ? (
            <>
              <Grid item xs={9}>
                <FontField value={field.font} title={'Шрифт'} onChange={changeFontFamily} />
              </Grid>
              <Grid item xs={3}>
                <SmallField
                  label={''}
                  title={'Размер шрифта (мм)'}
                  value={field.fontSize}
                  onChange={v => {
                    field.fontSize = v;
                    onChange(field);
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <SmallIcon
                  title={'Жирный'}
                  value={field.bold}
                  onChange={v => {
                    field.bold = v;
                    onChange(field);
                  }}
                >
                  <FormatBoldIcon />
                </SmallIcon>
                <SmallIcon
                  title={'Наклонный'}
                  value={field.italics}
                  onChange={v => {
                    field.italics = v;
                    onChange(field);
                  }}
                >
                  <FormatItalicIcon />
                </SmallIcon>

                <SmallIcon
                  title={'По левому краю'}
                  value={field.align === 'left'}
                  onChange={v => {
                    field.align = 'left';
                    onChange(field);
                  }}
                  style={{ marginLeft: '8px' }}
                >
                  <FormatAlignLeftIcon />
                </SmallIcon>
                <SmallIcon
                  title={'По центру'}
                  value={field.align === 'center'}
                  onChange={v => {
                    field.align = 'center';
                    onChange(field);
                  }}
                >
                  <FormatAlignCenterIcon />
                </SmallIcon>
                <SmallIcon
                  title={'По правому краю'}
                  value={field.align === 'right'}
                  onChange={v => {
                    field.align = 'right';
                    onChange(field);
                  }}
                  style={{ marginRight: '8px' }}
                >
                  <FormatAlignRightIcon />
                </SmallIcon>

                <SmallIcon
                  title={'Автоматически уменьшать размер шрифта'}
                  value={field.adjustSize}
                  onChange={v => {
                    field.adjustSize = v;
                    onChange(field);
                  }}
                >
                  <ExposureIcon />
                </SmallIcon>
                <SmallIcon
                  title={'Разрешить перенос текста'}
                  value={field.allowWrap}
                  onChange={v => {
                    field.allowWrap = v;
                    onChange(field);
                  }}
                  style={{ marginRight: '8px' }}
                >
                  <WrapTextIcon />
                </SmallIcon>

                <SmallIcon
                  title={'ВСЕ ЗАГЛАВНЫЕ БУКВЫ'}
                  value={field.allCaps}
                  onChange={v => {
                    field.allCaps = v;
                    field.firstCaps = false;
                    onChange(field);
                  }}
                >
                  <TitleIcon />
                </SmallIcon>
                <SmallIcon
                  title={'Первая Буква Заглавная'}
                  value={field.firstCaps}
                  onChange={v => {
                    field.firstCaps = v;
                    field.allCaps = false;
                    onChange(field);
                  }}
                >
                  <TextFieldsIcon />
                </SmallIcon>
              </Grid>
            </>
          ) : null}
          {field.type === 'textarea' ? (
            <Grid item xs={12}>
              <TextField
                id='outlined-multiline-static'
                label='Текст'
                multiline
                autoFocus={true}
                fullWidth
                variant='outlined'
                defaultValue={field.text}
                rows={4}
                autoSize
                onChange={textareaInputChange}
                data-test-id='badge-text-edit'
              />
            </Grid>
          ) : null}

          {field.type === 'text' || field.type === 'textarea' ? (
            <Grid item xs={12}>
              <SmallIcon title={'Изменить цвет фона'}>
                <input
                  type='color'
                  style={{ width: 30, height: 30 }}
                  onChange={setColor}
                  value={field.backgroundColor ? field.backgroundColor : '#FFFFFF'}
                />
              </SmallIcon>
            </Grid>
          ) : null}

            <Grid item xs={12}>
              <SmallIcon
                title={'Повернуть на 90 градусов влево'}
                value={false}
                onChange={v => {
                  field.rotation -= 90;
                  onChange(field);
                }}
              >
                <RotateLeft />
              </SmallIcon>
              <SmallIcon
                title={'Повернуть на 90 градусов вправо'}
                value={false}
                onChange={v => {
                  field.rotation += 90;
                  onChange(field);
                }}
              >
                <RotateRight />
              </SmallIcon>
              {/*<SmallField label={"Deg"} title={"Значение врещения"}
                          value={field.rotation} onChange={(v) => {field.rotation = parseInt(v); onChange(field)}}/>*/}
            </Grid>

          {field.type === 'bar' ? (
            <Grid item xs={12}>
              {/*<BarcodeFormatField title={"Формат бар-кода"}
                                  value={getValue(field, 'barOptions.format') || "CODE128"}
                                  onChange={(v) => {
                                    setValue('barOptions.format', v, field);
                                    onChange(field)
                                  }}
              />*/}
              <SmallIcon
                title={'Включить отображение значения'}
                value={getValue(field, 'barOptions.displayValue')}
                onChange={v => {
                  setValue('barOptions.displayValue', v, field);
                  onChange(field);
                }}
              >
                <FormatListNumberedRtlIcon />
              </SmallIcon>
            </Grid>
          ) : null}

          {field.type === 'image' && field.text !== '{{photo}}' ? (
            <>
              <Grid item xs={12}>
                <Grid item xs={6}>
                  <input
                    ref={inputImageRef}
                    accept='image/*'
                    style={{ display: 'none' }}
                    id={'upload-image'}
                    type='file'
                    onChange={uploadImage}
                  />
                  <label htmlFor='upload-image'>
                    <Button component='span'>Загрузить изображение</Button>
                  </label>
                </Grid>
                <Grid item xs={6}>
                  <Button component='span' onClick={removeImage} disabled={field.imageId === 'default-background-image'}>
                    Удалить изображение
                  </Button>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Typography style={{color: 'red'}}>
                  { error ? error.response.data.message : ''}
                </Typography>
              </Grid> 
            </>
          ) : null}

          {field.type === 'image' && field.text === '{{photo}}' ? (
            <>
              <Grid item xs={12}>
                <TextField
                  select
                  value={field.photoProportion || "1x1"}
                  style={{ width: '100%' }}
                  margin='dense'
                  variant='outlined'
                  onChange={e => {
                    field.photoProportion = e.target.value;
                    onChange(field);
                  }}
                >
                  <MenuItem value={"3x4"} key={"3x4"}>
                    3x4
                  </MenuItem>
                  <MenuItem value={"1x1"} key={"1x1"}>
                    1x1
                  </MenuItem>
                </TextField>
              </Grid>
            </>
          ) : null}

        </Grid>
      </Grid>
    </Grid>
    </>
  );
};

const BadgeFieldsEditorUseStyles = makeStyles(theme => ({
  buttonRoot: {
    background: theme.palette.primary.main,
    color: theme.palette.white,
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  list: {
    margin: '-16px',
    width: 'calc(100% + 40px)',
    borderTop: '1px dashed #E4E7EB',
  },
  listItem: {
    borderBottom: '1px dashed #E4E7EB',
    '&:hover,&.selected': {
      background: 'rgba(196, 196, 196, 0.15)',
      cursor: 'pointer',
    },
  },
}));

const getDefaultSize = type => {
  switch (type) {
    case 'bar':
      return { x: 100, y: 40 };
    case 'qr':
      return { x: 50, y: 50 };
    default:
      return { x: 100, y: 20 };
  }
};

const BadgeFieldsAdder = props => {
  const { fields, onChange, fieldSchema, setSelectedField, selectedField, badgeId } = props;

  const [fieldKey, setFieldKey] = useState('');
  const appContext = useContext(AppContext)
  const meetupContext = useContext(MeetupContext);

  const addField = () => {
    if (!fieldKey || fieldKey === '') {
      return;
    }

    let newType = '';
    if (fieldKey === 'text-') {
      newType = 'textarea';
    }

    const type = typeFromKey(fieldKey);

    fields.push({
      type: newType || type,
      text: textFromKey(fieldKey),
      position: { x: 10, y: 10 },
      size: getDefaultSize(type),
      font: window.localStorage.getItem(`lastUsedFont-${appContext.session.accountId}-${meetupContext.meetupId}-${badgeId}`) || 'PT Sans',
      bold: false,
      italics: false,
      color: 'black',
      fontSize: 12,
      align: 'center',
      adjustSize: true,
      allowWrap: true,
      rotation: 0,
      barOptions: {
        displayValue: false,
      },
    });
    onChange(fields);
    setFieldKey('');
  };

  const handleFieldDelete = idx => {
    fields.splice(idx, 1);
    onChange(fields);
  };

  const classes = BadgeFieldsEditorUseStyles();
  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} style={{ paddingBottom: 0 }}>
          <div>
            <Typography variant={'h5'}>Добавление поля</Typography>
          </div>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={3} alignItems={'center'}>
            <Grid item xs={11}>
              <TextField
                select
                label='Поле'
                style={{ width: '100%' }}
                value={fieldKey}
                onChange={e => setFieldKey(e.target.value)}
                name='field'
                margin='dense'
                variant='outlined'
                data-test-id='badge-new-field-select'
              >
                {fieldSchema.map(f => (
                  <MenuItem key={key(f)} value={key(f)}>
                    {f.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={1} style={{ padding: 0 }}>
              <IconButton
                aria-label='add'
                onClick={addField}
                size='small'
                color='primary'
                variant='contained'
                classes={{
                  root: classes.buttonRoot,
                }}
                data-test-id='badge-new-field-add'
              >
                <AddIcon fontSize='inherit' />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={4} className={classes.list}>
            {fields.map((field, index) => (
              <Grid item xs={12} key={key(field)} >
                <Grid
                  container
                  direction='row'
                  justify='space-between'
                  alignItems='center'
                  className={classNames(classes.listItem, { selected: index === selectedField })}
                  onClick={() => setSelectedField(index)}
                  key={field.type + '_' + index}
                  spacing={4}
                >
                  <Grid item xs={10} style={{ maxWidth: '392px' }}>
                    <Typography variant={'h5'}>
                      {fieldByTypeAndText(fieldSchema, field.type, field.text).label}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={1}
                    style={{
                      padding: '0 51px 0 0',
                    }}
                  >
                    <IconButton
                      size='small'
                      aria-label='delete'
                      onClick={() => {
                        handleFieldDelete(index);
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>

              // <BadgeField key={index} index={index} fieldSchema={fieldSchema} field={f} onChange={handleFieldChange} onDelete={handleFieldDelete}/>
            ))}
          </Grid>
          {/*{fields.map((f, index) => (
              <BadgeField key={index} index={index} fieldSchema={fieldSchema} field={f} onChange={handleFieldChange} onDelete={handleFieldDelete}/>
            ))}*/}
        </Grid>
      </Grid>
    </>
  );
};

export default BadgeFieldsAdder;
