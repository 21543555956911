// tslint:disable
/// <reference path="./custom.d.ts" />
/**
 * Eventos API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as globalImportUrl from 'url';
import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AccountData
 */
export interface AccountData {
    /**
     * 
     * @type {number}
     * @memberof AccountData
     */
    accountId?: number;
    /**
     * 
     * @type {string}
     * @memberof AccountData
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountData
     */
    accountName?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountData
     */
    internalComment?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AccountData
     */
    active?: boolean;
    /**
     * 
     * @type {UserData}
     * @memberof AccountData
     */
    owner?: UserData;
    /**
     * 
     * @type {string}
     * @memberof AccountData
     */
    creationDate?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AccountData
     */
    confirmed?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AccountData
     */
    lastActiveDate?: string;
    /**
     * 
     * @type {number}
     * @memberof AccountData
     */
    actionsCount?: number;
    /**
     * 
     * @type {string}
     * @memberof AccountData
     */
    servicePlanId?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountData
     */
    servicePlanActivationDate?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountData
     */
    nextActionDate?: string;
    /**
     * 
     * @type {ServicePlanActionType}
     * @memberof AccountData
     */
    nextActionType?: ServicePlanActionType;
    /**
     * 
     * @type {string}
     * @memberof AccountData
     */
    nextServicePlanId?: string;
    /**
     * 
     * @type {number}
     * @memberof AccountData
     */
    balance?: number;
    /**
     * 
     * @type {Array<LimitAndCounterData>}
     * @memberof AccountData
     */
    limits?: Array<LimitAndCounterData>;
    /**
     * 
     * @type {boolean}
     * @memberof AccountData
     */
    forTest?: boolean;
}
/**
 * 
 * @export
 * @interface AccountPaginationListData
 */
export interface AccountPaginationListData {
    /**
     * 
     * @type {Array<AccountData>}
     * @memberof AccountPaginationListData
     */
    data?: Array<AccountData>;
    /**
     * 
     * @type {number}
     * @memberof AccountPaginationListData
     */
    page?: number;
    /**
     * 
     * @type {number}
     * @memberof AccountPaginationListData
     */
    pageSize?: number;
    /**
     * 
     * @type {number}
     * @memberof AccountPaginationListData
     */
    totalCount?: number;
}
/**
 * 
 * @export
 * @interface AccountRegisterData
 */
export interface AccountRegisterData {
    /**
     * 
     * @type {string}
     * @memberof AccountRegisterData
     */
    accountName?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountRegisterData
     */
    ownerDisplayName?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountRegisterData
     */
    ownerEmail?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountRegisterData
     */
    ownerPassword?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AccountRegisterData
     */
    forTest?: boolean;
}
/**
 * 
 * @export
 * @interface ActiveBotData
 */
export interface ActiveBotData {
    /**
     * 
     * @type {number}
     * @memberof ActiveBotData
     */
    accountId?: number;
    /**
     * 
     * @type {number}
     * @memberof ActiveBotData
     */
    meetupId?: number;
    /**
     * 
     * @type {string}
     * @memberof ActiveBotData
     */
    token?: string;
    /**
     * 
     * @type {number}
     * @memberof ActiveBotData
     */
    chatConfigId?: number;
    /**
     * 
     * @type {string}
     * @memberof ActiveBotData
     */
    channelName?: string;
    /**
     * 
     * @type {string}
     * @memberof ActiveBotData
     */
    channelLink?: string;
    /**
     * 
     * @type {string}
     * @memberof ActiveBotData
     */
    channelType?: string;
}
/**
 * 
 * @export
 * @interface ActiveChannelData
 */
export interface ActiveChannelData {
    /**
     * 
     * @type {string}
     * @memberof ActiveChannelData
     */
    botUserName?: string;
    /**
     * 
     * @type {string}
     * @memberof ActiveChannelData
     */
    operatorUserName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ActiveChannelData
     */
    hasClient?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ActiveChannelData
     */
    clientDisplayName?: string;
}
/**
 * 
 * @export
 * @interface ActiveClientsReportData
 */
export interface ActiveClientsReportData {
    /**
     * 
     * @type {Array<ClientReportData>}
     * @memberof ActiveClientsReportData
     */
    report?: Array<ClientReportData>;
}
/**
 * 
 * @export
 * @interface ActiveClientsRequestData
 */
export interface ActiveClientsRequestData {
    /**
     * 
     * @type {string}
     * @memberof ActiveClientsRequestData
     */
    startTime?: string;
    /**
     * 
     * @type {string}
     * @memberof ActiveClientsRequestData
     */
    endTime?: string;
    /**
     * 
     * @type {string}
     * @memberof ActiveClientsRequestData
     */
    requestType?: string;
}
/**
 * 
 * @export
 * @interface ActiveDesksByClientsData
 */
export interface ActiveDesksByClientsData {
    /**
     * 
     * @type {Array<ActiveDesksData>}
     * @memberof ActiveDesksByClientsData
     */
    report?: Array<ActiveDesksData>;
}
/**
 * 
 * @export
 * @interface ActiveDesksData
 */
export interface ActiveDesksData {
    /**
     * 
     * @type {ClientData}
     * @memberof ActiveDesksData
     */
    client?: ClientData;
    /**
     * 
     * @type {Array<ActivityData>}
     * @memberof ActiveDesksData
     */
    activity?: Array<ActivityData>;
}
/**
 * 
 * @export
 * @interface ActivityData
 */
export interface ActivityData {
    /**
     * 
     * @type {string}
     * @memberof ActivityData
     */
    date?: string;
    /**
     * 
     * @type {number}
     * @memberof ActivityData
     */
    deskQuantity?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum ActivityRequestType {
    MAU = 'MAU',
    DAU = 'DAU'
}

/**
 * 
 * @export
 * @interface AnswerOptionData
 */
export interface AnswerOptionData {
    /**
     * 
     * @type {string}
     * @memberof AnswerOptionData
     */
    text?: string;
    /**
     * 
     * @type {string}
     * @memberof AnswerOptionData
     */
    tag?: string;
}
/**
 * 
 * @export
 * @interface ApplyPackRequestData
 */
export interface ApplyPackRequestData {
    /**
     * 
     * @type {string}
     * @memberof ApplyPackRequestData
     */
    packId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ApplyPackRequestData
     */
    noCharge?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ApplyPackRequestData
     */
    count?: number;
    /**
     * 
     * @type {string}
     * @memberof ApplyPackRequestData
     */
    comment?: string;
}
/**
 * 
 * @export
 * @interface BadgeData
 */
export interface BadgeData {
    /**
     * 
     * @type {number}
     * @memberof BadgeData
     */
    badgeId?: number;
    /**
     * 
     * @type {number}
     * @memberof BadgeData
     */
    meetupId?: number;
    /**
     * 
     * @type {string}
     * @memberof BadgeData
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof BadgeData
     */
    width?: number;
    /**
     * 
     * @type {number}
     * @memberof BadgeData
     */
    height?: number;
    /**
     * 
     * @type {number}
     * @memberof BadgeData
     */
    heightOnForm?: number;
    /**
     * 
     * @type {string}
     * @memberof BadgeData
     */
    imageId?: string;
    /**
     * 
     * @type {string}
     * @memberof BadgeData
     */
    previewImageId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof BadgeData
     */
    dontPrintImage?: boolean;
    /**
     * 
     * @type {Array<BadgeFieldData>}
     * @memberof BadgeData
     */
    fields?: Array<BadgeFieldData>;
}
/**
 * 
 * @export
 * @interface BadgeFieldData
 */
export interface BadgeFieldData {
    /**
     * 
     * @type {string}
     * @memberof BadgeFieldData
     */
    type?: string;
    /**
     * 
     * @type {string}
     * @memberof BadgeFieldData
     */
    text?: string;
    /**
     * 
     * @type {XYData}
     * @memberof BadgeFieldData
     */
    position?: XYData;
    /**
     * 
     * @type {XYData}
     * @memberof BadgeFieldData
     */
    size?: XYData;
    /**
     * 
     * @type {number}
     * @memberof BadgeFieldData
     */
    rotation?: number;
    /**
     * 
     * @type {string}
     * @memberof BadgeFieldData
     */
    textColor?: string;
    /**
     * 
     * @type {string}
     * @memberof BadgeFieldData
     */
    backgroundColor?: string;
    /**
     * 
     * @type {string}
     * @memberof BadgeFieldData
     */
    font?: string;
    /**
     * 
     * @type {boolean}
     * @memberof BadgeFieldData
     */
    bold?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BadgeFieldData
     */
    italics?: boolean;
    /**
     * 
     * @type {number}
     * @memberof BadgeFieldData
     */
    fontSize?: number;
    /**
     * 
     * @type {string}
     * @memberof BadgeFieldData
     */
    color?: string;
    /**
     * 
     * @type {string}
     * @memberof BadgeFieldData
     */
    align?: string;
    /**
     * 
     * @type {boolean}
     * @memberof BadgeFieldData
     */
    allowWrap?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BadgeFieldData
     */
    adjustSize?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BadgeFieldData
     */
    allCaps?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BadgeFieldData
     */
    firstCaps?: boolean;
    /**
     * 
     * @type {BarOptionsData}
     * @memberof BadgeFieldData
     */
    barOptions?: BarOptionsData;
    /**
     * 
     * @type {string}
     * @memberof BadgeFieldData
     */
    photoProportion?: string;
}
/**
 * 
 * @export
 * @interface BadgeReportData
 */
export interface BadgeReportData {
    /**
     * 
     * @type {string}
     * @memberof BadgeReportData
     */
    tag?: string;
    /**
     * 
     * @type {number}
     * @memberof BadgeReportData
     */
    onMeetup?: number;
    /**
     * 
     * @type {number}
     * @memberof BadgeReportData
     */
    sum?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum BalanceActionType {
    ManualRefillment = 'manualRefillment',
    ManualWithdrawal = 'manualWithdrawal',
    PurchaseTariff = 'purchaseTariff',
    PurchasePack = 'purchasePack',
    TariffCharge = 'tariffCharge',
    CardPayment = 'cardPayment'
}

/**
 * 
 * @export
 * @interface BalanceRecordData
 */
export interface BalanceRecordData {
    /**
     * 
     * @type {number}
     * @memberof BalanceRecordData
     */
    accountId?: number;
    /**
     * 
     * @type {string}
     * @memberof BalanceRecordData
     */
    accountName?: string;
    /**
     * 
     * @type {number}
     * @memberof BalanceRecordData
     */
    orderId?: number;
    /**
     * 
     * @type {BalanceActionType}
     * @memberof BalanceRecordData
     */
    action?: BalanceActionType;
    /**
     * 
     * @type {BigDecimal}
     * @memberof BalanceRecordData
     */
    amount?: BigDecimal;
    /**
     * 
     * @type {string}
     * @memberof BalanceRecordData
     */
    comment?: string;
    /**
     * 
     * @type {string}
     * @memberof BalanceRecordData
     */
    date?: string;
    /**
     * 
     * @type {string}
     * @memberof BalanceRecordData
     */
    userId?: string;
    /**
     * 
     * @type {string}
     * @memberof BalanceRecordData
     */
    userName?: string;
    /**
     * 
     * @type {ProductType}
     * @memberof BalanceRecordData
     */
    productType?: ProductType;
    /**
     * 
     * @type {string}
     * @memberof BalanceRecordData
     */
    productId?: string;
    /**
     * 
     * @type {number}
     * @memberof BalanceRecordData
     */
    productCount?: number;
    /**
     * 
     * @type {boolean}
     * @memberof BalanceRecordData
     */
    paymentResult?: boolean;
    /**
     * 
     * @type {CurrencyName}
     * @memberof BalanceRecordData
     */
    currency?: CurrencyName;
    /**
     * 
     * @type {number}
     * @memberof BalanceRecordData
     */
    transactionId?: number;
    /**
     * 
     * @type {number}
     * @memberof BalanceRecordData
     */
    cardId?: number;
    /**
     * 
     * @type {string}
     * @memberof BalanceRecordData
     */
    paymentErrorCode?: string;
    /**
     * 
     * @type {number}
     * @memberof BalanceRecordData
     */
    resultCode?: number;
}
/**
 * 
 * @export
 * @interface BarOptionsData
 */
export interface BarOptionsData {
    /**
     * 
     * @type {string}
     * @memberof BarOptionsData
     */
    format?: string;
    /**
     * 
     * @type {boolean}
     * @memberof BarOptionsData
     */
    displayValue?: boolean;
}
/**
 * 
 * @export
 * @interface BigDecimal
 */
export interface BigDecimal {
    /**
     * 
     * @type {number}
     * @memberof BigDecimal
     */
    value?: number;
}
/**
 * 
 * @export
 * @interface BotConfigData
 */
export interface BotConfigData {
    /**
     * 
     * @type {number}
     * @memberof BotConfigData
     */
    accountId?: number;
    /**
     * 
     * @type {MeetupData}
     * @memberof BotConfigData
     */
    meetup?: MeetupData;
    /**
     * 
     * @type {LocationData}
     * @memberof BotConfigData
     */
    location?: LocationData;
    /**
     * 
     * @type {ContactsData}
     * @memberof BotConfigData
     */
    contact?: ContactsData;
    /**
     * 
     * @type {Array<SpeakerData>}
     * @memberof BotConfigData
     */
    speakers?: Array<SpeakerData>;
    /**
     * 
     * @type {Array<TimeSlotData>}
     * @memberof BotConfigData
     */
    timeslots?: Array<TimeSlotData>;
    /**
     * 
     * @type {Array<HallData>}
     * @memberof BotConfigData
     */
    halls?: Array<HallData>;
    /**
     * 
     * @type {Array<MerchData>}
     * @memberof BotConfigData
     */
    merch?: Array<MerchData>;
    /**
     * 
     * @type {Array<NewsData>}
     * @memberof BotConfigData
     */
    news?: Array<NewsData>;
    /**
     * 
     * @type {NetworkingSettingsData}
     * @memberof BotConfigData
     */
    networking?: NetworkingSettingsData;
    /**
     * 
     * @type {string}
     * @memberof BotConfigData
     */
    meetupRegFormToken?: string;
    /**
     * 
     * @type {string}
     * @memberof BotConfigData
     */
    chatToken?: string;
    /**
     * 
     * @type {number}
     * @memberof BotConfigData
     */
    chatConfigId?: number;
    /**
     * 
     * @type {ChannelInfoData}
     * @memberof BotConfigData
     */
    channel?: ChannelInfoData;
    /**
     * 
     * @type {boolean}
     * @memberof BotConfigData
     */
    registrationClosed?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BotConfigData
     */
    telegramChannelLink?: string;
}
/**
 * 
 * @export
 * @interface CardData
 */
export interface CardData {
    /**
     * 
     * @type {number}
     * @memberof CardData
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof CardData
     */
    accountId?: number;
    /**
     * 
     * @type {string}
     * @memberof CardData
     */
    rebillAnchor?: string;
    /**
     * 
     * @type {string}
     * @memberof CardData
     */
    cardNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof CardData
     */
    cardHolder?: string;
    /**
     * 
     * @type {string}
     * @memberof CardData
     */
    addingDate?: string;
}
/**
 * 
 * @export
 * @interface CardPaymentResponse
 */
export interface CardPaymentResponse {
    /**
     * 
     * @type {number}
     * @memberof CardPaymentResponse
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof CardPaymentResponse
     */
    operation?: string;
    /**
     * 
     * @type {TransactionResult}
     * @memberof CardPaymentResponse
     */
    result?: TransactionResult;
    /**
     * 
     * @type {number}
     * @memberof CardPaymentResponse
     */
    code?: number;
    /**
     * 
     * @type {TransactionStatus}
     * @memberof CardPaymentResponse
     */
    status?: TransactionStatus;
    /**
     * 
     * @type {number}
     * @memberof CardPaymentResponse
     */
    errorCode?: number;
    /**
     * 
     * @type {number}
     * @memberof CardPaymentResponse
     */
    merchantId?: number;
}
/**
 * 
 * @export
 * @interface ChangeBalanceRequestData
 */
export interface ChangeBalanceRequestData {
    /**
     * 
     * @type {string}
     * @memberof ChangeBalanceRequestData
     */
    amount?: string;
    /**
     * 
     * @type {string}
     * @memberof ChangeBalanceRequestData
     */
    comment?: string;
    /**
     * 
     * @type {string}
     * @memberof ChangeBalanceRequestData
     */
    currency?: string;
}
/**
 * 
 * @export
 * @interface ChangePasswordData
 */
export interface ChangePasswordData {
    /**
     * 
     * @type {string}
     * @memberof ChangePasswordData
     */
    token?: string;
    /**
     * 
     * @type {string}
     * @memberof ChangePasswordData
     */
    password?: string;
}
/**
 * 
 * @export
 * @interface ChangePlanRequestData
 */
export interface ChangePlanRequestData {
    /**
     * 
     * @type {string}
     * @memberof ChangePlanRequestData
     */
    planId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ChangePlanRequestData
     */
    noCharge?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ChangePlanRequestData
     */
    deferred?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ChangePlanRequestData
     */
    comment?: string;
}
/**
 * 
 * @export
 * @interface ChannelInfoData
 */
export interface ChannelInfoData {
    /**
     * 
     * @type {string}
     * @memberof ChannelInfoData
     */
    channelType?: string;
    /**
     * 
     * @type {string}
     * @memberof ChannelInfoData
     */
    channelName?: string;
    /**
     * 
     * @type {string}
     * @memberof ChannelInfoData
     */
    channelLink?: string;
    /**
     * 
     * @type {number}
     * @memberof ChannelInfoData
     */
    chatConfigId?: number;
}
/**
 * 
 * @export
 * @interface ChannelReportData
 */
export interface ChannelReportData {
    /**
     * 
     * @type {string}
     * @memberof ChannelReportData
     */
    channelType?: string;
    /**
     * 
     * @type {number}
     * @memberof ChannelReportData
     */
    quantity?: number;
}
/**
 * 
 * @export
 * @interface ChannelsReportData
 */
export interface ChannelsReportData {
    /**
     * 
     * @type {Array<ChannelReportData>}
     * @memberof ChannelsReportData
     */
    report?: Array<ChannelReportData>;
}
/**
 * 
 * @export
 * @interface ChatClientData
 */
export interface ChatClientData {
    /**
     * 
     * @type {ChatIdData}
     * @memberof ChatClientData
     */
    chatId?: ChatIdData;
    /**
     * 
     * @type {ChannelInfoData}
     * @memberof ChatClientData
     */
    channel?: ChannelInfoData;
    /**
     * 
     * @type {string}
     * @memberof ChatClientData
     */
    chatUserName?: string;
    /**
     * 
     * @type {string}
     * @memberof ChatClientData
     */
    joinDate?: string;
    /**
     * 
     * @type {string}
     * @memberof ChatClientData
     */
    lastActiveDate?: string;
    /**
     * 
     * @type {number}
     * @memberof ChatClientData
     */
    visitorId?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof ChatClientData
     */
    tags?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ChatClientData
     */
    tgUsername?: string;
}
/**
 * 
 * @export
 * @interface ChatConfigData
 */
export interface ChatConfigData {
    /**
     * 
     * @type {number}
     * @memberof ChatConfigData
     */
    meetupId?: number;
    /**
     * 
     * @type {number}
     * @memberof ChatConfigData
     */
    chatConfigId?: number;
    /**
     * 
     * @type {string}
     * @memberof ChatConfigData
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof ChatConfigData
     */
    channelType?: string;
    /**
     * 
     * @type {string}
     * @memberof ChatConfigData
     */
    channelToken?: string;
    /**
     * 
     * @type {object}
     * @memberof ChatConfigData
     */
    data?: object;
    /**
     * 
     * @type {string}
     * @memberof ChatConfigData
     */
    botName?: string;
    /**
     * 
     * @type {string}
     * @memberof ChatConfigData
     */
    botLink?: string;
}
/**
 * 
 * @export
 * @interface ChatIdData
 */
export interface ChatIdData {
    /**
     * 
     * @type {number}
     * @memberof ChatIdData
     */
    accountId?: number;
    /**
     * 
     * @type {number}
     * @memberof ChatIdData
     */
    meetupId?: number;
    /**
     * 
     * @type {number}
     * @memberof ChatIdData
     */
    chatConfigId?: number;
    /**
     * 
     * @type {string}
     * @memberof ChatIdData
     */
    chatClientId?: string;
    /**
     * 
     * @type {string}
     * @memberof ChatIdData
     */
    username?: string;
}
/**
 * 
 * @export
 * @interface ChatMessageData
 */
export interface ChatMessageData {
    /**
     * 
     * @type {string}
     * @memberof ChatMessageData
     */
    timestamp?: string;
    /**
     * 
     * @type {ParticipantType}
     * @memberof ChatMessageData
     */
    participant?: ParticipantType;
    /**
     * 
     * @type {string}
     * @memberof ChatMessageData
     */
    message?: string;
    /**
     * 
     * @type {object}
     * @memberof ChatMessageData
     */
    fullMessage?: object;
}
/**
 * 
 * @export
 * @interface ChatMessagesData
 */
export interface ChatMessagesData {
    /**
     * 
     * @type {ChatIdData}
     * @memberof ChatMessagesData
     */
    chatId?: ChatIdData;
    /**
     * 
     * @type {Array<ChatMessageData>}
     * @memberof ChatMessagesData
     */
    messages?: Array<ChatMessageData>;
}
/**
 * 
 * @export
 * @interface ClientActivityData
 */
export interface ClientActivityData {
    /**
     * 
     * @type {string}
     * @memberof ClientActivityData
     */
    date?: string;
    /**
     * 
     * @type {number}
     * @memberof ClientActivityData
     */
    activityQuantity?: number;
}
/**
 * 
 * @export
 * @interface ClientData
 */
export interface ClientData {
    /**
     * 
     * @type {number}
     * @memberof ClientData
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ClientData
     */
    name?: string;
}
/**
 * 
 * @export
 * @interface ClientPaymentsReportData
 */
export interface ClientPaymentsReportData {
    /**
     * 
     * @type {ClientData}
     * @memberof ClientPaymentsReportData
     */
    client?: ClientData;
    /**
     * 
     * @type {number}
     * @memberof ClientPaymentsReportData
     */
    paymentQuantity?: number;
    /**
     * 
     * @type {BigDecimal}
     * @memberof ClientPaymentsReportData
     */
    paymentSum?: BigDecimal;
}
/**
 * 
 * @export
 * @interface ClientRegistrationData
 */
export interface ClientRegistrationData {
    /**
     * 
     * @type {ClientData}
     * @memberof ClientRegistrationData
     */
    client?: ClientData;
    /**
     * 
     * @type {string}
     * @memberof ClientRegistrationData
     */
    registrationTime?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientRegistrationData
     */
    organizationName?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientRegistrationData
     */
    tariff?: string;
}
/**
 * 
 * @export
 * @interface ClientReportData
 */
export interface ClientReportData {
    /**
     * 
     * @type {ClientData}
     * @memberof ClientReportData
     */
    client?: ClientData;
    /**
     * 
     * @type {Array<ClientActivityData>}
     * @memberof ClientReportData
     */
    activity?: Array<ClientActivityData>;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum ClosureType {
    ByTime = 'byTime',
    ByLimit = 'byLimit'
}

/**
 * 
 * @export
 * @interface ContactsData
 */
export interface ContactsData {
    /**
     * 
     * @type {string}
     * @memberof ContactsData
     */
    contacts?: string;
}
/**
 * 
 * @export
 * @interface CountByDesk
 */
export interface CountByDesk {
    /**
     * 
     * @type {string}
     * @memberof CountByDesk
     */
    tag?: string;
    /**
     * 
     * @type {number}
     * @memberof CountByDesk
     */
    count?: number;
}
/**
 * 
 * @export
 * @interface CountByTime
 */
export interface CountByTime {
    /**
     * 
     * @type {string}
     * @memberof CountByTime
     */
    tag?: string;
    /**
     * 
     * @type {number}
     * @memberof CountByTime
     */
    count?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum CurrencyName {
    RUB = 'RUB',
    USD = 'USD',
    EUR = 'EUR'
}

/**
 * 
 * @export
 * @interface DateReportRequestData
 */
export interface DateReportRequestData {
    /**
     * 
     * @type {string}
     * @memberof DateReportRequestData
     */
    startTime?: string;
    /**
     * 
     * @type {string}
     * @memberof DateReportRequestData
     */
    endTime?: string;
}
/**
 * 
 * @export
 * @interface DeskConditionData
 */
export interface DeskConditionData {
    /**
     * 
     * @type {string}
     * @memberof DeskConditionData
     */
    field?: string;
    /**
     * 
     * @type {string}
     * @memberof DeskConditionData
     */
    operator?: string;
    /**
     * 
     * @type {string}
     * @memberof DeskConditionData
     */
    value?: string;
}
/**
 * 
 * @export
 * @interface DeskData
 */
export interface DeskData {
    /**
     * 
     * @type {string}
     * @memberof DeskData
     */
    type?: string;
    /**
     * 
     * @type {number}
     * @memberof DeskData
     */
    meetupId?: number;
    /**
     * 
     * @type {number}
     * @memberof DeskData
     */
    deskId?: number;
    /**
     * 
     * @type {number}
     * @memberof DeskData
     */
    pin?: number;
    /**
     * 
     * @type {string}
     * @memberof DeskData
     */
    name?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DeskData
     */
    forTest?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof DeskData
     */
    activeDays?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof DeskData
     */
    activatedForToday?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DeskData
     */
    hasOpenSession?: boolean;
    /**
     * 
     * @type {DeskSettingsData}
     * @memberof DeskData
     */
    settings?: DeskSettingsData;
}
/**
 * 
 * @export
 * @interface DeskLogData
 */
export interface DeskLogData {
    /**
     * 
     * @type {number}
     * @memberof DeskLogData
     */
    deskId?: number;
    /**
     * 
     * @type {string}
     * @memberof DeskLogData
     */
    deskName?: string;
    /**
     * 
     * @type {number}
     * @memberof DeskLogData
     */
    visitorId?: number;
    /**
     * 
     * @type {string}
     * @memberof DeskLogData
     */
    action?: string;
    /**
     * 
     * @type {string}
     * @memberof DeskLogData
     */
    timestamp?: string;
}
/**
 * 
 * @export
 * @interface DeskReportData
 */
export interface DeskReportData {
    /**
     * 
     * @type {Array<CountByDesk>}
     * @memberof DeskReportData
     */
    countVisitorsByDesk?: Array<CountByDesk>;
    /**
     * 
     * @type {Array<CountByDesk>}
     * @memberof DeskReportData
     */
    countBadgesByDesk?: Array<CountByDesk>;
    /**
     * 
     * @type {Array<CountByTime>}
     * @memberof DeskReportData
     */
    countVisitorsByTime?: Array<CountByTime>;
    /**
     * 
     * @type {Array<CountByTime>}
     * @memberof DeskReportData
     */
    countBadgesByTime?: Array<CountByTime>;
}
/**
 * 
 * @export
 * @interface DeskSettingsData
 */
export interface DeskSettingsData {
    /**
     * 
     * @type {boolean}
     * @memberof DeskSettingsData
     */
    faceScannerEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DeskSettingsData
     */
    webCamBarScannerEnabled?: boolean;
    /**
     * 
     * @type {number}
     * @memberof DeskSettingsData
     */
    webCamNumber?: number;
    /**
     * 
     * @type {boolean}
     * @memberof DeskSettingsData
     */
    actionCheckinEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DeskSettingsData
     */
    actionPrintBadgeEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DeskSettingsData
     */
    selfRegistrationEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DeskSettingsData
     */
    dontOpenCard?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DeskSettingsData
     */
    tag?: string;
    /**
     * 
     * @type {Array<DeskConditionData>}
     * @memberof DeskSettingsData
     */
    conditions?: Array<DeskConditionData>;
    /**
     * 
     * @type {boolean}
     * @memberof DeskSettingsData
     */
    andOr?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DeskSettingsData
     */
    accessReject?: boolean;
    /**
     * 
     * @type {DeskType}
     * @memberof DeskSettingsData
     */
    type?: DeskType;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum DeskType {
    RegistrationDesk = 'registrationDesk',
    AccessDesk = 'accessDesk',
    SelfServiceDesk = 'selfServiceDesk'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum DuplicateHandlingType {
    Skip = 'skip',
    Overwrite = 'overwrite',
    Merge = 'merge'
}

/**
 * 
 * @export
 * @interface EmailAttachmentData
 */
export interface EmailAttachmentData {
    /**
     * 
     * @type {string}
     * @memberof EmailAttachmentData
     */
    filename?: string;
    /**
     * 
     * @type {string}
     * @memberof EmailAttachmentData
     */
    contentType?: string;
    /**
     * 
     * @type {string}
     * @memberof EmailAttachmentData
     */
    content?: string;
}
/**
 * 
 * @export
 * @interface EmailInfoData
 */
export interface EmailInfoData {
    /**
     * 
     * @type {string}
     * @memberof EmailInfoData
     */
    subject: string;
    /**
     * 
     * @type {string}
     * @memberof EmailInfoData
     */
    content: string;
    /**
     * 
     * @type {string}
     * @memberof EmailInfoData
     */
    contentType?: string;
    /**
     * 
     * @type {string}
     * @memberof EmailInfoData
     */
    to: string;
    /**
     * 
     * @type {string}
     * @memberof EmailInfoData
     */
    from: string;
    /**
     * 
     * @type {Array<EmailAttachmentData>}
     * @memberof EmailInfoData
     */
    attachments?: Array<EmailAttachmentData>;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum ErrorHandlingType {
    Stop = 'stop',
    Skip = 'skip'
}

/**
 * 
 * @export
 * @interface FaceEncodingData
 */
export interface FaceEncodingData {
    /**
     * 
     * @type {number}
     * @memberof FaceEncodingData
     */
    visitorId?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof FaceEncodingData
     */
    encoding?: Array<number>;
}
/**
 * 
 * @export
 * @interface FeatureDescriptorData
 */
export interface FeatureDescriptorData {
    /**
     * 
     * @type {FeatureType}
     * @memberof FeatureDescriptorData
     */
    name?: FeatureType;
    /**
     * 
     * @type {string}
     * @memberof FeatureDescriptorData
     */
    description?: string;
    /**
     * 
     * @type {number}
     * @memberof FeatureDescriptorData
     */
    _default?: number;
}
/**
 * 
 * @export
 * @interface FeatureSettingData
 */
export interface FeatureSettingData {
    /**
     * 
     * @type {FeatureType}
     * @memberof FeatureSettingData
     */
    feature?: FeatureType;
    /**
     * 
     * @type {number}
     * @memberof FeatureSettingData
     */
    option?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum FeatureType {
    Chat = 'chat',
    FaceId = 'faceId',
    FieldsSettings = 'fieldsSettings'
}

/**
 * 
 * @export
 * @interface FieldData
 */
export interface FieldData {
    /**
     * 
     * @type {SystemFieldType}
     * @memberof FieldData
     */
    systemField?: SystemFieldType;
    /**
     * 
     * @type {string}
     * @memberof FieldData
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof FieldData
     */
    label?: string;
    /**
     * 
     * @type {FieldType}
     * @memberof FieldData
     */
    type?: FieldType;
    /**
     * 
     * @type {Array<string>}
     * @memberof FieldData
     */
    options?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof FieldData
     */
    required?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FieldData
     */
    defaultValue?: string;
    /**
     * 
     * @type {FieldValidationData}
     * @memberof FieldData
     */
    validation?: FieldValidationData;
    /**
     * 
     * @type {number}
     * @memberof FieldData
     */
    sizeOnCard?: number;
    /**
     * 
     * @type {boolean}
     * @memberof FieldData
     */
    capitalLetter?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FieldData
     */
    fieldForTranslit?: string;
    /**
     * 
     * @type {string}
     * @memberof FieldData
     */
    photoProportion?: string;
}
/**
 * 
 * @export
 * @interface FieldSettingsData
 */
export interface FieldSettingsData {
    /**
     * 
     * @type {Array<FieldData>}
     * @memberof FieldSettingsData
     */
    fields?: Array<FieldData>;
    /**
     * 
     * @type {Array<string>}
     * @memberof FieldSettingsData
     */
    uniqueFields?: Array<string>;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum FieldType {
    Text = 'text',
    Transliteration = 'transliteration',
    Choice = 'choice',
    MultiChoice = 'multiChoice',
    LimitedChoice = 'limitedChoice'
}

/**
 * 
 * @export
 * @interface FieldValidationData
 */
export interface FieldValidationData {
    /**
     * 
     * @type {number}
     * @memberof FieldValidationData
     */
    min?: number;
    /**
     * 
     * @type {number}
     * @memberof FieldValidationData
     */
    max?: number;
    /**
     * 
     * @type {string}
     * @memberof FieldValidationData
     */
    regex?: string;
}
/**
 * 
 * @export
 * @interface GroupData
 */
export interface GroupData {
    /**
     * 
     * @type {number}
     * @memberof GroupData
     */
    meetupId?: number;
    /**
     * 
     * @type {number}
     * @memberof GroupData
     */
    groupId?: number;
    /**
     * 
     * @type {string}
     * @memberof GroupData
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof GroupData
     */
    visitorsCount?: number;
    /**
     * 
     * @type {number}
     * @memberof GroupData
     */
    badgeId?: number;
    /**
     * 
     * @type {string}
     * @memberof GroupData
     */
    badgeName?: string;
    /**
     * 
     * @type {string}
     * @memberof GroupData
     */
    color?: string;
}
/**
 * 
 * @export
 * @interface GroupReportData
 */
export interface GroupReportData {
    /**
     * 
     * @type {string}
     * @memberof GroupReportData
     */
    tag?: string;
    /**
     * 
     * @type {number}
     * @memberof GroupReportData
     */
    visitors?: number;
    /**
     * 
     * @type {number}
     * @memberof GroupReportData
     */
    arrivedVisitors?: number;
}
/**
 * 
 * @export
 * @interface HallData
 */
export interface HallData {
    /**
     * 
     * @type {number}
     * @memberof HallData
     */
    meetupId?: number;
    /**
     * 
     * @type {number}
     * @memberof HallData
     */
    hallId?: number;
    /**
     * 
     * @type {string}
     * @memberof HallData
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof HallData
     */
    location?: string;
    /**
     * 
     * @type {string}
     * @memberof HallData
     */
    imageId?: string;
}
/**
 * 
 * @export
 * @interface HealthAndVersionData
 */
export interface HealthAndVersionData {
    /**
     * 
     * @type {string}
     * @memberof HealthAndVersionData
     */
    status?: string;
    /**
     * 
     * @type {string}
     * @memberof HealthAndVersionData
     */
    changeset?: string;
}
/**
 * 
 * @export
 * @interface ImageData
 */
export interface ImageData {
    /**
     * 
     * @type {string}
     * @memberof ImageData
     */
    imageId?: string;
}
/**
 * 
 * @export
 * @interface LimitAndCounterData
 */
export interface LimitAndCounterData {
    /**
     * 
     * @type {LimitType}
     * @memberof LimitAndCounterData
     */
    limit?: LimitType;
    /**
     * 
     * @type {number}
     * @memberof LimitAndCounterData
     */
    current?: number;
    /**
     * 
     * @type {number}
     * @memberof LimitAndCounterData
     */
    max?: number;
}
/**
 * 
 * @export
 * @interface LimitData
 */
export interface LimitData {
    /**
     * 
     * @type {LimitType}
     * @memberof LimitData
     */
    limit?: LimitType;
    /**
     * 
     * @type {number}
     * @memberof LimitData
     */
    value?: number;
}
/**
 * 
 * @export
 * @interface LimitDescriptorData
 */
export interface LimitDescriptorData {
    /**
     * 
     * @type {LimitType}
     * @memberof LimitDescriptorData
     */
    name?: LimitType;
    /**
     * 
     * @type {string}
     * @memberof LimitDescriptorData
     */
    description?: string;
    /**
     * 
     * @type {number}
     * @memberof LimitDescriptorData
     */
    _default?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum LimitType {
    Meetups = 'meetups',
    Visitors = 'visitors',
    DeskPerDay = 'deskPerDay',
    Sms = 'sms'
}

/**
 * 
 * @export
 * @interface LocationData
 */
export interface LocationData {
    /**
     * 
     * @type {string}
     * @memberof LocationData
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof LocationData
     */
    address?: string;
    /**
     * 
     * @type {string}
     * @memberof LocationData
     */
    directions?: string;
    /**
     * 
     * @type {string}
     * @memberof LocationData
     */
    directionsImage?: string;
    /**
     * 
     * @type {string}
     * @memberof LocationData
     */
    locationPlanImage?: string;
    /**
     * 
     * @type {string}
     * @memberof LocationData
     */
    timetableImage?: string;
}
/**
 * 
 * @export
 * @interface LoginRequestData
 */
export interface LoginRequestData {
    /**
     * 
     * @type {string}
     * @memberof LoginRequestData
     */
    login?: string;
    /**
     * 
     * @type {string}
     * @memberof LoginRequestData
     */
    password?: string;
}
/**
 * 
 * @export
 * @interface MailAttachmentData
 */
export interface MailAttachmentData {
    /**
     * 
     * @type {string}
     * @memberof MailAttachmentData
     */
    filename?: string;
    /**
     * 
     * @type {string}
     * @memberof MailAttachmentData
     */
    attachmentType?: MailAttachmentDataAttachmentTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof MailAttachmentData
     */
    badgeId?: number;
    /**
     * 
     * @type {string}
     * @memberof MailAttachmentData
     */
    imageId?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum MailAttachmentDataAttachmentTypeEnum {
    QrCode = 'qrCode',
    Badge = 'badge',
    Image = 'image'
}

/**
 * 
 * @export
 * @interface MailTemplateData
 */
export interface MailTemplateData {
    /**
     * 
     * @type {string}
     * @memberof MailTemplateData
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof MailTemplateData
     */
    body?: string;
    /**
     * 
     * @type {Array<MailAttachmentData>}
     * @memberof MailTemplateData
     */
    attachments?: Array<MailAttachmentData>;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum MatchStatusType {
    Looked = 'looked',
    Liked = 'liked',
    Disliked = 'disliked',
    Contact = 'contact'
}

/**
 * 
 * @export
 * @interface MeetupData
 */
export interface MeetupData {
    /**
     * 
     * @type {number}
     * @memberof MeetupData
     */
    meetupId?: number;
    /**
     * 
     * @type {string}
     * @memberof MeetupData
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof MeetupData
     */
    startTime?: string;
    /**
     * 
     * @type {string}
     * @memberof MeetupData
     */
    endTime?: string;
    /**
     * 
     * @type {string}
     * @memberof MeetupData
     */
    timezone?: string;
    /**
     * 
     * @type {number}
     * @memberof MeetupData
     */
    visitorsCount?: number;
    /**
     * 
     * @type {boolean}
     * @memberof MeetupData
     */
    closed?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MeetupData
     */
    imageId?: string;
}
/**
 * 
 * @export
 * @interface MeetupPurchasesData
 */
export interface MeetupPurchasesData {
    /**
     * 
     * @type {number}
     * @memberof MeetupPurchasesData
     */
    meetupId?: number;
    /**
     * 
     * @type {BigDecimal}
     * @memberof MeetupPurchasesData
     */
    balance?: BigDecimal;
    /**
     * 
     * @type {Array<PurchaseFullData>}
     * @memberof MeetupPurchasesData
     */
    purchases?: Array<PurchaseFullData>;
}
/**
 * 
 * @export
 * @interface MerchData
 */
export interface MerchData {
    /**
     * 
     * @type {number}
     * @memberof MerchData
     */
    meetupId?: number;
    /**
     * 
     * @type {number}
     * @memberof MerchData
     */
    merchId?: number;
    /**
     * 
     * @type {string}
     * @memberof MerchData
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof MerchData
     */
    imageId?: string;
    /**
     * 
     * @type {string}
     * @memberof MerchData
     */
    description?: string;
    /**
     * 
     * @type {number}
     * @memberof MerchData
     */
    price?: number;
    /**
     * 
     * @type {MerchHandlingData}
     * @memberof MerchData
     */
    handling?: MerchHandlingData;
}
/**
 * 
 * @export
 * @interface MerchHandlingData
 */
export interface MerchHandlingData {
    /**
     * 
     * @type {boolean}
     * @memberof MerchHandlingData
     */
    sendMail?: boolean;
    /**
     * 
     * @type {MailTemplateData}
     * @memberof MerchHandlingData
     */
    mailTemplate?: MailTemplateData;
    /**
     * 
     * @type {boolean}
     * @memberof MerchHandlingData
     */
    tweakProfile?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MerchHandlingData
     */
    fieldName?: string;
    /**
     * 
     * @type {string}
     * @memberof MerchHandlingData
     */
    fieldValue?: string;
}
/**
 * 
 * @export
 * @interface MerchPurchaseResult
 */
export interface MerchPurchaseResult {
    /**
     * 
     * @type {MerchData}
     * @memberof MerchPurchaseResult
     */
    merch?: MerchData;
    /**
     * 
     * @type {boolean}
     * @memberof MerchPurchaseResult
     */
    result?: boolean;
}
/**
 * 
 * @export
 * @interface MessageData
 */
export interface MessageData {
    /**
     * 
     * @type {string}
     * @memberof MessageData
     */
    code?: string;
    /**
     * 
     * @type {string}
     * @memberof MessageData
     */
    text?: string;
    /**
     * 
     * @type {object}
     * @memberof MessageData
     */
    args?: object;
}
/**
 * 
 * @export
 * @interface NStatData
 */
export interface NStatData {
    /**
     * 
     * @type {number}
     * @memberof NStatData
     */
    visitorId?: number;
    /**
     * 
     * @type {MatchStatusType}
     * @memberof NStatData
     */
    status?: MatchStatusType;
}
/**
 * 
 * @export
 * @interface NetworkingCarouselData
 */
export interface NetworkingCarouselData {
    /**
     * 
     * @type {VisitorData}
     * @memberof NetworkingCarouselData
     */
    visitor?: VisitorData;
    /**
     * 
     * @type {MatchStatusType}
     * @memberof NetworkingCarouselData
     */
    status?: MatchStatusType;
    /**
     * 
     * @type {MatchStatusType}
     * @memberof NetworkingCarouselData
     */
    contraStatus?: MatchStatusType;
    /**
     * 
     * @type {number}
     * @memberof NetworkingCarouselData
     */
    total?: number;
    /**
     * 
     * @type {number}
     * @memberof NetworkingCarouselData
     */
    index?: number;
}
/**
 * 
 * @export
 * @interface NetworkingContactData
 */
export interface NetworkingContactData {
    /**
     * 
     * @type {number}
     * @memberof NetworkingContactData
     */
    firstVisitorId?: number;
    /**
     * 
     * @type {number}
     * @memberof NetworkingContactData
     */
    secondVisitorId?: number;
}
/**
 * 
 * @export
 * @interface NetworkingMatchData
 */
export interface NetworkingMatchData {
    /**
     * 
     * @type {number}
     * @memberof NetworkingMatchData
     */
    selfId?: number;
    /**
     * 
     * @type {number}
     * @memberof NetworkingMatchData
     */
    otherId?: number;
}
/**
 * 
 * @export
 * @interface NetworkingQuestionData
 */
export interface NetworkingQuestionData {
    /**
     * 
     * @type {string}
     * @memberof NetworkingQuestionData
     */
    fieldKey?: string;
    /**
     * 
     * @type {string}
     * @memberof NetworkingQuestionData
     */
    label?: string;
    /**
     * 
     * @type {string}
     * @memberof NetworkingQuestionData
     */
    question?: string;
    /**
     * 
     * @type {boolean}
     * @memberof NetworkingQuestionData
     */
    required?: boolean;
}
/**
 * 
 * @export
 * @interface NetworkingSettingsData
 */
export interface NetworkingSettingsData {
    /**
     * 
     * @type {boolean}
     * @memberof NetworkingSettingsData
     */
    enabled?: boolean;
    /**
     * 
     * @type {Array<NetworkingQuestionData>}
     * @memberof NetworkingSettingsData
     */
    profileFields?: Array<NetworkingQuestionData>;
}
/**
 * 
 * @export
 * @interface NetworkingVisitorData
 */
export interface NetworkingVisitorData {
    /**
     * 
     * @type {number}
     * @memberof NetworkingVisitorData
     */
    visitorId?: number;
    /**
     * 
     * @type {boolean}
     * @memberof NetworkingVisitorData
     */
    enabled?: boolean;
    /**
     * 
     * @type {string}
     * @memberof NetworkingVisitorData
     */
    lastModified?: string;
    /**
     * 
     * @type {string}
     * @memberof NetworkingVisitorData
     */
    telegramUserId?: string;
    /**
     * 
     * @type {string}
     * @memberof NetworkingVisitorData
     */
    telegramUserName?: string;
    /**
     * 
     * @type {MatchStatusType}
     * @memberof NetworkingVisitorData
     */
    status?: MatchStatusType;
    /**
     * 
     * @type {MatchStatusType}
     * @memberof NetworkingVisitorData
     */
    contraStatus?: MatchStatusType;
}
/**
 * 
 * @export
 * @interface NetworkingVisitorStatData
 */
export interface NetworkingVisitorStatData {
    /**
     * 
     * @type {number}
     * @memberof NetworkingVisitorStatData
     */
    selfId?: number;
    /**
     * 
     * @type {Array<NStatData>}
     * @memberof NetworkingVisitorStatData
     */
    meToOtherStat?: Array<NStatData>;
    /**
     * 
     * @type {Array<NStatData>}
     * @memberof NetworkingVisitorStatData
     */
    otherToMeStat?: Array<NStatData>;
}
/**
 * 
 * @export
 * @interface NetworkingWriteStatData
 */
export interface NetworkingWriteStatData {
    /**
     * 
     * @type {number}
     * @memberof NetworkingWriteStatData
     */
    selfId?: number;
    /**
     * 
     * @type {number}
     * @memberof NetworkingWriteStatData
     */
    otherId?: number;
    /**
     * 
     * @type {MatchStatusType}
     * @memberof NetworkingWriteStatData
     */
    status?: MatchStatusType;
}
/**
 * 
 * @export
 * @interface NewsData
 */
export interface NewsData {
    /**
     * 
     * @type {number}
     * @memberof NewsData
     */
    meetupId?: number;
    /**
     * 
     * @type {number}
     * @memberof NewsData
     */
    newsId?: number;
    /**
     * 
     * @type {NewsType}
     * @memberof NewsData
     */
    type?: NewsType;
    /**
     * 
     * @type {NewsStatusType}
     * @memberof NewsData
     */
    status?: NewsStatusType;
    /**
     * 
     * @type {SendingTimeData}
     * @memberof NewsData
     */
    sendingTime?: SendingTimeData;
    /**
     * 
     * @type {RecipientsData}
     * @memberof NewsData
     */
    recipients?: RecipientsData;
    /**
     * 
     * @type {ProgressData}
     * @memberof NewsData
     */
    progress?: ProgressData;
    /**
     * 
     * @type {string}
     * @memberof NewsData
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof NewsData
     */
    text?: string;
    /**
     * 
     * @type {Array<MailAttachmentData>}
     * @memberof NewsData
     */
    attachments?: Array<MailAttachmentData>;
    /**
     * 
     * @type {Array<AnswerOptionData>}
     * @memberof NewsData
     */
    answerOptions?: Array<AnswerOptionData>;
}
/**
 * 
 * @export
 * @interface NewsSendingStatusData
 */
export interface NewsSendingStatusData {
    /**
     * 
     * @type {number}
     * @memberof NewsSendingStatusData
     */
    newsId?: number;
    /**
     * 
     * @type {ChatIdData}
     * @memberof NewsSendingStatusData
     */
    chatId?: ChatIdData;
    /**
     * 
     * @type {NewsSendingStatusType}
     * @memberof NewsSendingStatusData
     */
    status?: NewsSendingStatusType;
    /**
     * 
     * @type {string}
     * @memberof NewsSendingStatusData
     */
    error?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum NewsSendingStatusType {
    Scheduled = 'scheduled',
    Sent = 'sent',
    Error = 'error',
    Published = 'published'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum NewsStatusType {
    Draft = 'draft',
    ReadyToSend = 'readyToSend',
    Sending = 'sending',
    Published = 'published'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum NewsType {
    News = 'news',
    Notification = 'notification',
    Question = 'question'
}

/**
 * 
 * @export
 * @interface NotificationData
 */
export interface NotificationData {
    /**
     * 
     * @type {number}
     * @memberof NotificationData
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof NotificationData
     */
    timestamp?: string;
    /**
     * 
     * @type {NotificationLevelType}
     * @memberof NotificationData
     */
    level?: NotificationLevelType;
    /**
     * 
     * @type {MessageData}
     * @memberof NotificationData
     */
    message?: MessageData;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum NotificationLevelType {
    Info = 'info',
    Warn = 'warn',
    Error = 'error'
}

/**
 * 
 * @export
 * @interface OnlinePaymentRequestData
 */
export interface OnlinePaymentRequestData {
    /**
     * 
     * @type {BigDecimal}
     * @memberof OnlinePaymentRequestData
     */
    amount?: BigDecimal;
    /**
     * 
     * @type {string}
     * @memberof OnlinePaymentRequestData
     */
    orderDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof OnlinePaymentRequestData
     */
    currency?: string;
    /**
     * 
     * @type {PaymentGoals}
     * @memberof OnlinePaymentRequestData
     */
    goal?: PaymentGoals;
    /**
     * 
     * @type {string}
     * @memberof OnlinePaymentRequestData
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof OnlinePaymentRequestData
     */
    count?: number;
    /**
     * 
     * @type {string}
     * @memberof OnlinePaymentRequestData
     */
    returnUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof OnlinePaymentRequestData
     */
    failUrl?: string;
    /**
     * 
     * @type {boolean}
     * @memberof OnlinePaymentRequestData
     */
    rememberCard?: boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum ParticipantType {
    Client = 'client',
    Bot = 'bot',
    Oper = 'oper'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum PaymentGoals {
    ApplyPack = 'applyPack',
    ChangePlan = 'changePlan'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum PaymentLanguage {
    Ru = 'ru',
    En = 'en'
}

/**
 * 
 * @export
 * @interface PaymentResult
 */
export interface PaymentResult {
    /**
     * 
     * @type {TransactionResult}
     * @memberof PaymentResult
     */
    result?: TransactionResult;
    /**
     * 
     * @type {number}
     * @memberof PaymentResult
     */
    errorCode?: number;
    /**
     * 
     * @type {TransactionStatus}
     * @memberof PaymentResult
     */
    transactionStatus?: TransactionStatus;
}
/**
 * 
 * @export
 * @interface PaymentsReportData
 */
export interface PaymentsReportData {
    /**
     * 
     * @type {Array<ClientPaymentsReportData>}
     * @memberof PaymentsReportData
     */
    report?: Array<ClientPaymentsReportData>;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum PermissionType {
    User = 'user',
    Admin = 'admin',
    SuperAdmin = 'superAdmin',
    Reception = 'reception'
}

/**
 * 
 * @export
 * @interface PoolResultsData
 */
export interface PoolResultsData {
    /**
     * 
     * @type {Array<TotalVoteData>}
     * @memberof PoolResultsData
     */
    total?: Array<TotalVoteData>;
    /**
     * 
     * @type {Array<SingleVoteData>}
     * @memberof PoolResultsData
     */
    votes?: Array<SingleVoteData>;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum ProductType {
    ServicePlan = 'servicePlan',
    ServicePack = 'servicePack'
}

/**
 * 
 * @export
 * @interface ProfileData
 */
export interface ProfileData {
    /**
     * 
     * @type {AccountData}
     * @memberof ProfileData
     */
    account?: AccountData;
    /**
     * 
     * @type {ServicePlanData}
     * @memberof ProfileData
     */
    servicePlan?: ServicePlanData;
    /**
     * 
     * @type {UserData}
     * @memberof ProfileData
     */
    user?: UserData;
}
/**
 * 
 * @export
 * @interface ProgressData
 */
export interface ProgressData {
    /**
     * 
     * @type {boolean}
     * @memberof ProgressData
     */
    inProgress?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ProgressData
     */
    startTime?: string;
    /**
     * 
     * @type {string}
     * @memberof ProgressData
     */
    endTime?: string;
    /**
     * 
     * @type {number}
     * @memberof ProgressData
     */
    scheduled?: number;
    /**
     * 
     * @type {number}
     * @memberof ProgressData
     */
    completed?: number;
    /**
     * 
     * @type {number}
     * @memberof ProgressData
     */
    answered?: number;
}
/**
 * 
 * @export
 * @interface PurchaseFullData
 */
export interface PurchaseFullData {
    /**
     * 
     * @type {number}
     * @memberof PurchaseFullData
     */
    meetupId?: number;
    /**
     * 
     * @type {number}
     * @memberof PurchaseFullData
     */
    purchaseId?: number;
    /**
     * 
     * @type {MerchData}
     * @memberof PurchaseFullData
     */
    merch?: MerchData;
    /**
     * 
     * @type {VisitorData}
     * @memberof PurchaseFullData
     */
    visitor?: VisitorData;
    /**
     * 
     * @type {PurchasePaymentData}
     * @memberof PurchaseFullData
     */
    payment?: PurchasePaymentData;
}
/**
 * 
 * @export
 * @interface PurchasePaymentData
 */
export interface PurchasePaymentData {
    /**
     * 
     * @type {BigDecimal}
     * @memberof PurchasePaymentData
     */
    amount?: BigDecimal;
    /**
     * 
     * @type {string}
     * @memberof PurchasePaymentData
     */
    comment?: string;
    /**
     * 
     * @type {string}
     * @memberof PurchasePaymentData
     */
    date?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PurchasePaymentData
     */
    paymentResult?: boolean;
    /**
     * 
     * @type {CurrencyName}
     * @memberof PurchasePaymentData
     */
    currency?: CurrencyName;
    /**
     * 
     * @type {number}
     * @memberof PurchasePaymentData
     */
    transactionId?: number;
    /**
     * 
     * @type {string}
     * @memberof PurchasePaymentData
     */
    paymentErrorCode?: string;
    /**
     * 
     * @type {string}
     * @memberof PurchasePaymentData
     */
    status?: string;
}
/**
 * 
 * @export
 * @interface PurchaseRequestData
 */
export interface PurchaseRequestData {
    /**
     * 
     * @type {number}
     * @memberof PurchaseRequestData
     */
    visitorId?: number;
    /**
     * 
     * @type {string}
     * @memberof PurchaseRequestData
     */
    chatId?: string;
    /**
     * 
     * @type {number}
     * @memberof PurchaseRequestData
     */
    chatConfigId?: number;
    /**
     * 
     * @type {BigDecimal}
     * @memberof PurchaseRequestData
     */
    amount?: BigDecimal;
    /**
     * 
     * @type {string}
     * @memberof PurchaseRequestData
     */
    orderDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof PurchaseRequestData
     */
    currency?: string;
    /**
     * 
     * @type {MerchData}
     * @memberof PurchaseRequestData
     */
    merch?: MerchData;
    /**
     * 
     * @type {number}
     * @memberof PurchaseRequestData
     */
    count?: number;
}
/**
 * 
 * @export
 * @interface PushRequestData
 */
export interface PushRequestData {
    /**
     * 
     * @type {Array<number>}
     * @memberof PushRequestData
     */
    visitors?: Array<number>;
    /**
     * 
     * @type {Array<ChatIdData>}
     * @memberof PushRequestData
     */
    chatClients?: Array<ChatIdData>;
    /**
     * 
     * @type {string}
     * @memberof PushRequestData
     */
    event?: string;
    /**
     * 
     * @type {string}
     * @memberof PushRequestData
     */
    data?: string;
}
/**
 * 
 * @export
 * @interface PushResponseData
 */
export interface PushResponseData {
    /**
     * 
     * @type {string}
     * @memberof PushResponseData
     */
    status?: string;
}
/**
 * 
 * @export
 * @interface RebillPaymentRequestData
 */
export interface RebillPaymentRequestData {
    /**
     * 
     * @type {BigDecimal}
     * @memberof RebillPaymentRequestData
     */
    amount?: BigDecimal;
    /**
     * 
     * @type {string}
     * @memberof RebillPaymentRequestData
     */
    orderDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof RebillPaymentRequestData
     */
    currency?: string;
    /**
     * 
     * @type {PaymentGoals}
     * @memberof RebillPaymentRequestData
     */
    goal?: PaymentGoals;
    /**
     * 
     * @type {string}
     * @memberof RebillPaymentRequestData
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof RebillPaymentRequestData
     */
    count?: number;
}
/**
 * 
 * @export
 * @interface ReceptionData
 */
export interface ReceptionData {
    /**
     * 
     * @type {MeetupData}
     * @memberof ReceptionData
     */
    meetup?: MeetupData;
    /**
     * 
     * @type {Array<FieldData>}
     * @memberof ReceptionData
     */
    fields?: Array<FieldData>;
    /**
     * 
     * @type {Array<GroupData>}
     * @memberof ReceptionData
     */
    groups?: Array<GroupData>;
    /**
     * 
     * @type {string}
     * @memberof ReceptionData
     */
    deskName?: string;
    /**
     * 
     * @type {DeskSettingsData}
     * @memberof ReceptionData
     */
    deskSettings?: DeskSettingsData;
    /**
     * 
     * @type {number}
     * @memberof ReceptionData
     */
    visitorsCount?: number;
    /**
     * 
     * @type {string}
     * @memberof ReceptionData
     */
    lastActivity?: string;
}
/**
 * 
 * @export
 * @interface RecipientsData
 */
export interface RecipientsData {
    /**
     * 
     * @type {Array<boolean>}
     * @memberof RecipientsData
     */
    registered?: Array<boolean>;
    /**
     * 
     * @type {Array<number>}
     * @memberof RecipientsData
     */
    groups?: Array<number>;
    /**
     * 
     * @type {Array<VisitorStatusType>}
     * @memberof RecipientsData
     */
    statuses?: Array<VisitorStatusType>;
    /**
     * 
     * @type {Array<string>}
     * @memberof RecipientsData
     */
    tags?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof RecipientsData
     */
    preliminaryCount?: number;
}
/**
 * 
 * @export
 * @interface RegFormData
 */
export interface RegFormData {
    /**
     * 
     * @type {FieldSettingsData}
     * @memberof RegFormData
     */
    fields?: FieldSettingsData;
    /**
     * 
     * @type {RegFormSettingsData}
     * @memberof RegFormData
     */
    regForm?: RegFormSettingsData;
    /**
     * 
     * @type {MeetupData}
     * @memberof RegFormData
     */
    meetup?: MeetupData;
    /**
     * 
     * @type {Array<GroupData>}
     * @memberof RegFormData
     */
    groups?: Array<GroupData>;
    /**
     * 
     * @type {boolean}
     * @memberof RegFormData
     */
    closed?: boolean;
}
/**
 * 
 * @export
 * @interface RegFormSettingsData
 */
export interface RegFormSettingsData {
    /**
     * 
     * @type {string}
     * @memberof RegFormSettingsData
     */
    token?: string;
    /**
     * 
     * @type {boolean}
     * @memberof RegFormSettingsData
     */
    enabled?: boolean;
    /**
     * 
     * @type {RegFormType}
     * @memberof RegFormSettingsData
     */
    formType?: RegFormType;
    /**
     * 
     * @type {string}
     * @memberof RegFormSettingsData
     */
    language?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof RegFormSettingsData
     */
    fieldsToHide?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof RegFormSettingsData
     */
    completionText?: string;
    /**
     * 
     * @type {boolean}
     * @memberof RegFormSettingsData
     */
    sendCompletionEmail?: boolean;
    /**
     * 
     * @type {MailTemplateData}
     * @memberof RegFormSettingsData
     */
    completionMailTemplate?: MailTemplateData;
    /**
     * 
     * @type {ClosureType}
     * @memberof RegFormSettingsData
     */
    closureType?: ClosureType;
    /**
     * 
     * @type {string}
     * @memberof RegFormSettingsData
     */
    closeByTime?: string;
    /**
     * 
     * @type {number}
     * @memberof RegFormSettingsData
     */
    closeByLimit?: number;
    /**
     * 
     * @type {string}
     * @memberof RegFormSettingsData
     */
    closedText?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum RegFormType {
    Bar = 'bar',
    Flat = 'flat'
}

/**
 * 
 * @export
 * @interface RegistrationBySourceReportData
 */
export interface RegistrationBySourceReportData {
    /**
     * 
     * @type {Array<RegistrationSourceReportData>}
     * @memberof RegistrationBySourceReportData
     */
    report?: Array<RegistrationSourceReportData>;
}
/**
 * 
 * @export
 * @interface RegistrationProductReportData
 */
export interface RegistrationProductReportData {
    /**
     * 
     * @type {Array<ClientRegistrationData>}
     * @memberof RegistrationProductReportData
     */
    report?: Array<ClientRegistrationData>;
}
/**
 * 
 * @export
 * @interface RegistrationReportData
 */
export interface RegistrationReportData {
    /**
     * 
     * @type {VisitorSourceType}
     * @memberof RegistrationReportData
     */
    tag?: VisitorSourceType;
    /**
     * 
     * @type {number}
     * @memberof RegistrationReportData
     */
    inBase?: number;
    /**
     * 
     * @type {number}
     * @memberof RegistrationReportData
     */
    arrived?: number;
}
/**
 * 
 * @export
 * @interface RegistrationSourceReportData
 */
export interface RegistrationSourceReportData {
    /**
     * 
     * @type {string}
     * @memberof RegistrationSourceReportData
     */
    source?: string;
    /**
     * 
     * @type {number}
     * @memberof RegistrationSourceReportData
     */
    quantity?: number;
}
/**
 * 
 * @export
 * @interface ReportData
 */
export interface ReportData {
    /**
     * 
     * @type {Array<SourceReportData>}
     * @memberof ReportData
     */
    countBySource?: Array<SourceReportData>;
    /**
     * 
     * @type {Array<BadgeReportData>}
     * @memberof ReportData
     */
    countByBadge?: Array<BadgeReportData>;
    /**
     * 
     * @type {Array<RegistrationReportData>}
     * @memberof ReportData
     */
    countByRegistration?: Array<RegistrationReportData>;
    /**
     * 
     * @type {Array<GroupReportData>}
     * @memberof ReportData
     */
    countByGroup?: Array<GroupReportData>;
}
/**
 * 
 * @export
 * @interface RequestChangePasswordData
 */
export interface RequestChangePasswordData {
    /**
     * 
     * @type {string}
     * @memberof RequestChangePasswordData
     */
    email?: string;
}
/**
 * 
 * @export
 * @interface RequestPlanData
 */
export interface RequestPlanData {
    /**
     * 
     * @type {number}
     * @memberof RequestPlanData
     */
    accountId?: number;
    /**
     * 
     * @type {number}
     * @memberof RequestPlanData
     */
    userId?: number;
    /**
     * 
     * @type {string}
     * @memberof RequestPlanData
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof RequestPlanData
     */
    planDescription?: string;
}
/**
 * 
 * @export
 * @interface SendCustomMailData
 */
export interface SendCustomMailData {
    /**
     * 
     * @type {MailTemplateData}
     * @memberof SendCustomMailData
     */
    template?: MailTemplateData;
    /**
     * 
     * @type {Array<string>}
     * @memberof SendCustomMailData
     */
    recipients?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof SendCustomMailData
     */
    sender?: string;
}
/**
 * 
 * @export
 * @interface SendingTimeData
 */
export interface SendingTimeData {
    /**
     * 
     * @type {boolean}
     * @memberof SendingTimeData
     */
    immediate?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SendingTimeData
     */
    time?: string;
}
/**
 * 
 * @export
 * @interface ServicePackData
 */
export interface ServicePackData {
    /**
     * 
     * @type {string}
     * @memberof ServicePackData
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ServicePackData
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof ServicePackData
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof ServicePackData
     */
    limit?: string;
    /**
     * 
     * @type {number}
     * @memberof ServicePackData
     */
    amount?: number;
    /**
     * 
     * @type {number}
     * @memberof ServicePackData
     */
    price?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum ServicePlanActionType {
    Renewal = 'renewal',
    ChangePlan = 'changePlan',
    Deactivation = 'deactivation'
}

/**
 * 
 * @export
 * @interface ServicePlanData
 */
export interface ServicePlanData {
    /**
     * 
     * @type {string}
     * @memberof ServicePlanData
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ServicePlanData
     */
    publicName?: string;
    /**
     * 
     * @type {string}
     * @memberof ServicePlanData
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof ServicePlanData
     */
    description?: string;
    /**
     * 
     * @type {Array<FeatureSettingData>}
     * @memberof ServicePlanData
     */
    features?: Array<FeatureSettingData>;
    /**
     * 
     * @type {Array<LimitData>}
     * @memberof ServicePlanData
     */
    limits?: Array<LimitData>;
    /**
     * 
     * @type {string}
     * @memberof ServicePlanData
     */
    period?: string;
    /**
     * 
     * @type {ServicePlanActionType}
     * @memberof ServicePlanData
     */
    nextAction?: ServicePlanActionType;
    /**
     * 
     * @type {string}
     * @memberof ServicePlanData
     */
    nextServicePlan?: string;
    /**
     * 
     * @type {number}
     * @memberof ServicePlanData
     */
    price?: number;
}
/**
 * 
 * @export
 * @interface ServicePlansConfigData
 */
export interface ServicePlansConfigData {
    /**
     * 
     * @type {Array<FeatureDescriptorData>}
     * @memberof ServicePlansConfigData
     */
    features?: Array<FeatureDescriptorData>;
    /**
     * 
     * @type {Array<LimitDescriptorData>}
     * @memberof ServicePlansConfigData
     */
    limits?: Array<LimitDescriptorData>;
    /**
     * 
     * @type {Array<ServicePlanData>}
     * @memberof ServicePlansConfigData
     */
    servicePlans?: Array<ServicePlanData>;
    /**
     * 
     * @type {Array<ServicePackData>}
     * @memberof ServicePlansConfigData
     */
    servicePacks?: Array<ServicePackData>;
}
/**
 * 
 * @export
 * @interface SessionInfoData
 */
export interface SessionInfoData {
    /**
     * 
     * @type {string}
     * @memberof SessionInfoData
     */
    sessionId?: string;
    /**
     * 
     * @type {number}
     * @memberof SessionInfoData
     */
    accountId?: number;
    /**
     * 
     * @type {boolean}
     * @memberof SessionInfoData
     */
    superAdmin?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SessionInfoData
     */
    userId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SessionInfoData
     */
    emailNotVerified?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SessionInfoData
     */
    timeToVerify?: string;
    /**
     * 
     * @type {string}
     * @memberof SessionInfoData
     */
    userEmail?: string;
    /**
     * 
     * @type {string}
     * @memberof SessionInfoData
     */
    displayName?: string;
    /**
     * 
     * @type {Array<PermissionType>}
     * @memberof SessionInfoData
     */
    permissions?: Array<PermissionType>;
    /**
     * 
     * @type {Array<FeatureType>}
     * @memberof SessionInfoData
     */
    features?: Array<FeatureType>;
    /**
     * 
     * @type {number}
     * @memberof SessionInfoData
     */
    meetupId?: number;
}
/**
 * 
 * @export
 * @interface SettingsData
 */
export interface SettingsData {
    /**
     * 
     * @type {FieldSettingsData}
     * @memberof SettingsData
     */
    fields: FieldSettingsData;
    /**
     * 
     * @type {RegFormSettingsData}
     * @memberof SettingsData
     */
    regForm: RegFormSettingsData;
}
/**
 * 
 * @export
 * @interface SingleVoteData
 */
export interface SingleVoteData {
    /**
     * 
     * @type {string}
     * @memberof SingleVoteData
     */
    tgName?: string;
    /**
     * 
     * @type {number}
     * @memberof SingleVoteData
     */
    visitorId?: number;
    /**
     * 
     * @type {string}
     * @memberof SingleVoteData
     */
    visitorName?: string;
    /**
     * 
     * @type {string}
     * @memberof SingleVoteData
     */
    choice?: string;
    /**
     * 
     * @type {string}
     * @memberof SingleVoteData
     */
    timestamp?: string;
}
/**
 * 
 * @export
 * @interface SourceReportData
 */
export interface SourceReportData {
    /**
     * 
     * @type {string}
     * @memberof SourceReportData
     */
    tag?: string;
    /**
     * 
     * @type {number}
     * @memberof SourceReportData
     */
    fromBase?: number;
    /**
     * 
     * @type {number}
     * @memberof SourceReportData
     */
    online?: number;
    /**
     * 
     * @type {number}
     * @memberof SourceReportData
     */
    _new?: number;
    /**
     * 
     * @type {number}
     * @memberof SourceReportData
     */
    sum?: number;
}
/**
 * 
 * @export
 * @interface SpeakerData
 */
export interface SpeakerData {
    /**
     * 
     * @type {number}
     * @memberof SpeakerData
     */
    meetupId?: number;
    /**
     * 
     * @type {number}
     * @memberof SpeakerData
     */
    speakerId?: number;
    /**
     * 
     * @type {string}
     * @memberof SpeakerData
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof SpeakerData
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof SpeakerData
     */
    position?: string;
    /**
     * 
     * @type {string}
     * @memberof SpeakerData
     */
    company?: string;
    /**
     * 
     * @type {string}
     * @memberof SpeakerData
     */
    description?: string;
    /**
     * 
     * @type {number}
     * @memberof SpeakerData
     */
    posInList?: number;
    /**
     * 
     * @type {string}
     * @memberof SpeakerData
     */
    imageId?: string;
    /**
     * 
     * @type {Array<TimeSlotData>}
     * @memberof SpeakerData
     */
    timeSlots?: Array<TimeSlotData>;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum StatLogCategory {
    Payments = 'payments',
    Desks = 'desks',
    Meetups = 'meetups',
    Visitors = 'visitors',
    All = 'all',
    NoCategory = 'noCategory'
}

/**
 * 
 * @export
 * @interface StatLogData
 */
export interface StatLogData {
    /**
     * 
     * @type {number}
     * @memberof StatLogData
     */
    statLogId?: number;
    /**
     * 
     * @type {StatLogUserData}
     * @memberof StatLogData
     */
    user?: StatLogUserData;
    /**
     * 
     * @type {StatLogDeskData}
     * @memberof StatLogData
     */
    desk?: StatLogDeskData;
    /**
     * 
     * @type {string}
     * @memberof StatLogData
     */
    action?: string;
    /**
     * 
     * @type {number}
     * @memberof StatLogData
     */
    count?: number;
    /**
     * 
     * @type {string}
     * @memberof StatLogData
     */
    comment?: string;
    /**
     * 
     * @type {string}
     * @memberof StatLogData
     */
    timestamp?: string;
    /**
     * 
     * @type {MessageData}
     * @memberof StatLogData
     */
    message?: MessageData;
}
/**
 * 
 * @export
 * @interface StatLogDeskData
 */
export interface StatLogDeskData {
    /**
     * 
     * @type {number}
     * @memberof StatLogDeskData
     */
    deskId?: number;
    /**
     * 
     * @type {string}
     * @memberof StatLogDeskData
     */
    deskName?: string;
}
/**
 * 
 * @export
 * @interface StatLogPaginationData
 */
export interface StatLogPaginationData {
    /**
     * 
     * @type {Array<StatLogData>}
     * @memberof StatLogPaginationData
     */
    data?: Array<StatLogData>;
    /**
     * 
     * @type {number}
     * @memberof StatLogPaginationData
     */
    page?: number;
    /**
     * 
     * @type {number}
     * @memberof StatLogPaginationData
     */
    pageSize?: number;
    /**
     * 
     * @type {number}
     * @memberof StatLogPaginationData
     */
    totalCount?: number;
}
/**
 * 
 * @export
 * @interface StatLogUserData
 */
export interface StatLogUserData {
    /**
     * 
     * @type {number}
     * @memberof StatLogUserData
     */
    userAccountId?: number;
    /**
     * 
     * @type {number}
     * @memberof StatLogUserData
     */
    userId?: number;
    /**
     * 
     * @type {string}
     * @memberof StatLogUserData
     */
    userName?: string;
    /**
     * 
     * @type {string}
     * @memberof StatLogUserData
     */
    userEmail?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum SystemFieldType {
    FirstName = 'firstName',
    LastName = 'lastName',
    MiddleName = 'middleName',
    PhoneNumber = 'phoneNumber',
    Email = 'email',
    Group = 'group',
    Photo = 'photo',
    QrToken = 'qrToken'
}

/**
 * 
 * @export
 * @interface TestClockData
 */
export interface TestClockData {
    /**
     * 
     * @type {number}
     * @memberof TestClockData
     */
    offset: number;
    /**
     * 
     * @type {string}
     * @memberof TestClockData
     */
    date: string;
}
/**
 * 
 * @export
 * @interface TestClockOffsetDurationRequestData
 */
export interface TestClockOffsetDurationRequestData {
    /**
     * 
     * @type {string}
     * @memberof TestClockOffsetDurationRequestData
     */
    offset: string;
}
/**
 * 
 * @export
 * @interface TestClockOffsetRequestData
 */
export interface TestClockOffsetRequestData {
    /**
     * 
     * @type {number}
     * @memberof TestClockOffsetRequestData
     */
    offset: number;
}
/**
 * 
 * @export
 * @interface TestClockPlusDurationRequestData
 */
export interface TestClockPlusDurationRequestData {
    /**
     * 
     * @type {string}
     * @memberof TestClockPlusDurationRequestData
     */
    plus: string;
}
/**
 * 
 * @export
 * @interface TestClockPlusRequestData
 */
export interface TestClockPlusRequestData {
    /**
     * 
     * @type {number}
     * @memberof TestClockPlusRequestData
     */
    plus: number;
}
/**
 * 
 * @export
 * @interface TestClockRequestData
 */
export interface TestClockRequestData {
    /**
     * 
     * @type {string}
     * @memberof TestClockRequestData
     */
    date: string;
}
/**
 * 
 * @export
 * @interface TestEmailData
 */
export interface TestEmailData {
    /**
     * 
     * @type {string}
     * @memberof TestEmailData
     */
    messageId?: string;
    /**
     * 
     * @type {string}
     * @memberof TestEmailData
     */
    to?: string;
}
/**
 * 
 * @export
 * @interface TestEmailRequestData
 */
export interface TestEmailRequestData {
    /**
     * 
     * @type {string}
     * @memberof TestEmailRequestData
     */
    to?: string;
    /**
     * 
     * @type {string}
     * @memberof TestEmailRequestData
     */
    subject?: string;
    /**
     * 
     * @type {string}
     * @memberof TestEmailRequestData
     */
    content?: string;
    /**
     * 
     * @type {string}
     * @memberof TestEmailRequestData
     */
    from?: string;
}
/**
 * 
 * @export
 * @interface TestSmsData
 */
export interface TestSmsData {
    /**
     * 
     * @type {string}
     * @memberof TestSmsData
     */
    messageId?: string;
}
/**
 * 
 * @export
 * @interface TestSmsRequestData
 */
export interface TestSmsRequestData {
    /**
     * 
     * @type {string}
     * @memberof TestSmsRequestData
     */
    senderName?: string;
    /**
     * 
     * @type {string}
     * @memberof TestSmsRequestData
     */
    phoneNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof TestSmsRequestData
     */
    content?: string;
}
/**
 * 
 * @export
 * @interface TimeSlotData
 */
export interface TimeSlotData {
    /**
     * 
     * @type {number}
     * @memberof TimeSlotData
     */
    meetupId?: number;
    /**
     * 
     * @type {number}
     * @memberof TimeSlotData
     */
    timeSlotId?: number;
    /**
     * 
     * @type {string}
     * @memberof TimeSlotData
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof TimeSlotData
     */
    startTime?: string;
    /**
     * 
     * @type {string}
     * @memberof TimeSlotData
     */
    endTime?: string;
    /**
     * 
     * @type {HallData}
     * @memberof TimeSlotData
     */
    hall?: HallData;
    /**
     * 
     * @type {Array<SpeakerData>}
     * @memberof TimeSlotData
     */
    speakers?: Array<SpeakerData>;
    /**
     * 
     * @type {string}
     * @memberof TimeSlotData
     */
    description?: string;
}
/**
 * 
 * @export
 * @interface TotalVoteData
 */
export interface TotalVoteData {
    /**
     * 
     * @type {string}
     * @memberof TotalVoteData
     */
    choice?: string;
    /**
     * 
     * @type {number}
     * @memberof TotalVoteData
     */
    count?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum TransactionResult {
    Error = 'Error',
    OK = 'OK'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum TransactionStatus {
    Pending = 'Pending',
    PreAuthorized = 'PreAuthorized',
    Declined = 'Declined',
    New = 'New'
}

/**
 * 
 * @export
 * @interface UserData
 */
export interface UserData {
    /**
     * 
     * @type {number}
     * @memberof UserData
     */
    userId?: number;
    /**
     * 
     * @type {string}
     * @memberof UserData
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof UserData
     */
    displayName?: string;
    /**
     * 
     * @type {string}
     * @memberof UserData
     */
    lastActivity?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserData
     */
    active?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserData
     */
    password?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum VisitorBadgeStatusType {
    NoBadge = 'noBadge',
    Printed = 'printed'
}

/**
 * 
 * @export
 * @interface VisitorData
 */
export interface VisitorData {
    /**
     * 
     * @type {number}
     * @memberof VisitorData
     */
    meetupId?: number;
    /**
     * 
     * @type {number}
     * @memberof VisitorData
     */
    visitorId?: number;
    /**
     * 
     * @type {string}
     * @memberof VisitorData
     */
    qrToken?: string;
    /**
     * 
     * @type {string}
     * @memberof VisitorData
     */
    visitorToken?: string;
    /**
     * 
     * @type {string}
     * @memberof VisitorData
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof VisitorData
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof VisitorData
     */
    middleName?: string;
    /**
     * 
     * @type {string}
     * @memberof VisitorData
     */
    photo?: string;
    /**
     * 
     * @type {string}
     * @memberof VisitorData
     */
    phoneNumber?: string;
    /**
     * 
     * @type {boolean}
     * @memberof VisitorData
     */
    phoneNumberConfirmed?: boolean;
    /**
     * 
     * @type {string}
     * @memberof VisitorData
     */
    email?: string;
    /**
     * 
     * @type {boolean}
     * @memberof VisitorData
     */
    emailConfirmed?: boolean;
    /**
     * 
     * @type {number}
     * @memberof VisitorData
     */
    groupId?: number;
    /**
     * 
     * @type {string}
     * @memberof VisitorData
     */
    groupName?: string;
    /**
     * 
     * @type {object}
     * @memberof VisitorData
     */
    extended?: object;
    /**
     * 
     * @type {VisitorStatusData}
     * @memberof VisitorData
     */
    status?: VisitorStatusData;
    /**
     * 
     * @type {string}
     * @memberof VisitorData
     */
    lastModified?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof VisitorData
     */
    tags?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof VisitorData
     */
    makeContactLink?: string;
}
/**
 * 
 * @export
 * @interface VisitorFullData
 */
export interface VisitorFullData {
    /**
     * 
     * @type {VisitorData}
     * @memberof VisitorFullData
     */
    visitor?: VisitorData;
    /**
     * 
     * @type {GroupData}
     * @memberof VisitorFullData
     */
    group?: GroupData;
    /**
     * 
     * @type {BadgeData}
     * @memberof VisitorFullData
     */
    badge?: BadgeData;
}
/**
 * 
 * @export
 * @interface VisitorIdentificationData
 */
export interface VisitorIdentificationData {
    /**
     * 
     * @type {VisitorData}
     * @memberof VisitorIdentificationData
     */
    visitor?: VisitorData;
    /**
     * 
     * @type {string}
     * @memberof VisitorIdentificationData
     */
    code?: string;
}
/**
 * 
 * @export
 * @interface VisitorImportRequestData
 */
export interface VisitorImportRequestData {
    /**
     * 
     * @type {ErrorHandlingType}
     * @memberof VisitorImportRequestData
     */
    onError?: ErrorHandlingType;
    /**
     * 
     * @type {DuplicateHandlingType}
     * @memberof VisitorImportRequestData
     */
    onDuplicate?: DuplicateHandlingType;
    /**
     * 
     * @type {Array<VisitorData>}
     * @memberof VisitorImportRequestData
     */
    visitors?: Array<VisitorData>;
    /**
     * 
     * @type {boolean}
     * @memberof VisitorImportRequestData
     */
    dryRun?: boolean;
}
/**
 * 
 * @export
 * @interface VisitorImportResultData
 */
export interface VisitorImportResultData {
    /**
     * 
     * @type {string}
     * @memberof VisitorImportResultData
     */
    status?: string;
    /**
     * 
     * @type {Array<VisitorData>}
     * @memberof VisitorImportResultData
     */
    visitors?: Array<VisitorData>;
    /**
     * 
     * @type {object}
     * @memberof VisitorImportResultData
     */
    errors?: object;
    /**
     * 
     * @type {number}
     * @memberof VisitorImportResultData
     */
    addedCount?: number;
    /**
     * 
     * @type {number}
     * @memberof VisitorImportResultData
     */
    updatedCount?: number;
    /**
     * 
     * @type {number}
     * @memberof VisitorImportResultData
     */
    skippedCount?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum VisitorSourceType {
    Imported = 'imported',
    Manual = 'manual',
    OnLine = 'onLine',
    OnSite = 'onSite',
    Chat = 'chat'
}

/**
 * 
 * @export
 * @interface VisitorStatusData
 */
export interface VisitorStatusData {
    /**
     * 
     * @type {VisitorSourceType}
     * @memberof VisitorStatusData
     */
    source?: VisitorSourceType;
    /**
     * 
     * @type {string}
     * @memberof VisitorStatusData
     */
    registrationTime?: string;
    /**
     * 
     * @type {VisitorStatusType}
     * @memberof VisitorStatusData
     */
    status?: VisitorStatusType;
    /**
     * 
     * @type {string}
     * @memberof VisitorStatusData
     */
    statusChangeTime?: string;
    /**
     * 
     * @type {VisitorBadgeStatusType}
     * @memberof VisitorStatusData
     */
    badgeStatus?: VisitorBadgeStatusType;
    /**
     * 
     * @type {string}
     * @memberof VisitorStatusData
     */
    badgeStatusTime?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum VisitorStatusType {
    Initial = 'initial',
    Invited = 'invited',
    Recorded = 'recorded',
    Confirmed = 'confirmed',
    Come = 'come',
    Canceled = 'canceled'
}

/**
 * 
 * @export
 * @interface VisitorsQuantityData
 */
export interface VisitorsQuantityData {
    /**
     * 
     * @type {string}
     * @memberof VisitorsQuantityData
     */
    source?: string;
    /**
     * 
     * @type {number}
     * @memberof VisitorsQuantityData
     */
    quantity?: number;
}
/**
 * 
 * @export
 * @interface VoteResultData
 */
export interface VoteResultData {
    /**
     * 
     * @type {ChatIdData}
     * @memberof VoteResultData
     */
    chatId?: ChatIdData;
    /**
     * 
     * @type {number}
     * @memberof VoteResultData
     */
    newsId?: number;
    /**
     * 
     * @type {string}
     * @memberof VoteResultData
     */
    text?: string;
    /**
     * 
     * @type {string}
     * @memberof VoteResultData
     */
    tag?: string;
}
/**
 * 
 * @export
 * @interface WSClientMessage
 */
export interface WSClientMessage {
    /**
     * 
     * @type {string}
     * @memberof WSClientMessage
     */
    register?: string;
    /**
     * 
     * @type {string}
     * @memberof WSClientMessage
     */
    windowId?: string;
    /**
     * 
     * @type {object}
     * @memberof WSClientMessage
     */
    logout?: object;
    /**
     * 
     * @type {object}
     * @memberof WSClientMessage
     */
    ping?: object;
    /**
     * 
     * @type {object}
     * @memberof WSClientMessage
     */
    listVisitors?: object;
    /**
     * 
     * @type {object}
     * @memberof WSClientMessage
     */
    n11s?: object;
}
/**
 * 
 * @export
 * @interface WSServerMessage
 */
export interface WSServerMessage {
    /**
     * 
     * @type {object}
     * @memberof WSServerMessage
     */
    logout?: object;
    /**
     * 
     * @type {string}
     * @memberof WSServerMessage
     */
    windowId?: string;
    /**
     * 
     * @type {string}
     * @memberof WSServerMessage
     */
    pong?: string;
    /**
     * 
     * @type {Array<VisitorData>}
     * @memberof WSServerMessage
     */
    allVisitors?: Array<VisitorData>;
    /**
     * 
     * @type {VisitorData}
     * @memberof WSServerMessage
     */
    visitorUpdate?: VisitorData;
    /**
     * 
     * @type {object}
     * @memberof WSServerMessage
     */
    deskSettingsUpdate?: object;
    /**
     * 
     * @type {number}
     * @memberof WSServerMessage
     */
    offset?: number;
    /**
     * 
     * @type {Array<NotificationData>}
     * @memberof WSServerMessage
     */
    n11s?: Array<NotificationData>;
    /**
     * 
     * @type {string}
     * @memberof WSServerMessage
     */
    n11sClear?: string;
}
/**
 * 
 * @export
 * @interface WriteHistoryData
 */
export interface WriteHistoryData {
    /**
     * 
     * @type {ChatIdData}
     * @memberof WriteHistoryData
     */
    chatId?: ChatIdData;
    /**
     * 
     * @type {ChannelInfoData}
     * @memberof WriteHistoryData
     */
    channel?: ChannelInfoData;
    /**
     * 
     * @type {string}
     * @memberof WriteHistoryData
     */
    chatUserName?: string;
    /**
     * 
     * @type {string}
     * @memberof WriteHistoryData
     */
    timestamp?: string;
    /**
     * 
     * @type {ParticipantType}
     * @memberof WriteHistoryData
     */
    participant?: ParticipantType;
    /**
     * 
     * @type {string}
     * @memberof WriteHistoryData
     */
    message?: string;
    /**
     * 
     * @type {object}
     * @memberof WriteHistoryData
     */
    fullMessage?: object;
    /**
     * 
     * @type {string}
     * @memberof WriteHistoryData
     */
    username?: string;
    /**
     * 
     * @type {number}
     * @memberof WriteHistoryData
     */
    visitorId?: number;
    /**
     * 
     * @type {string}
     * @memberof WriteHistoryData
     */
    tag?: string;
}
/**
 * 
 * @export
 * @interface XYData
 */
export interface XYData {
    /**
     * 
     * @type {number}
     * @memberof XYData
     */
    x?: number;
    /**
     * 
     * @type {number}
     * @memberof XYData
     */
    y?: number;
}

/**
 * AccountApi - axios parameter creator
 * @export
 */
export const AccountApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} accountId Account identifier.
         * @param {ApplyPackRequestData} applyPackRequestData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applyPack(accountId: number, applyPackRequestData: ApplyPackRequestData, options: any = {}): RequestArgs {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId','Required parameter accountId was null or undefined when calling applyPack.');
            }
            // verify required parameter 'applyPackRequestData' is not null or undefined
            if (applyPackRequestData === null || applyPackRequestData === undefined) {
                throw new RequiredError('applyPackRequestData','Required parameter applyPackRequestData was null or undefined when calling applyPack.');
            }
            const localVarPath = `/api/account/{accountId}/apply-pack`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"ApplyPackRequestData" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(applyPackRequestData !== undefined ? applyPackRequestData : {}) : (applyPackRequestData || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} accountId Account identifier.
         * @param {OnlinePaymentRequestData} onlinePaymentRequestData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buyOnline(accountId: number, onlinePaymentRequestData: OnlinePaymentRequestData, options: any = {}): RequestArgs {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId','Required parameter accountId was null or undefined when calling buyOnline.');
            }
            // verify required parameter 'onlinePaymentRequestData' is not null or undefined
            if (onlinePaymentRequestData === null || onlinePaymentRequestData === undefined) {
                throw new RequiredError('onlinePaymentRequestData','Required parameter onlinePaymentRequestData was null or undefined when calling buyOnline.');
            }
            const localVarPath = `/api/account/{accountId}/buy-online`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"OnlinePaymentRequestData" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(onlinePaymentRequestData !== undefined ? onlinePaymentRequestData : {}) : (onlinePaymentRequestData || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} accountId Account identifier.
         * @param {RebillPaymentRequestData} rebillPaymentRequestData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buyWithRebill(accountId: number, rebillPaymentRequestData: RebillPaymentRequestData, options: any = {}): RequestArgs {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId','Required parameter accountId was null or undefined when calling buyWithRebill.');
            }
            // verify required parameter 'rebillPaymentRequestData' is not null or undefined
            if (rebillPaymentRequestData === null || rebillPaymentRequestData === undefined) {
                throw new RequiredError('rebillPaymentRequestData','Required parameter rebillPaymentRequestData was null or undefined when calling buyWithRebill.');
            }
            const localVarPath = `/api/account/{accountId}/buy-rebill`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"RebillPaymentRequestData" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(rebillPaymentRequestData !== undefined ? rebillPaymentRequestData : {}) : (rebillPaymentRequestData || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} accountId Account identifier.
         * @param {ChangeBalanceRequestData} changeBalanceRequestData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeBalance(accountId: number, changeBalanceRequestData: ChangeBalanceRequestData, options: any = {}): RequestArgs {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId','Required parameter accountId was null or undefined when calling changeBalance.');
            }
            // verify required parameter 'changeBalanceRequestData' is not null or undefined
            if (changeBalanceRequestData === null || changeBalanceRequestData === undefined) {
                throw new RequiredError('changeBalanceRequestData','Required parameter changeBalanceRequestData was null or undefined when calling changeBalance.');
            }
            const localVarPath = `/api/account/{accountId}/change-balance`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"ChangeBalanceRequestData" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(changeBalanceRequestData !== undefined ? changeBalanceRequestData : {}) : (changeBalanceRequestData || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} accountId Account identifier.
         * @param {ChangePlanRequestData} changePlanRequestData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changePlan(accountId: number, changePlanRequestData: ChangePlanRequestData, options: any = {}): RequestArgs {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId','Required parameter accountId was null or undefined when calling changePlan.');
            }
            // verify required parameter 'changePlanRequestData' is not null or undefined
            if (changePlanRequestData === null || changePlanRequestData === undefined) {
                throw new RequiredError('changePlanRequestData','Required parameter changePlanRequestData was null or undefined when calling changePlan.');
            }
            const localVarPath = `/api/account/{accountId}/change-plan`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"ChangePlanRequestData" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(changePlanRequestData !== undefined ? changePlanRequestData : {}) : (changePlanRequestData || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AccountRegisterData} accountRegisterData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAccount(accountRegisterData: AccountRegisterData, options: any = {}): RequestArgs {
            // verify required parameter 'accountRegisterData' is not null or undefined
            if (accountRegisterData === null || accountRegisterData === undefined) {
                throw new RequiredError('accountRegisterData','Required parameter accountRegisterData was null or undefined when calling createAccount.');
            }
            const localVarPath = `/api/account/`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"AccountRegisterData" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(accountRegisterData !== undefined ? accountRegisterData : {}) : (accountRegisterData || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} accountId Account identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAllByAccount(accountId: number, options: any = {}): RequestArgs {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId','Required parameter accountId was null or undefined when calling deleteAllByAccount.');
            }
            const localVarPath = `/api/account/{accountId}`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} accountId Account identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccount(accountId: number, options: any = {}): RequestArgs {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId','Required parameter accountId was null or undefined when calling getAccount.');
            }
            const localVarPath = `/api/account/{accountId}`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} accountId Account identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentCard(accountId: number, options: any = {}): RequestArgs {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId','Required parameter accountId was null or undefined when calling getCurrentCard.');
            }
            const localVarPath = `/api/account/{accountId}/get-card`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} accountId Account identifier.
         * @param {number} paymentId Payment identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPayment(accountId: number, paymentId: number, options: any = {}): RequestArgs {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId','Required parameter accountId was null or undefined when calling getPayment.');
            }
            // verify required parameter 'paymentId' is not null or undefined
            if (paymentId === null || paymentId === undefined) {
                throw new RequiredError('paymentId','Required parameter paymentId was null or undefined when calling getPayment.');
            }
            const localVarPath = `/api/account/{accountId}/payment/{paymentId}`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"paymentId"}}`, encodeURIComponent(String(paymentId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] Page number.
         * @param {number} [pageSize] Page size.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAccounts(page?: number, pageSize?: number, options: any = {}): RequestArgs {
            const localVarPath = `/api/account/`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAllPayments(options: any = {}): RequestArgs {
            const localVarPath = `/api/payments-all`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} accountId Account identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPayments(accountId: number, options: any = {}): RequestArgs {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId','Required parameter accountId was null or undefined when calling listPayments.');
            }
            const localVarPath = `/api/account/{accountId}/payment`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [category] Filtration category.
         * @param {number} [page] Page number.
         * @param {number} [pageSize] Page size.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listStatLog(category?: string, page?: number, pageSize?: number, options: any = {}): RequestArgs {
            const localVarPath = `/api/stat-log`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (category !== undefined) {
                localVarQueryParameter['category'] = category;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} accountId Account identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginIntoAccount(accountId: number, options: any = {}): RequestArgs {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId','Required parameter accountId was null or undefined when calling loginIntoAccount.');
            }
            const localVarPath = `/api/account/{accountId}/login-into`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logoutToSuperAccount(options: any = {}): RequestArgs {
            const localVarPath = `/api/account/login-out`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AccountRegisterData} accountRegisterData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerAccount(accountRegisterData: AccountRegisterData, options: any = {}): RequestArgs {
            // verify required parameter 'accountRegisterData' is not null or undefined
            if (accountRegisterData === null || accountRegisterData === undefined) {
                throw new RequiredError('accountRegisterData','Required parameter accountRegisterData was null or undefined when calling registerAccount.');
            }
            const localVarPath = `/api/register-account`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"AccountRegisterData" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(accountRegisterData !== undefined ? accountRegisterData : {}) : (accountRegisterData || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} accountId Account identifier.
         * @param {RequestPlanData} requestPlanData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendPlanRequest(accountId: number, requestPlanData: RequestPlanData, options: any = {}): RequestArgs {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId','Required parameter accountId was null or undefined when calling sendPlanRequest.');
            }
            // verify required parameter 'requestPlanData' is not null or undefined
            if (requestPlanData === null || requestPlanData === undefined) {
                throw new RequiredError('requestPlanData','Required parameter requestPlanData was null or undefined when calling sendPlanRequest.');
            }
            const localVarPath = `/api/account/{accountId}/request-plan`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"RequestPlanData" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(requestPlanData !== undefined ? requestPlanData : {}) : (requestPlanData || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} accountId Account identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unlinkCard(accountId: number, options: any = {}): RequestArgs {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId','Required parameter accountId was null or undefined when calling unlinkCard.');
            }
            const localVarPath = `/api/account/{accountId}/unlink-card`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} accountId Account identifier.
         * @param {AccountData} accountData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAccount(accountId: number, accountData: AccountData, options: any = {}): RequestArgs {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId','Required parameter accountId was null or undefined when calling updateAccount.');
            }
            // verify required parameter 'accountData' is not null or undefined
            if (accountData === null || accountData === undefined) {
                throw new RequiredError('accountData','Required parameter accountData was null or undefined when calling updateAccount.');
            }
            const localVarPath = `/api/account/{accountId}`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"AccountData" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(accountData !== undefined ? accountData : {}) : (accountData || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {StatLogData} statLogData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        writeStatLog(statLogData: StatLogData, options: any = {}): RequestArgs {
            // verify required parameter 'statLogData' is not null or undefined
            if (statLogData === null || statLogData === undefined) {
                throw new RequiredError('statLogData','Required parameter statLogData was null or undefined when calling writeStatLog.');
            }
            const localVarPath = `/api/account/write-stat-log`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"StatLogData" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(statLogData !== undefined ? statLogData : {}) : (statLogData || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AccountApi - functional programming interface
 * @export
 */
export const AccountApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} accountId Account identifier.
         * @param {ApplyPackRequestData} applyPackRequestData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applyPack(accountId: number, applyPackRequestData: ApplyPackRequestData, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = AccountApiAxiosParamCreator(configuration).applyPack(accountId, applyPackRequestData, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} accountId Account identifier.
         * @param {OnlinePaymentRequestData} onlinePaymentRequestData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buyOnline(accountId: number, onlinePaymentRequestData: OnlinePaymentRequestData, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string> {
            const localVarAxiosArgs = AccountApiAxiosParamCreator(configuration).buyOnline(accountId, onlinePaymentRequestData, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} accountId Account identifier.
         * @param {RebillPaymentRequestData} rebillPaymentRequestData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buyWithRebill(accountId: number, rebillPaymentRequestData: RebillPaymentRequestData, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentResult> {
            const localVarAxiosArgs = AccountApiAxiosParamCreator(configuration).buyWithRebill(accountId, rebillPaymentRequestData, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} accountId Account identifier.
         * @param {ChangeBalanceRequestData} changeBalanceRequestData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeBalance(accountId: number, changeBalanceRequestData: ChangeBalanceRequestData, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = AccountApiAxiosParamCreator(configuration).changeBalance(accountId, changeBalanceRequestData, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} accountId Account identifier.
         * @param {ChangePlanRequestData} changePlanRequestData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changePlan(accountId: number, changePlanRequestData: ChangePlanRequestData, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = AccountApiAxiosParamCreator(configuration).changePlan(accountId, changePlanRequestData, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {AccountRegisterData} accountRegisterData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAccount(accountRegisterData: AccountRegisterData, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountData> {
            const localVarAxiosArgs = AccountApiAxiosParamCreator(configuration).createAccount(accountRegisterData, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} accountId Account identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAllByAccount(accountId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = AccountApiAxiosParamCreator(configuration).deleteAllByAccount(accountId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} accountId Account identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccount(accountId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountData> {
            const localVarAxiosArgs = AccountApiAxiosParamCreator(configuration).getAccount(accountId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} accountId Account identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentCard(accountId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<CardData> {
            const localVarAxiosArgs = AccountApiAxiosParamCreator(configuration).getCurrentCard(accountId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} accountId Account identifier.
         * @param {number} paymentId Payment identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPayment(accountId: number, paymentId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<BalanceRecordData> {
            const localVarAxiosArgs = AccountApiAxiosParamCreator(configuration).getPayment(accountId, paymentId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} [page] Page number.
         * @param {number} [pageSize] Page size.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAccounts(page?: number, pageSize?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountPaginationListData> {
            const localVarAxiosArgs = AccountApiAxiosParamCreator(configuration).listAccounts(page, pageSize, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAllPayments(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BalanceRecordData>> {
            const localVarAxiosArgs = AccountApiAxiosParamCreator(configuration).listAllPayments(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} accountId Account identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPayments(accountId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BalanceRecordData>> {
            const localVarAxiosArgs = AccountApiAxiosParamCreator(configuration).listPayments(accountId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [category] Filtration category.
         * @param {number} [page] Page number.
         * @param {number} [pageSize] Page size.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listStatLog(category?: string, page?: number, pageSize?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatLogPaginationData> {
            const localVarAxiosArgs = AccountApiAxiosParamCreator(configuration).listStatLog(category, page, pageSize, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} accountId Account identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginIntoAccount(accountId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SessionInfoData> {
            const localVarAxiosArgs = AccountApiAxiosParamCreator(configuration).loginIntoAccount(accountId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logoutToSuperAccount(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SessionInfoData> {
            const localVarAxiosArgs = AccountApiAxiosParamCreator(configuration).logoutToSuperAccount(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {AccountRegisterData} accountRegisterData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerAccount(accountRegisterData: AccountRegisterData, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = AccountApiAxiosParamCreator(configuration).registerAccount(accountRegisterData, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} accountId Account identifier.
         * @param {RequestPlanData} requestPlanData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendPlanRequest(accountId: number, requestPlanData: RequestPlanData, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = AccountApiAxiosParamCreator(configuration).sendPlanRequest(accountId, requestPlanData, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} accountId Account identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unlinkCard(accountId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = AccountApiAxiosParamCreator(configuration).unlinkCard(accountId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} accountId Account identifier.
         * @param {AccountData} accountData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAccount(accountId: number, accountData: AccountData, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountData> {
            const localVarAxiosArgs = AccountApiAxiosParamCreator(configuration).updateAccount(accountId, accountData, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {StatLogData} statLogData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        writeStatLog(statLogData: StatLogData, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatLogData> {
            const localVarAxiosArgs = AccountApiAxiosParamCreator(configuration).writeStatLog(statLogData, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * AccountApi - factory interface
 * @export
 */
export const AccountApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {number} accountId Account identifier.
         * @param {ApplyPackRequestData} applyPackRequestData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applyPack(accountId: number, applyPackRequestData: ApplyPackRequestData, options?: any) {
            return AccountApiFp(configuration).applyPack(accountId, applyPackRequestData, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} accountId Account identifier.
         * @param {OnlinePaymentRequestData} onlinePaymentRequestData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buyOnline(accountId: number, onlinePaymentRequestData: OnlinePaymentRequestData, options?: any) {
            return AccountApiFp(configuration).buyOnline(accountId, onlinePaymentRequestData, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} accountId Account identifier.
         * @param {RebillPaymentRequestData} rebillPaymentRequestData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buyWithRebill(accountId: number, rebillPaymentRequestData: RebillPaymentRequestData, options?: any) {
            return AccountApiFp(configuration).buyWithRebill(accountId, rebillPaymentRequestData, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} accountId Account identifier.
         * @param {ChangeBalanceRequestData} changeBalanceRequestData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeBalance(accountId: number, changeBalanceRequestData: ChangeBalanceRequestData, options?: any) {
            return AccountApiFp(configuration).changeBalance(accountId, changeBalanceRequestData, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} accountId Account identifier.
         * @param {ChangePlanRequestData} changePlanRequestData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changePlan(accountId: number, changePlanRequestData: ChangePlanRequestData, options?: any) {
            return AccountApiFp(configuration).changePlan(accountId, changePlanRequestData, options)(axios, basePath);
        },
        /**
         * 
         * @param {AccountRegisterData} accountRegisterData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAccount(accountRegisterData: AccountRegisterData, options?: any) {
            return AccountApiFp(configuration).createAccount(accountRegisterData, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} accountId Account identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAllByAccount(accountId: number, options?: any) {
            return AccountApiFp(configuration).deleteAllByAccount(accountId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} accountId Account identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccount(accountId: number, options?: any) {
            return AccountApiFp(configuration).getAccount(accountId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} accountId Account identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentCard(accountId: number, options?: any) {
            return AccountApiFp(configuration).getCurrentCard(accountId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} accountId Account identifier.
         * @param {number} paymentId Payment identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPayment(accountId: number, paymentId: number, options?: any) {
            return AccountApiFp(configuration).getPayment(accountId, paymentId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} [page] Page number.
         * @param {number} [pageSize] Page size.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAccounts(page?: number, pageSize?: number, options?: any) {
            return AccountApiFp(configuration).listAccounts(page, pageSize, options)(axios, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAllPayments(options?: any) {
            return AccountApiFp(configuration).listAllPayments(options)(axios, basePath);
        },
        /**
         * 
         * @param {number} accountId Account identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPayments(accountId: number, options?: any) {
            return AccountApiFp(configuration).listPayments(accountId, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} [category] Filtration category.
         * @param {number} [page] Page number.
         * @param {number} [pageSize] Page size.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listStatLog(category?: string, page?: number, pageSize?: number, options?: any) {
            return AccountApiFp(configuration).listStatLog(category, page, pageSize, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} accountId Account identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginIntoAccount(accountId: number, options?: any) {
            return AccountApiFp(configuration).loginIntoAccount(accountId, options)(axios, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logoutToSuperAccount(options?: any) {
            return AccountApiFp(configuration).logoutToSuperAccount(options)(axios, basePath);
        },
        /**
         * 
         * @param {AccountRegisterData} accountRegisterData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerAccount(accountRegisterData: AccountRegisterData, options?: any) {
            return AccountApiFp(configuration).registerAccount(accountRegisterData, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} accountId Account identifier.
         * @param {RequestPlanData} requestPlanData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendPlanRequest(accountId: number, requestPlanData: RequestPlanData, options?: any) {
            return AccountApiFp(configuration).sendPlanRequest(accountId, requestPlanData, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} accountId Account identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unlinkCard(accountId: number, options?: any) {
            return AccountApiFp(configuration).unlinkCard(accountId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} accountId Account identifier.
         * @param {AccountData} accountData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAccount(accountId: number, accountData: AccountData, options?: any) {
            return AccountApiFp(configuration).updateAccount(accountId, accountData, options)(axios, basePath);
        },
        /**
         * 
         * @param {StatLogData} statLogData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        writeStatLog(statLogData: StatLogData, options?: any) {
            return AccountApiFp(configuration).writeStatLog(statLogData, options)(axios, basePath);
        },
    };
};

/**
 * AccountApi - object-oriented interface
 * @export
 * @class AccountApi
 * @extends {BaseAPI}
 */
export class AccountApi extends BaseAPI {
    /**
     * 
     * @param {number} accountId Account identifier.
     * @param {ApplyPackRequestData} applyPackRequestData 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public applyPack(accountId: number, applyPackRequestData: ApplyPackRequestData, options?: any) {
        return AccountApiFp(this.configuration).applyPack(accountId, applyPackRequestData, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} accountId Account identifier.
     * @param {OnlinePaymentRequestData} onlinePaymentRequestData 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public buyOnline(accountId: number, onlinePaymentRequestData: OnlinePaymentRequestData, options?: any) {
        return AccountApiFp(this.configuration).buyOnline(accountId, onlinePaymentRequestData, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} accountId Account identifier.
     * @param {RebillPaymentRequestData} rebillPaymentRequestData 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public buyWithRebill(accountId: number, rebillPaymentRequestData: RebillPaymentRequestData, options?: any) {
        return AccountApiFp(this.configuration).buyWithRebill(accountId, rebillPaymentRequestData, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} accountId Account identifier.
     * @param {ChangeBalanceRequestData} changeBalanceRequestData 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public changeBalance(accountId: number, changeBalanceRequestData: ChangeBalanceRequestData, options?: any) {
        return AccountApiFp(this.configuration).changeBalance(accountId, changeBalanceRequestData, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} accountId Account identifier.
     * @param {ChangePlanRequestData} changePlanRequestData 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public changePlan(accountId: number, changePlanRequestData: ChangePlanRequestData, options?: any) {
        return AccountApiFp(this.configuration).changePlan(accountId, changePlanRequestData, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {AccountRegisterData} accountRegisterData 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public createAccount(accountRegisterData: AccountRegisterData, options?: any) {
        return AccountApiFp(this.configuration).createAccount(accountRegisterData, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} accountId Account identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public deleteAllByAccount(accountId: number, options?: any) {
        return AccountApiFp(this.configuration).deleteAllByAccount(accountId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} accountId Account identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public getAccount(accountId: number, options?: any) {
        return AccountApiFp(this.configuration).getAccount(accountId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} accountId Account identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public getCurrentCard(accountId: number, options?: any) {
        return AccountApiFp(this.configuration).getCurrentCard(accountId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} accountId Account identifier.
     * @param {number} paymentId Payment identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public getPayment(accountId: number, paymentId: number, options?: any) {
        return AccountApiFp(this.configuration).getPayment(accountId, paymentId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} [page] Page number.
     * @param {number} [pageSize] Page size.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public listAccounts(page?: number, pageSize?: number, options?: any) {
        return AccountApiFp(this.configuration).listAccounts(page, pageSize, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public listAllPayments(options?: any) {
        return AccountApiFp(this.configuration).listAllPayments(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} accountId Account identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public listPayments(accountId: number, options?: any) {
        return AccountApiFp(this.configuration).listPayments(accountId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} [category] Filtration category.
     * @param {number} [page] Page number.
     * @param {number} [pageSize] Page size.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public listStatLog(category?: string, page?: number, pageSize?: number, options?: any) {
        return AccountApiFp(this.configuration).listStatLog(category, page, pageSize, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} accountId Account identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public loginIntoAccount(accountId: number, options?: any) {
        return AccountApiFp(this.configuration).loginIntoAccount(accountId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public logoutToSuperAccount(options?: any) {
        return AccountApiFp(this.configuration).logoutToSuperAccount(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {AccountRegisterData} accountRegisterData 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public registerAccount(accountRegisterData: AccountRegisterData, options?: any) {
        return AccountApiFp(this.configuration).registerAccount(accountRegisterData, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} accountId Account identifier.
     * @param {RequestPlanData} requestPlanData 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public sendPlanRequest(accountId: number, requestPlanData: RequestPlanData, options?: any) {
        return AccountApiFp(this.configuration).sendPlanRequest(accountId, requestPlanData, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} accountId Account identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public unlinkCard(accountId: number, options?: any) {
        return AccountApiFp(this.configuration).unlinkCard(accountId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} accountId Account identifier.
     * @param {AccountData} accountData 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public updateAccount(accountId: number, accountData: AccountData, options?: any) {
        return AccountApiFp(this.configuration).updateAccount(accountId, accountData, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {StatLogData} statLogData 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public writeStatLog(statLogData: StatLogData, options?: any) {
        return AccountApiFp(this.configuration).writeStatLog(statLogData, options)(this.axios, this.basePath);
    }

}


/**
 * AuthApi - axios parameter creator
 * @export
 */
export const AuthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ChangePasswordData} changePasswordData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changePassword(changePasswordData: ChangePasswordData, options: any = {}): RequestArgs {
            // verify required parameter 'changePasswordData' is not null or undefined
            if (changePasswordData === null || changePasswordData === undefined) {
                throw new RequiredError('changePasswordData','Required parameter changePasswordData was null or undefined when calling changePassword.');
            }
            const localVarPath = `/api/change-password`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"ChangePasswordData" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(changePasswordData !== undefined ? changePasswordData : {}) : (changePasswordData || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Check session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkSession(options: any = {}): RequestArgs {
            const localVarPath = `/api/check-session`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Login
         * @param {LoginRequestData} loginRequestData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login(loginRequestData: LoginRequestData, options: any = {}): RequestArgs {
            // verify required parameter 'loginRequestData' is not null or undefined
            if (loginRequestData === null || loginRequestData === undefined) {
                throw new RequiredError('loginRequestData','Required parameter loginRequestData was null or undefined when calling login.');
            }
            const localVarPath = `/api/login`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"LoginRequestData" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(loginRequestData !== undefined ? loginRequestData : {}) : (loginRequestData || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Logout
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logout(options: any = {}): RequestArgs {
            const localVarPath = `/api/logout`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RequestChangePasswordData} requestChangePasswordData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestChangePassword(requestChangePasswordData: RequestChangePasswordData, options: any = {}): RequestArgs {
            // verify required parameter 'requestChangePasswordData' is not null or undefined
            if (requestChangePasswordData === null || requestChangePasswordData === undefined) {
                throw new RequiredError('requestChangePasswordData','Required parameter requestChangePasswordData was null or undefined when calling requestChangePassword.');
            }
            const localVarPath = `/api/request-change-password`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"RequestChangePasswordData" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(requestChangePasswordData !== undefined ? requestChangePasswordData : {}) : (requestChangePasswordData || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthApi - functional programming interface
 * @export
 */
export const AuthApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ChangePasswordData} changePasswordData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changePassword(changePasswordData: ChangePasswordData, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = AuthApiAxiosParamCreator(configuration).changePassword(changePasswordData, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Check session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkSession(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SessionInfoData> {
            const localVarAxiosArgs = AuthApiAxiosParamCreator(configuration).checkSession(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Login
         * @param {LoginRequestData} loginRequestData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login(loginRequestData: LoginRequestData, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SessionInfoData> {
            const localVarAxiosArgs = AuthApiAxiosParamCreator(configuration).login(loginRequestData, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Logout
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logout(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = AuthApiAxiosParamCreator(configuration).logout(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {RequestChangePasswordData} requestChangePasswordData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestChangePassword(requestChangePasswordData: RequestChangePasswordData, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = AuthApiAxiosParamCreator(configuration).requestChangePassword(requestChangePasswordData, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * AuthApi - factory interface
 * @export
 */
export const AuthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {ChangePasswordData} changePasswordData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changePassword(changePasswordData: ChangePasswordData, options?: any) {
            return AuthApiFp(configuration).changePassword(changePasswordData, options)(axios, basePath);
        },
        /**
         * 
         * @summary Check session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkSession(options?: any) {
            return AuthApiFp(configuration).checkSession(options)(axios, basePath);
        },
        /**
         * 
         * @summary Login
         * @param {LoginRequestData} loginRequestData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login(loginRequestData: LoginRequestData, options?: any) {
            return AuthApiFp(configuration).login(loginRequestData, options)(axios, basePath);
        },
        /**
         * 
         * @summary Logout
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logout(options?: any) {
            return AuthApiFp(configuration).logout(options)(axios, basePath);
        },
        /**
         * 
         * @param {RequestChangePasswordData} requestChangePasswordData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestChangePassword(requestChangePasswordData: RequestChangePasswordData, options?: any) {
            return AuthApiFp(configuration).requestChangePassword(requestChangePasswordData, options)(axios, basePath);
        },
    };
};

/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI {
    /**
     * 
     * @param {ChangePasswordData} changePasswordData 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public changePassword(changePasswordData: ChangePasswordData, options?: any) {
        return AuthApiFp(this.configuration).changePassword(changePasswordData, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Check session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public checkSession(options?: any) {
        return AuthApiFp(this.configuration).checkSession(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Login
     * @param {LoginRequestData} loginRequestData 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public login(loginRequestData: LoginRequestData, options?: any) {
        return AuthApiFp(this.configuration).login(loginRequestData, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Logout
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public logout(options?: any) {
        return AuthApiFp(this.configuration).logout(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {RequestChangePasswordData} requestChangePasswordData 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public requestChangePassword(requestChangePasswordData: RequestChangePasswordData, options?: any) {
        return AuthApiFp(this.configuration).requestChangePassword(requestChangePasswordData, options)(this.axios, this.basePath);
    }

}


/**
 * BadgeApi - axios parameter creator
 * @export
 */
export const BadgeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {BadgeData} badgeData 
         * @param {number} [mid] Meetup identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBadge(badgeData: BadgeData, mid?: number, options: any = {}): RequestArgs {
            // verify required parameter 'badgeData' is not null or undefined
            if (badgeData === null || badgeData === undefined) {
                throw new RequiredError('badgeData','Required parameter badgeData was null or undefined when calling createBadge.');
            }
            const localVarPath = `/api/badge`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (mid !== undefined) {
                localVarQueryParameter['mid'] = mid;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"BadgeData" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(badgeData !== undefined ? badgeData : {}) : (badgeData || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} badgeId Badge identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBadge(badgeId: number, options: any = {}): RequestArgs {
            // verify required parameter 'badgeId' is not null or undefined
            if (badgeId === null || badgeId === undefined) {
                throw new RequiredError('badgeId','Required parameter badgeId was null or undefined when calling deleteBadge.');
            }
            const localVarPath = `/api/badge/{badgeId}`
                .replace(`{${"badgeId"}}`, encodeURIComponent(String(badgeId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} badgeId Badge identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBadge(badgeId: number, options: any = {}): RequestArgs {
            // verify required parameter 'badgeId' is not null or undefined
            if (badgeId === null || badgeId === undefined) {
                throw new RequiredError('badgeId','Required parameter badgeId was null or undefined when calling getBadge.');
            }
            const localVarPath = `/api/badge/{badgeId}`
                .replace(`{${"badgeId"}}`, encodeURIComponent(String(badgeId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} badgeId Badge identifier.
         * @param {number} aid Account identifier.
         * @param {number} vid Visitor identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPdfBadge(badgeId: number, aid: number, vid: number, options: any = {}): RequestArgs {
            // verify required parameter 'badgeId' is not null or undefined
            if (badgeId === null || badgeId === undefined) {
                throw new RequiredError('badgeId','Required parameter badgeId was null or undefined when calling getPdfBadge.');
            }
            // verify required parameter 'aid' is not null or undefined
            if (aid === null || aid === undefined) {
                throw new RequiredError('aid','Required parameter aid was null or undefined when calling getPdfBadge.');
            }
            // verify required parameter 'vid' is not null or undefined
            if (vid === null || vid === undefined) {
                throw new RequiredError('vid','Required parameter vid was null or undefined when calling getPdfBadge.');
            }
            const localVarPath = `/api/badge/{badgeId}/badge`
                .replace(`{${"badgeId"}}`, encodeURIComponent(String(badgeId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (aid !== undefined) {
                localVarQueryParameter['aid'] = aid;
            }

            if (vid !== undefined) {
                localVarQueryParameter['vid'] = vid;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [mid] Meetup identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listBadges(mid?: number, options: any = {}): RequestArgs {
            const localVarPath = `/api/badge`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (mid !== undefined) {
                localVarQueryParameter['mid'] = mid;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} badgeId Badge identifier.
         * @param {BadgeData} badgeData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBadge(badgeId: number, badgeData: BadgeData, options: any = {}): RequestArgs {
            // verify required parameter 'badgeId' is not null or undefined
            if (badgeId === null || badgeId === undefined) {
                throw new RequiredError('badgeId','Required parameter badgeId was null or undefined when calling updateBadge.');
            }
            // verify required parameter 'badgeData' is not null or undefined
            if (badgeData === null || badgeData === undefined) {
                throw new RequiredError('badgeData','Required parameter badgeData was null or undefined when calling updateBadge.');
            }
            const localVarPath = `/api/badge/{badgeId}`
                .replace(`{${"badgeId"}}`, encodeURIComponent(String(badgeId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"BadgeData" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(badgeData !== undefined ? badgeData : {}) : (badgeData || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BadgeApi - functional programming interface
 * @export
 */
export const BadgeApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {BadgeData} badgeData 
         * @param {number} [mid] Meetup identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBadge(badgeData: BadgeData, mid?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<BadgeData> {
            const localVarAxiosArgs = BadgeApiAxiosParamCreator(configuration).createBadge(badgeData, mid, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} badgeId Badge identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBadge(badgeId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = BadgeApiAxiosParamCreator(configuration).deleteBadge(badgeId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} badgeId Badge identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBadge(badgeId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<BadgeData> {
            const localVarAxiosArgs = BadgeApiAxiosParamCreator(configuration).getBadge(badgeId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} badgeId Badge identifier.
         * @param {number} aid Account identifier.
         * @param {number} vid Visitor identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPdfBadge(badgeId: number, aid: number, vid: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any> {
            const localVarAxiosArgs = BadgeApiAxiosParamCreator(configuration).getPdfBadge(badgeId, aid, vid, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} [mid] Meetup identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listBadges(mid?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BadgeData>> {
            const localVarAxiosArgs = BadgeApiAxiosParamCreator(configuration).listBadges(mid, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} badgeId Badge identifier.
         * @param {BadgeData} badgeData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBadge(badgeId: number, badgeData: BadgeData, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<BadgeData> {
            const localVarAxiosArgs = BadgeApiAxiosParamCreator(configuration).updateBadge(badgeId, badgeData, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * BadgeApi - factory interface
 * @export
 */
export const BadgeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {BadgeData} badgeData 
         * @param {number} [mid] Meetup identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBadge(badgeData: BadgeData, mid?: number, options?: any) {
            return BadgeApiFp(configuration).createBadge(badgeData, mid, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} badgeId Badge identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBadge(badgeId: number, options?: any) {
            return BadgeApiFp(configuration).deleteBadge(badgeId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} badgeId Badge identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBadge(badgeId: number, options?: any) {
            return BadgeApiFp(configuration).getBadge(badgeId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} badgeId Badge identifier.
         * @param {number} aid Account identifier.
         * @param {number} vid Visitor identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPdfBadge(badgeId: number, aid: number, vid: number, options?: any) {
            return BadgeApiFp(configuration).getPdfBadge(badgeId, aid, vid, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} [mid] Meetup identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listBadges(mid?: number, options?: any) {
            return BadgeApiFp(configuration).listBadges(mid, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} badgeId Badge identifier.
         * @param {BadgeData} badgeData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBadge(badgeId: number, badgeData: BadgeData, options?: any) {
            return BadgeApiFp(configuration).updateBadge(badgeId, badgeData, options)(axios, basePath);
        },
    };
};

/**
 * BadgeApi - object-oriented interface
 * @export
 * @class BadgeApi
 * @extends {BaseAPI}
 */
export class BadgeApi extends BaseAPI {
    /**
     * 
     * @param {BadgeData} badgeData 
     * @param {number} [mid] Meetup identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BadgeApi
     */
    public createBadge(badgeData: BadgeData, mid?: number, options?: any) {
        return BadgeApiFp(this.configuration).createBadge(badgeData, mid, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} badgeId Badge identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BadgeApi
     */
    public deleteBadge(badgeId: number, options?: any) {
        return BadgeApiFp(this.configuration).deleteBadge(badgeId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} badgeId Badge identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BadgeApi
     */
    public getBadge(badgeId: number, options?: any) {
        return BadgeApiFp(this.configuration).getBadge(badgeId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} badgeId Badge identifier.
     * @param {number} aid Account identifier.
     * @param {number} vid Visitor identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BadgeApi
     */
    public getPdfBadge(badgeId: number, aid: number, vid: number, options?: any) {
        return BadgeApiFp(this.configuration).getPdfBadge(badgeId, aid, vid, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} [mid] Meetup identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BadgeApi
     */
    public listBadges(mid?: number, options?: any) {
        return BadgeApiFp(this.configuration).listBadges(mid, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} badgeId Badge identifier.
     * @param {BadgeData} badgeData 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BadgeApi
     */
    public updateBadge(badgeId: number, badgeData: BadgeData, options?: any) {
        return BadgeApiFp(this.configuration).updateBadge(badgeId, badgeData, options)(this.axios, this.basePath);
    }

}


/**
 * BotApi - axios parameter creator
 * @export
 */
export const BotApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllActiveBots(options: any = {}): RequestArgs {
            const localVarPath = `/api/active-bots`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} botToken Bot channel token.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBotConfig(botToken: string, options: any = {}): RequestArgs {
            // verify required parameter 'botToken' is not null or undefined
            if (botToken === null || botToken === undefined) {
                throw new RequiredError('botToken','Required parameter botToken was null or undefined when calling getBotConfig.');
            }
            const localVarPath = `/api/bot/{botToken}`
                .replace(`{${"botToken"}}`, encodeURIComponent(String(botToken)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {PushRequestData} pushRequestData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendPushEvent(meetupId: number, pushRequestData: PushRequestData, options: any = {}): RequestArgs {
            // verify required parameter 'meetupId' is not null or undefined
            if (meetupId === null || meetupId === undefined) {
                throw new RequiredError('meetupId','Required parameter meetupId was null or undefined when calling sendPushEvent.');
            }
            // verify required parameter 'pushRequestData' is not null or undefined
            if (pushRequestData === null || pushRequestData === undefined) {
                throw new RequiredError('pushRequestData','Required parameter pushRequestData was null or undefined when calling sendPushEvent.');
            }
            const localVarPath = `/api/meetup/{meetupId}/push`
                .replace(`{${"meetupId"}}`, encodeURIComponent(String(meetupId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"PushRequestData" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(pushRequestData !== undefined ? pushRequestData : {}) : (pushRequestData || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BotApi - functional programming interface
 * @export
 */
export const BotApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllActiveBots(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ActiveBotData>> {
            const localVarAxiosArgs = BotApiAxiosParamCreator(configuration).getAllActiveBots(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} botToken Bot channel token.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBotConfig(botToken: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<BotConfigData> {
            const localVarAxiosArgs = BotApiAxiosParamCreator(configuration).getBotConfig(botToken, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {PushRequestData} pushRequestData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendPushEvent(meetupId: number, pushRequestData: PushRequestData, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PushResponseData> {
            const localVarAxiosArgs = BotApiAxiosParamCreator(configuration).sendPushEvent(meetupId, pushRequestData, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * BotApi - factory interface
 * @export
 */
export const BotApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllActiveBots(options?: any) {
            return BotApiFp(configuration).getAllActiveBots(options)(axios, basePath);
        },
        /**
         * 
         * @param {string} botToken Bot channel token.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBotConfig(botToken: string, options?: any) {
            return BotApiFp(configuration).getBotConfig(botToken, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {PushRequestData} pushRequestData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendPushEvent(meetupId: number, pushRequestData: PushRequestData, options?: any) {
            return BotApiFp(configuration).sendPushEvent(meetupId, pushRequestData, options)(axios, basePath);
        },
    };
};

/**
 * BotApi - object-oriented interface
 * @export
 * @class BotApi
 * @extends {BaseAPI}
 */
export class BotApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BotApi
     */
    public getAllActiveBots(options?: any) {
        return BotApiFp(this.configuration).getAllActiveBots(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} botToken Bot channel token.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BotApi
     */
    public getBotConfig(botToken: string, options?: any) {
        return BotApiFp(this.configuration).getBotConfig(botToken, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} meetupId Meetup identifier.
     * @param {PushRequestData} pushRequestData 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BotApi
     */
    public sendPushEvent(meetupId: number, pushRequestData: PushRequestData, options?: any) {
        return BotApiFp(this.configuration).sendPushEvent(meetupId, pushRequestData, options)(this.axios, this.basePath);
    }

}


/**
 * ChatConfigApi - axios parameter creator
 * @export
 */
export const ChatConfigApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {ChatConfigData} chatConfigData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createChatConfig(meetupId: number, chatConfigData: ChatConfigData, options: any = {}): RequestArgs {
            // verify required parameter 'meetupId' is not null or undefined
            if (meetupId === null || meetupId === undefined) {
                throw new RequiredError('meetupId','Required parameter meetupId was null or undefined when calling createChatConfig.');
            }
            // verify required parameter 'chatConfigData' is not null or undefined
            if (chatConfigData === null || chatConfigData === undefined) {
                throw new RequiredError('chatConfigData','Required parameter chatConfigData was null or undefined when calling createChatConfig.');
            }
            const localVarPath = `/api/meetup/{meetupId}/chat-config`
                .replace(`{${"meetupId"}}`, encodeURIComponent(String(meetupId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"ChatConfigData" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(chatConfigData !== undefined ? chatConfigData : {}) : (chatConfigData || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {number} chatConfigId Chat config identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteChatConfig(meetupId: number, chatConfigId: number, options: any = {}): RequestArgs {
            // verify required parameter 'meetupId' is not null or undefined
            if (meetupId === null || meetupId === undefined) {
                throw new RequiredError('meetupId','Required parameter meetupId was null or undefined when calling deleteChatConfig.');
            }
            // verify required parameter 'chatConfigId' is not null or undefined
            if (chatConfigId === null || chatConfigId === undefined) {
                throw new RequiredError('chatConfigId','Required parameter chatConfigId was null or undefined when calling deleteChatConfig.');
            }
            const localVarPath = `/api/meetup/{meetupId}/chat-config/{chatConfigId}`
                .replace(`{${"meetupId"}}`, encodeURIComponent(String(meetupId)))
                .replace(`{${"chatConfigId"}}`, encodeURIComponent(String(chatConfigId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {number} chatConfigId Chat config identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChatConfig(meetupId: number, chatConfigId: number, options: any = {}): RequestArgs {
            // verify required parameter 'meetupId' is not null or undefined
            if (meetupId === null || meetupId === undefined) {
                throw new RequiredError('meetupId','Required parameter meetupId was null or undefined when calling getChatConfig.');
            }
            // verify required parameter 'chatConfigId' is not null or undefined
            if (chatConfigId === null || chatConfigId === undefined) {
                throw new RequiredError('chatConfigId','Required parameter chatConfigId was null or undefined when calling getChatConfig.');
            }
            const localVarPath = `/api/meetup/{meetupId}/chat-config/{chatConfigId}`
                .replace(`{${"meetupId"}}`, encodeURIComponent(String(meetupId)))
                .replace(`{${"chatConfigId"}}`, encodeURIComponent(String(chatConfigId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listChatConfig(meetupId: number, options: any = {}): RequestArgs {
            // verify required parameter 'meetupId' is not null or undefined
            if (meetupId === null || meetupId === undefined) {
                throw new RequiredError('meetupId','Required parameter meetupId was null or undefined when calling listChatConfig.');
            }
            const localVarPath = `/api/meetup/{meetupId}/chat-config`
                .replace(`{${"meetupId"}}`, encodeURIComponent(String(meetupId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {number} chatConfigId Chat config identifier.
         * @param {ChatConfigData} chatConfigData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateChatConfig(meetupId: number, chatConfigId: number, chatConfigData: ChatConfigData, options: any = {}): RequestArgs {
            // verify required parameter 'meetupId' is not null or undefined
            if (meetupId === null || meetupId === undefined) {
                throw new RequiredError('meetupId','Required parameter meetupId was null or undefined when calling updateChatConfig.');
            }
            // verify required parameter 'chatConfigId' is not null or undefined
            if (chatConfigId === null || chatConfigId === undefined) {
                throw new RequiredError('chatConfigId','Required parameter chatConfigId was null or undefined when calling updateChatConfig.');
            }
            // verify required parameter 'chatConfigData' is not null or undefined
            if (chatConfigData === null || chatConfigData === undefined) {
                throw new RequiredError('chatConfigData','Required parameter chatConfigData was null or undefined when calling updateChatConfig.');
            }
            const localVarPath = `/api/meetup/{meetupId}/chat-config/{chatConfigId}`
                .replace(`{${"meetupId"}}`, encodeURIComponent(String(meetupId)))
                .replace(`{${"chatConfigId"}}`, encodeURIComponent(String(chatConfigId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"ChatConfigData" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(chatConfigData !== undefined ? chatConfigData : {}) : (chatConfigData || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ChatConfigApi - functional programming interface
 * @export
 */
export const ChatConfigApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {ChatConfigData} chatConfigData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createChatConfig(meetupId: number, chatConfigData: ChatConfigData, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChatConfigData> {
            const localVarAxiosArgs = ChatConfigApiAxiosParamCreator(configuration).createChatConfig(meetupId, chatConfigData, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {number} chatConfigId Chat config identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteChatConfig(meetupId: number, chatConfigId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = ChatConfigApiAxiosParamCreator(configuration).deleteChatConfig(meetupId, chatConfigId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {number} chatConfigId Chat config identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChatConfig(meetupId: number, chatConfigId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChatConfigData> {
            const localVarAxiosArgs = ChatConfigApiAxiosParamCreator(configuration).getChatConfig(meetupId, chatConfigId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listChatConfig(meetupId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ChatConfigData>> {
            const localVarAxiosArgs = ChatConfigApiAxiosParamCreator(configuration).listChatConfig(meetupId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {number} chatConfigId Chat config identifier.
         * @param {ChatConfigData} chatConfigData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateChatConfig(meetupId: number, chatConfigId: number, chatConfigData: ChatConfigData, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChatConfigData> {
            const localVarAxiosArgs = ChatConfigApiAxiosParamCreator(configuration).updateChatConfig(meetupId, chatConfigId, chatConfigData, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ChatConfigApi - factory interface
 * @export
 */
export const ChatConfigApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {ChatConfigData} chatConfigData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createChatConfig(meetupId: number, chatConfigData: ChatConfigData, options?: any) {
            return ChatConfigApiFp(configuration).createChatConfig(meetupId, chatConfigData, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {number} chatConfigId Chat config identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteChatConfig(meetupId: number, chatConfigId: number, options?: any) {
            return ChatConfigApiFp(configuration).deleteChatConfig(meetupId, chatConfigId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {number} chatConfigId Chat config identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChatConfig(meetupId: number, chatConfigId: number, options?: any) {
            return ChatConfigApiFp(configuration).getChatConfig(meetupId, chatConfigId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listChatConfig(meetupId: number, options?: any) {
            return ChatConfigApiFp(configuration).listChatConfig(meetupId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {number} chatConfigId Chat config identifier.
         * @param {ChatConfigData} chatConfigData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateChatConfig(meetupId: number, chatConfigId: number, chatConfigData: ChatConfigData, options?: any) {
            return ChatConfigApiFp(configuration).updateChatConfig(meetupId, chatConfigId, chatConfigData, options)(axios, basePath);
        },
    };
};

/**
 * ChatConfigApi - object-oriented interface
 * @export
 * @class ChatConfigApi
 * @extends {BaseAPI}
 */
export class ChatConfigApi extends BaseAPI {
    /**
     * 
     * @param {number} meetupId Meetup identifier.
     * @param {ChatConfigData} chatConfigData 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatConfigApi
     */
    public createChatConfig(meetupId: number, chatConfigData: ChatConfigData, options?: any) {
        return ChatConfigApiFp(this.configuration).createChatConfig(meetupId, chatConfigData, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} meetupId Meetup identifier.
     * @param {number} chatConfigId Chat config identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatConfigApi
     */
    public deleteChatConfig(meetupId: number, chatConfigId: number, options?: any) {
        return ChatConfigApiFp(this.configuration).deleteChatConfig(meetupId, chatConfigId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} meetupId Meetup identifier.
     * @param {number} chatConfigId Chat config identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatConfigApi
     */
    public getChatConfig(meetupId: number, chatConfigId: number, options?: any) {
        return ChatConfigApiFp(this.configuration).getChatConfig(meetupId, chatConfigId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} meetupId Meetup identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatConfigApi
     */
    public listChatConfig(meetupId: number, options?: any) {
        return ChatConfigApiFp(this.configuration).listChatConfig(meetupId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} meetupId Meetup identifier.
     * @param {number} chatConfigId Chat config identifier.
     * @param {ChatConfigData} chatConfigData 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatConfigApi
     */
    public updateChatConfig(meetupId: number, chatConfigId: number, chatConfigData: ChatConfigData, options?: any) {
        return ChatConfigApiFp(this.configuration).updateChatConfig(meetupId, chatConfigId, chatConfigData, options)(this.axios, this.basePath);
    }

}


/**
 * ChatLogsApi - axios parameter creator
 * @export
 */
export const ChatLogsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {number} [ccid] Chat config identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClients(meetupId: number, ccid?: number, options: any = {}): RequestArgs {
            // verify required parameter 'meetupId' is not null or undefined
            if (meetupId === null || meetupId === undefined) {
                throw new RequiredError('meetupId','Required parameter meetupId was null or undefined when calling getClients.');
            }
            const localVarPath = `/api/meetup/{meetupId}/chat-history/client`
                .replace(`{${"meetupId"}}`, encodeURIComponent(String(meetupId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (ccid !== undefined) {
                localVarQueryParameter['ccid'] = ccid;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {number} chatConfigId Chat config identifier.
         * @param {string} chatClientId Jaicp client identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listClientLogs(meetupId: number, chatConfigId: number, chatClientId: string, options: any = {}): RequestArgs {
            // verify required parameter 'meetupId' is not null or undefined
            if (meetupId === null || meetupId === undefined) {
                throw new RequiredError('meetupId','Required parameter meetupId was null or undefined when calling listClientLogs.');
            }
            // verify required parameter 'chatConfigId' is not null or undefined
            if (chatConfigId === null || chatConfigId === undefined) {
                throw new RequiredError('chatConfigId','Required parameter chatConfigId was null or undefined when calling listClientLogs.');
            }
            // verify required parameter 'chatClientId' is not null or undefined
            if (chatClientId === null || chatClientId === undefined) {
                throw new RequiredError('chatClientId','Required parameter chatClientId was null or undefined when calling listClientLogs.');
            }
            const localVarPath = `/api/meetup/{meetupId}/chat-history/client/{chatConfigId}/{chatClientId}`
                .replace(`{${"meetupId"}}`, encodeURIComponent(String(meetupId)))
                .replace(`{${"chatConfigId"}}`, encodeURIComponent(String(chatConfigId)))
                .replace(`{${"chatClientId"}}`, encodeURIComponent(String(chatClientId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ChatLogsApi - functional programming interface
 * @export
 */
export const ChatLogsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {number} [ccid] Chat config identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClients(meetupId: number, ccid?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ChatClientData>> {
            const localVarAxiosArgs = ChatLogsApiAxiosParamCreator(configuration).getClients(meetupId, ccid, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {number} chatConfigId Chat config identifier.
         * @param {string} chatClientId Jaicp client identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listClientLogs(meetupId: number, chatConfigId: number, chatClientId: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChatMessagesData> {
            const localVarAxiosArgs = ChatLogsApiAxiosParamCreator(configuration).listClientLogs(meetupId, chatConfigId, chatClientId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ChatLogsApi - factory interface
 * @export
 */
export const ChatLogsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {number} [ccid] Chat config identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClients(meetupId: number, ccid?: number, options?: any) {
            return ChatLogsApiFp(configuration).getClients(meetupId, ccid, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {number} chatConfigId Chat config identifier.
         * @param {string} chatClientId Jaicp client identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listClientLogs(meetupId: number, chatConfigId: number, chatClientId: string, options?: any) {
            return ChatLogsApiFp(configuration).listClientLogs(meetupId, chatConfigId, chatClientId, options)(axios, basePath);
        },
    };
};

/**
 * ChatLogsApi - object-oriented interface
 * @export
 * @class ChatLogsApi
 * @extends {BaseAPI}
 */
export class ChatLogsApi extends BaseAPI {
    /**
     * 
     * @param {number} meetupId Meetup identifier.
     * @param {number} [ccid] Chat config identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatLogsApi
     */
    public getClients(meetupId: number, ccid?: number, options?: any) {
        return ChatLogsApiFp(this.configuration).getClients(meetupId, ccid, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} meetupId Meetup identifier.
     * @param {number} chatConfigId Chat config identifier.
     * @param {string} chatClientId Jaicp client identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatLogsApi
     */
    public listClientLogs(meetupId: number, chatConfigId: number, chatClientId: string, options?: any) {
        return ChatLogsApiFp(this.configuration).listClientLogs(meetupId, chatConfigId, chatClientId, options)(this.axios, this.basePath);
    }

}


/**
 * ClockApi - axios parameter creator
 * @export
 */
export const ClockApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get(options: any = {}): RequestArgs {
            const localVarPath = `/api-test/clock`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Set clock offset
         * @param {TestClockOffsetRequestData} testClockOffsetRequestData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offset(testClockOffsetRequestData: TestClockOffsetRequestData, options: any = {}): RequestArgs {
            // verify required parameter 'testClockOffsetRequestData' is not null or undefined
            if (testClockOffsetRequestData === null || testClockOffsetRequestData === undefined) {
                throw new RequiredError('testClockOffsetRequestData','Required parameter testClockOffsetRequestData was null or undefined when calling offset.');
            }
            const localVarPath = `/api-test/clock/offset`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"TestClockOffsetRequestData" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(testClockOffsetRequestData !== undefined ? testClockOffsetRequestData : {}) : (testClockOffsetRequestData || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Set clock offset
         * @param {TestClockOffsetDurationRequestData} testClockOffsetDurationRequestData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offsetDuration(testClockOffsetDurationRequestData: TestClockOffsetDurationRequestData, options: any = {}): RequestArgs {
            // verify required parameter 'testClockOffsetDurationRequestData' is not null or undefined
            if (testClockOffsetDurationRequestData === null || testClockOffsetDurationRequestData === undefined) {
                throw new RequiredError('testClockOffsetDurationRequestData','Required parameter testClockOffsetDurationRequestData was null or undefined when calling offsetDuration.');
            }
            const localVarPath = `/api-test/clock/offsetDuration`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"TestClockOffsetDurationRequestData" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(testClockOffsetDurationRequestData !== undefined ? testClockOffsetDurationRequestData : {}) : (testClockOffsetDurationRequestData || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Plus clock offset
         * @param {TestClockPlusRequestData} testClockPlusRequestData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        plus(testClockPlusRequestData: TestClockPlusRequestData, options: any = {}): RequestArgs {
            // verify required parameter 'testClockPlusRequestData' is not null or undefined
            if (testClockPlusRequestData === null || testClockPlusRequestData === undefined) {
                throw new RequiredError('testClockPlusRequestData','Required parameter testClockPlusRequestData was null or undefined when calling plus.');
            }
            const localVarPath = `/api-test/clock/plus`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"TestClockPlusRequestData" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(testClockPlusRequestData !== undefined ? testClockPlusRequestData : {}) : (testClockPlusRequestData || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Plus clock offset
         * @param {TestClockPlusDurationRequestData} testClockPlusDurationRequestData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        plusDuration(testClockPlusDurationRequestData: TestClockPlusDurationRequestData, options: any = {}): RequestArgs {
            // verify required parameter 'testClockPlusDurationRequestData' is not null or undefined
            if (testClockPlusDurationRequestData === null || testClockPlusDurationRequestData === undefined) {
                throw new RequiredError('testClockPlusDurationRequestData','Required parameter testClockPlusDurationRequestData was null or undefined when calling plusDuration.');
            }
            const localVarPath = `/api-test/clock/plusDuration`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"TestClockPlusDurationRequestData" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(testClockPlusDurationRequestData !== undefined ? testClockPlusDurationRequestData : {}) : (testClockPlusDurationRequestData || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Set clock
         * @param {TestClockRequestData} testClockRequestData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        set(testClockRequestData: TestClockRequestData, options: any = {}): RequestArgs {
            // verify required parameter 'testClockRequestData' is not null or undefined
            if (testClockRequestData === null || testClockRequestData === undefined) {
                throw new RequiredError('testClockRequestData','Required parameter testClockRequestData was null or undefined when calling set.');
            }
            const localVarPath = `/api-test/clock`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"TestClockRequestData" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(testClockRequestData !== undefined ? testClockRequestData : {}) : (testClockRequestData || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ClockApi - functional programming interface
 * @export
 */
export const ClockApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<TestClockData> {
            const localVarAxiosArgs = ClockApiAxiosParamCreator(configuration).get(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Set clock offset
         * @param {TestClockOffsetRequestData} testClockOffsetRequestData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offset(testClockOffsetRequestData: TestClockOffsetRequestData, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<TestClockData> {
            const localVarAxiosArgs = ClockApiAxiosParamCreator(configuration).offset(testClockOffsetRequestData, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Set clock offset
         * @param {TestClockOffsetDurationRequestData} testClockOffsetDurationRequestData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offsetDuration(testClockOffsetDurationRequestData: TestClockOffsetDurationRequestData, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<TestClockData> {
            const localVarAxiosArgs = ClockApiAxiosParamCreator(configuration).offsetDuration(testClockOffsetDurationRequestData, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Plus clock offset
         * @param {TestClockPlusRequestData} testClockPlusRequestData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        plus(testClockPlusRequestData: TestClockPlusRequestData, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<TestClockData> {
            const localVarAxiosArgs = ClockApiAxiosParamCreator(configuration).plus(testClockPlusRequestData, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Plus clock offset
         * @param {TestClockPlusDurationRequestData} testClockPlusDurationRequestData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        plusDuration(testClockPlusDurationRequestData: TestClockPlusDurationRequestData, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<TestClockData> {
            const localVarAxiosArgs = ClockApiAxiosParamCreator(configuration).plusDuration(testClockPlusDurationRequestData, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Set clock
         * @param {TestClockRequestData} testClockRequestData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        set(testClockRequestData: TestClockRequestData, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<TestClockData> {
            const localVarAxiosArgs = ClockApiAxiosParamCreator(configuration).set(testClockRequestData, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ClockApi - factory interface
 * @export
 */
export const ClockApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get(options?: any) {
            return ClockApiFp(configuration).get(options)(axios, basePath);
        },
        /**
         * 
         * @summary Set clock offset
         * @param {TestClockOffsetRequestData} testClockOffsetRequestData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offset(testClockOffsetRequestData: TestClockOffsetRequestData, options?: any) {
            return ClockApiFp(configuration).offset(testClockOffsetRequestData, options)(axios, basePath);
        },
        /**
         * 
         * @summary Set clock offset
         * @param {TestClockOffsetDurationRequestData} testClockOffsetDurationRequestData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offsetDuration(testClockOffsetDurationRequestData: TestClockOffsetDurationRequestData, options?: any) {
            return ClockApiFp(configuration).offsetDuration(testClockOffsetDurationRequestData, options)(axios, basePath);
        },
        /**
         * 
         * @summary Plus clock offset
         * @param {TestClockPlusRequestData} testClockPlusRequestData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        plus(testClockPlusRequestData: TestClockPlusRequestData, options?: any) {
            return ClockApiFp(configuration).plus(testClockPlusRequestData, options)(axios, basePath);
        },
        /**
         * 
         * @summary Plus clock offset
         * @param {TestClockPlusDurationRequestData} testClockPlusDurationRequestData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        plusDuration(testClockPlusDurationRequestData: TestClockPlusDurationRequestData, options?: any) {
            return ClockApiFp(configuration).plusDuration(testClockPlusDurationRequestData, options)(axios, basePath);
        },
        /**
         * 
         * @summary Set clock
         * @param {TestClockRequestData} testClockRequestData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        set(testClockRequestData: TestClockRequestData, options?: any) {
            return ClockApiFp(configuration).set(testClockRequestData, options)(axios, basePath);
        },
    };
};

/**
 * ClockApi - object-oriented interface
 * @export
 * @class ClockApi
 * @extends {BaseAPI}
 */
export class ClockApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClockApi
     */
    public get(options?: any) {
        return ClockApiFp(this.configuration).get(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Set clock offset
     * @param {TestClockOffsetRequestData} testClockOffsetRequestData 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClockApi
     */
    public offset(testClockOffsetRequestData: TestClockOffsetRequestData, options?: any) {
        return ClockApiFp(this.configuration).offset(testClockOffsetRequestData, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Set clock offset
     * @param {TestClockOffsetDurationRequestData} testClockOffsetDurationRequestData 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClockApi
     */
    public offsetDuration(testClockOffsetDurationRequestData: TestClockOffsetDurationRequestData, options?: any) {
        return ClockApiFp(this.configuration).offsetDuration(testClockOffsetDurationRequestData, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Plus clock offset
     * @param {TestClockPlusRequestData} testClockPlusRequestData 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClockApi
     */
    public plus(testClockPlusRequestData: TestClockPlusRequestData, options?: any) {
        return ClockApiFp(this.configuration).plus(testClockPlusRequestData, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Plus clock offset
     * @param {TestClockPlusDurationRequestData} testClockPlusDurationRequestData 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClockApi
     */
    public plusDuration(testClockPlusDurationRequestData: TestClockPlusDurationRequestData, options?: any) {
        return ClockApiFp(this.configuration).plusDuration(testClockPlusDurationRequestData, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Set clock
     * @param {TestClockRequestData} testClockRequestData 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClockApi
     */
    public set(testClockRequestData: TestClockRequestData, options?: any) {
        return ClockApiFp(this.configuration).set(testClockRequestData, options)(this.axios, this.basePath);
    }

}


/**
 * ConfigApi - axios parameter creator
 * @export
 */
export const ConfigApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getServicePlanConfig(options: any = {}): RequestArgs {
            const localVarPath = `/api/service-plans`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSettings(meetupId: number, options: any = {}): RequestArgs {
            // verify required parameter 'meetupId' is not null or undefined
            if (meetupId === null || meetupId === undefined) {
                throw new RequiredError('meetupId','Required parameter meetupId was null or undefined when calling getSettings.');
            }
            const localVarPath = `/api/meetup/{meetupId}/settings`
                .replace(`{${"meetupId"}}`, encodeURIComponent(String(meetupId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {MailTemplateData} mailTemplateData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendTestEmail(meetupId: number, mailTemplateData: MailTemplateData, options: any = {}): RequestArgs {
            // verify required parameter 'meetupId' is not null or undefined
            if (meetupId === null || meetupId === undefined) {
                throw new RequiredError('meetupId','Required parameter meetupId was null or undefined when calling sendTestEmail.');
            }
            // verify required parameter 'mailTemplateData' is not null or undefined
            if (mailTemplateData === null || mailTemplateData === undefined) {
                throw new RequiredError('mailTemplateData','Required parameter mailTemplateData was null or undefined when calling sendTestEmail.');
            }
            const localVarPath = `/api/meetup/{meetupId}/send-test-email`
                .replace(`{${"meetupId"}}`, encodeURIComponent(String(meetupId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"MailTemplateData" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(mailTemplateData !== undefined ? mailTemplateData : {}) : (mailTemplateData || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {FieldSettingsData} fieldSettingsData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setFieldSettings(meetupId: number, fieldSettingsData: FieldSettingsData, options: any = {}): RequestArgs {
            // verify required parameter 'meetupId' is not null or undefined
            if (meetupId === null || meetupId === undefined) {
                throw new RequiredError('meetupId','Required parameter meetupId was null or undefined when calling setFieldSettings.');
            }
            // verify required parameter 'fieldSettingsData' is not null or undefined
            if (fieldSettingsData === null || fieldSettingsData === undefined) {
                throw new RequiredError('fieldSettingsData','Required parameter fieldSettingsData was null or undefined when calling setFieldSettings.');
            }
            const localVarPath = `/api/meetup/{meetupId}/field-settings`
                .replace(`{${"meetupId"}}`, encodeURIComponent(String(meetupId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"FieldSettingsData" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(fieldSettingsData !== undefined ? fieldSettingsData : {}) : (fieldSettingsData || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {RegFormSettingsData} regFormSettingsData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setRegFormSettings(meetupId: number, regFormSettingsData: RegFormSettingsData, options: any = {}): RequestArgs {
            // verify required parameter 'meetupId' is not null or undefined
            if (meetupId === null || meetupId === undefined) {
                throw new RequiredError('meetupId','Required parameter meetupId was null or undefined when calling setRegFormSettings.');
            }
            // verify required parameter 'regFormSettingsData' is not null or undefined
            if (regFormSettingsData === null || regFormSettingsData === undefined) {
                throw new RequiredError('regFormSettingsData','Required parameter regFormSettingsData was null or undefined when calling setRegFormSettings.');
            }
            const localVarPath = `/api/meetup/{meetupId}/regform-settings`
                .replace(`{${"meetupId"}}`, encodeURIComponent(String(meetupId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"RegFormSettingsData" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(regFormSettingsData !== undefined ? regFormSettingsData : {}) : (regFormSettingsData || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ConfigApi - functional programming interface
 * @export
 */
export const ConfigApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getServicePlanConfig(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ServicePlansConfigData> {
            const localVarAxiosArgs = ConfigApiAxiosParamCreator(configuration).getServicePlanConfig(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSettings(meetupId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SettingsData> {
            const localVarAxiosArgs = ConfigApiAxiosParamCreator(configuration).getSettings(meetupId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {MailTemplateData} mailTemplateData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendTestEmail(meetupId: number, mailTemplateData: MailTemplateData, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<TestEmailData> {
            const localVarAxiosArgs = ConfigApiAxiosParamCreator(configuration).sendTestEmail(meetupId, mailTemplateData, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {FieldSettingsData} fieldSettingsData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setFieldSettings(meetupId: number, fieldSettingsData: FieldSettingsData, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<FieldSettingsData> {
            const localVarAxiosArgs = ConfigApiAxiosParamCreator(configuration).setFieldSettings(meetupId, fieldSettingsData, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {RegFormSettingsData} regFormSettingsData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setRegFormSettings(meetupId: number, regFormSettingsData: RegFormSettingsData, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<RegFormSettingsData> {
            const localVarAxiosArgs = ConfigApiAxiosParamCreator(configuration).setRegFormSettings(meetupId, regFormSettingsData, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ConfigApi - factory interface
 * @export
 */
export const ConfigApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getServicePlanConfig(options?: any) {
            return ConfigApiFp(configuration).getServicePlanConfig(options)(axios, basePath);
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSettings(meetupId: number, options?: any) {
            return ConfigApiFp(configuration).getSettings(meetupId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {MailTemplateData} mailTemplateData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendTestEmail(meetupId: number, mailTemplateData: MailTemplateData, options?: any) {
            return ConfigApiFp(configuration).sendTestEmail(meetupId, mailTemplateData, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {FieldSettingsData} fieldSettingsData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setFieldSettings(meetupId: number, fieldSettingsData: FieldSettingsData, options?: any) {
            return ConfigApiFp(configuration).setFieldSettings(meetupId, fieldSettingsData, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {RegFormSettingsData} regFormSettingsData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setRegFormSettings(meetupId: number, regFormSettingsData: RegFormSettingsData, options?: any) {
            return ConfigApiFp(configuration).setRegFormSettings(meetupId, regFormSettingsData, options)(axios, basePath);
        },
    };
};

/**
 * ConfigApi - object-oriented interface
 * @export
 * @class ConfigApi
 * @extends {BaseAPI}
 */
export class ConfigApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigApi
     */
    public getServicePlanConfig(options?: any) {
        return ConfigApiFp(this.configuration).getServicePlanConfig(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} meetupId Meetup identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigApi
     */
    public getSettings(meetupId: number, options?: any) {
        return ConfigApiFp(this.configuration).getSettings(meetupId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} meetupId Meetup identifier.
     * @param {MailTemplateData} mailTemplateData 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigApi
     */
    public sendTestEmail(meetupId: number, mailTemplateData: MailTemplateData, options?: any) {
        return ConfigApiFp(this.configuration).sendTestEmail(meetupId, mailTemplateData, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} meetupId Meetup identifier.
     * @param {FieldSettingsData} fieldSettingsData 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigApi
     */
    public setFieldSettings(meetupId: number, fieldSettingsData: FieldSettingsData, options?: any) {
        return ConfigApiFp(this.configuration).setFieldSettings(meetupId, fieldSettingsData, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} meetupId Meetup identifier.
     * @param {RegFormSettingsData} regFormSettingsData 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigApi
     */
    public setRegFormSettings(meetupId: number, regFormSettingsData: RegFormSettingsData, options?: any) {
        return ConfigApiFp(this.configuration).setRegFormSettings(meetupId, regFormSettingsData, options)(this.axios, this.basePath);
    }

}


/**
 * EventInfoApi - axios parameter creator
 * @export
 */
export const EventInfoApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContacts(meetupId: number, options: any = {}): RequestArgs {
            // verify required parameter 'meetupId' is not null or undefined
            if (meetupId === null || meetupId === undefined) {
                throw new RequiredError('meetupId','Required parameter meetupId was null or undefined when calling getContacts.');
            }
            const localVarPath = `/api/meetup/{meetupId}/contacts`
                .replace(`{${"meetupId"}}`, encodeURIComponent(String(meetupId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLocation(meetupId: number, options: any = {}): RequestArgs {
            // verify required parameter 'meetupId' is not null or undefined
            if (meetupId === null || meetupId === undefined) {
                throw new RequiredError('meetupId','Required parameter meetupId was null or undefined when calling getLocation.');
            }
            const localVarPath = `/api/meetup/{meetupId}/location`
                .replace(`{${"meetupId"}}`, encodeURIComponent(String(meetupId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {ContactsData} contactsData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateContacts(meetupId: number, contactsData: ContactsData, options: any = {}): RequestArgs {
            // verify required parameter 'meetupId' is not null or undefined
            if (meetupId === null || meetupId === undefined) {
                throw new RequiredError('meetupId','Required parameter meetupId was null or undefined when calling updateContacts.');
            }
            // verify required parameter 'contactsData' is not null or undefined
            if (contactsData === null || contactsData === undefined) {
                throw new RequiredError('contactsData','Required parameter contactsData was null or undefined when calling updateContacts.');
            }
            const localVarPath = `/api/meetup/{meetupId}/contacts`
                .replace(`{${"meetupId"}}`, encodeURIComponent(String(meetupId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"ContactsData" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(contactsData !== undefined ? contactsData : {}) : (contactsData || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {LocationData} locationData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLocation(meetupId: number, locationData: LocationData, options: any = {}): RequestArgs {
            // verify required parameter 'meetupId' is not null or undefined
            if (meetupId === null || meetupId === undefined) {
                throw new RequiredError('meetupId','Required parameter meetupId was null or undefined when calling updateLocation.');
            }
            // verify required parameter 'locationData' is not null or undefined
            if (locationData === null || locationData === undefined) {
                throw new RequiredError('locationData','Required parameter locationData was null or undefined when calling updateLocation.');
            }
            const localVarPath = `/api/meetup/{meetupId}/location`
                .replace(`{${"meetupId"}}`, encodeURIComponent(String(meetupId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"LocationData" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(locationData !== undefined ? locationData : {}) : (locationData || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EventInfoApi - functional programming interface
 * @export
 */
export const EventInfoApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContacts(meetupId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContactsData> {
            const localVarAxiosArgs = EventInfoApiAxiosParamCreator(configuration).getContacts(meetupId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLocation(meetupId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<LocationData> {
            const localVarAxiosArgs = EventInfoApiAxiosParamCreator(configuration).getLocation(meetupId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {ContactsData} contactsData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateContacts(meetupId: number, contactsData: ContactsData, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContactsData> {
            const localVarAxiosArgs = EventInfoApiAxiosParamCreator(configuration).updateContacts(meetupId, contactsData, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {LocationData} locationData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLocation(meetupId: number, locationData: LocationData, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<LocationData> {
            const localVarAxiosArgs = EventInfoApiAxiosParamCreator(configuration).updateLocation(meetupId, locationData, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * EventInfoApi - factory interface
 * @export
 */
export const EventInfoApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContacts(meetupId: number, options?: any) {
            return EventInfoApiFp(configuration).getContacts(meetupId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLocation(meetupId: number, options?: any) {
            return EventInfoApiFp(configuration).getLocation(meetupId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {ContactsData} contactsData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateContacts(meetupId: number, contactsData: ContactsData, options?: any) {
            return EventInfoApiFp(configuration).updateContacts(meetupId, contactsData, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {LocationData} locationData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLocation(meetupId: number, locationData: LocationData, options?: any) {
            return EventInfoApiFp(configuration).updateLocation(meetupId, locationData, options)(axios, basePath);
        },
    };
};

/**
 * EventInfoApi - object-oriented interface
 * @export
 * @class EventInfoApi
 * @extends {BaseAPI}
 */
export class EventInfoApi extends BaseAPI {
    /**
     * 
     * @param {number} meetupId Meetup identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventInfoApi
     */
    public getContacts(meetupId: number, options?: any) {
        return EventInfoApiFp(this.configuration).getContacts(meetupId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} meetupId Meetup identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventInfoApi
     */
    public getLocation(meetupId: number, options?: any) {
        return EventInfoApiFp(this.configuration).getLocation(meetupId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} meetupId Meetup identifier.
     * @param {ContactsData} contactsData 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventInfoApi
     */
    public updateContacts(meetupId: number, contactsData: ContactsData, options?: any) {
        return EventInfoApiFp(this.configuration).updateContacts(meetupId, contactsData, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} meetupId Meetup identifier.
     * @param {LocationData} locationData 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventInfoApi
     */
    public updateLocation(meetupId: number, locationData: LocationData, options?: any) {
        return EventInfoApiFp(this.configuration).updateLocation(meetupId, locationData, options)(this.axios, this.basePath);
    }

}


/**
 * GroupApi - axios parameter creator
 * @export
 */
export const GroupApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {GroupData} groupData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createGroup(meetupId: number, groupData: GroupData, options: any = {}): RequestArgs {
            // verify required parameter 'meetupId' is not null or undefined
            if (meetupId === null || meetupId === undefined) {
                throw new RequiredError('meetupId','Required parameter meetupId was null or undefined when calling createGroup.');
            }
            // verify required parameter 'groupData' is not null or undefined
            if (groupData === null || groupData === undefined) {
                throw new RequiredError('groupData','Required parameter groupData was null or undefined when calling createGroup.');
            }
            const localVarPath = `/api/meetup/{meetupId}/group`
                .replace(`{${"meetupId"}}`, encodeURIComponent(String(meetupId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"GroupData" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(groupData !== undefined ? groupData : {}) : (groupData || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {number} groupId Group identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteGroup(meetupId: number, groupId: number, options: any = {}): RequestArgs {
            // verify required parameter 'meetupId' is not null or undefined
            if (meetupId === null || meetupId === undefined) {
                throw new RequiredError('meetupId','Required parameter meetupId was null or undefined when calling deleteGroup.');
            }
            // verify required parameter 'groupId' is not null or undefined
            if (groupId === null || groupId === undefined) {
                throw new RequiredError('groupId','Required parameter groupId was null or undefined when calling deleteGroup.');
            }
            const localVarPath = `/api/meetup/{meetupId}/group/{groupId}`
                .replace(`{${"meetupId"}}`, encodeURIComponent(String(meetupId)))
                .replace(`{${"groupId"}}`, encodeURIComponent(String(groupId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {number} groupId Group identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGroup(meetupId: number, groupId: number, options: any = {}): RequestArgs {
            // verify required parameter 'meetupId' is not null or undefined
            if (meetupId === null || meetupId === undefined) {
                throw new RequiredError('meetupId','Required parameter meetupId was null or undefined when calling getGroup.');
            }
            // verify required parameter 'groupId' is not null or undefined
            if (groupId === null || groupId === undefined) {
                throw new RequiredError('groupId','Required parameter groupId was null or undefined when calling getGroup.');
            }
            const localVarPath = `/api/meetup/{meetupId}/group/{groupId}`
                .replace(`{${"meetupId"}}`, encodeURIComponent(String(meetupId)))
                .replace(`{${"groupId"}}`, encodeURIComponent(String(groupId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listGroups(meetupId: number, options: any = {}): RequestArgs {
            // verify required parameter 'meetupId' is not null or undefined
            if (meetupId === null || meetupId === undefined) {
                throw new RequiredError('meetupId','Required parameter meetupId was null or undefined when calling listGroups.');
            }
            const localVarPath = `/api/meetup/{meetupId}/group`
                .replace(`{${"meetupId"}}`, encodeURIComponent(String(meetupId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {number} groupId Group identifier.
         * @param {GroupData} groupData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGroup(meetupId: number, groupId: number, groupData: GroupData, options: any = {}): RequestArgs {
            // verify required parameter 'meetupId' is not null or undefined
            if (meetupId === null || meetupId === undefined) {
                throw new RequiredError('meetupId','Required parameter meetupId was null or undefined when calling updateGroup.');
            }
            // verify required parameter 'groupId' is not null or undefined
            if (groupId === null || groupId === undefined) {
                throw new RequiredError('groupId','Required parameter groupId was null or undefined when calling updateGroup.');
            }
            // verify required parameter 'groupData' is not null or undefined
            if (groupData === null || groupData === undefined) {
                throw new RequiredError('groupData','Required parameter groupData was null or undefined when calling updateGroup.');
            }
            const localVarPath = `/api/meetup/{meetupId}/group/{groupId}`
                .replace(`{${"meetupId"}}`, encodeURIComponent(String(meetupId)))
                .replace(`{${"groupId"}}`, encodeURIComponent(String(groupId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"GroupData" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(groupData !== undefined ? groupData : {}) : (groupData || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GroupApi - functional programming interface
 * @export
 */
export const GroupApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {GroupData} groupData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createGroup(meetupId: number, groupData: GroupData, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GroupData> {
            const localVarAxiosArgs = GroupApiAxiosParamCreator(configuration).createGroup(meetupId, groupData, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {number} groupId Group identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteGroup(meetupId: number, groupId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = GroupApiAxiosParamCreator(configuration).deleteGroup(meetupId, groupId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {number} groupId Group identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGroup(meetupId: number, groupId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GroupData> {
            const localVarAxiosArgs = GroupApiAxiosParamCreator(configuration).getGroup(meetupId, groupId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listGroups(meetupId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GroupData>> {
            const localVarAxiosArgs = GroupApiAxiosParamCreator(configuration).listGroups(meetupId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {number} groupId Group identifier.
         * @param {GroupData} groupData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGroup(meetupId: number, groupId: number, groupData: GroupData, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GroupData> {
            const localVarAxiosArgs = GroupApiAxiosParamCreator(configuration).updateGroup(meetupId, groupId, groupData, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * GroupApi - factory interface
 * @export
 */
export const GroupApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {GroupData} groupData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createGroup(meetupId: number, groupData: GroupData, options?: any) {
            return GroupApiFp(configuration).createGroup(meetupId, groupData, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {number} groupId Group identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteGroup(meetupId: number, groupId: number, options?: any) {
            return GroupApiFp(configuration).deleteGroup(meetupId, groupId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {number} groupId Group identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGroup(meetupId: number, groupId: number, options?: any) {
            return GroupApiFp(configuration).getGroup(meetupId, groupId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listGroups(meetupId: number, options?: any) {
            return GroupApiFp(configuration).listGroups(meetupId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {number} groupId Group identifier.
         * @param {GroupData} groupData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGroup(meetupId: number, groupId: number, groupData: GroupData, options?: any) {
            return GroupApiFp(configuration).updateGroup(meetupId, groupId, groupData, options)(axios, basePath);
        },
    };
};

/**
 * GroupApi - object-oriented interface
 * @export
 * @class GroupApi
 * @extends {BaseAPI}
 */
export class GroupApi extends BaseAPI {
    /**
     * 
     * @param {number} meetupId Meetup identifier.
     * @param {GroupData} groupData 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupApi
     */
    public createGroup(meetupId: number, groupData: GroupData, options?: any) {
        return GroupApiFp(this.configuration).createGroup(meetupId, groupData, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} meetupId Meetup identifier.
     * @param {number} groupId Group identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupApi
     */
    public deleteGroup(meetupId: number, groupId: number, options?: any) {
        return GroupApiFp(this.configuration).deleteGroup(meetupId, groupId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} meetupId Meetup identifier.
     * @param {number} groupId Group identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupApi
     */
    public getGroup(meetupId: number, groupId: number, options?: any) {
        return GroupApiFp(this.configuration).getGroup(meetupId, groupId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} meetupId Meetup identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupApi
     */
    public listGroups(meetupId: number, options?: any) {
        return GroupApiFp(this.configuration).listGroups(meetupId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} meetupId Meetup identifier.
     * @param {number} groupId Group identifier.
     * @param {GroupData} groupData 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupApi
     */
    public updateGroup(meetupId: number, groupId: number, groupData: GroupData, options?: any) {
        return GroupApiFp(this.configuration).updateGroup(meetupId, groupId, groupData, options)(this.axios, this.basePath);
    }

}


/**
 * HallApi - axios parameter creator
 * @export
 */
export const HallApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {HallData} hallData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createHall(meetupId: number, hallData: HallData, options: any = {}): RequestArgs {
            // verify required parameter 'meetupId' is not null or undefined
            if (meetupId === null || meetupId === undefined) {
                throw new RequiredError('meetupId','Required parameter meetupId was null or undefined when calling createHall.');
            }
            // verify required parameter 'hallData' is not null or undefined
            if (hallData === null || hallData === undefined) {
                throw new RequiredError('hallData','Required parameter hallData was null or undefined when calling createHall.');
            }
            const localVarPath = `/api/meetup/{meetupId}/hall`
                .replace(`{${"meetupId"}}`, encodeURIComponent(String(meetupId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"HallData" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(hallData !== undefined ? hallData : {}) : (hallData || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {number} hallId Meetup hall identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteHall(meetupId: number, hallId: number, options: any = {}): RequestArgs {
            // verify required parameter 'meetupId' is not null or undefined
            if (meetupId === null || meetupId === undefined) {
                throw new RequiredError('meetupId','Required parameter meetupId was null or undefined when calling deleteHall.');
            }
            // verify required parameter 'hallId' is not null or undefined
            if (hallId === null || hallId === undefined) {
                throw new RequiredError('hallId','Required parameter hallId was null or undefined when calling deleteHall.');
            }
            const localVarPath = `/api/meetup/{meetupId}/hall/{hallId}`
                .replace(`{${"meetupId"}}`, encodeURIComponent(String(meetupId)))
                .replace(`{${"hallId"}}`, encodeURIComponent(String(hallId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {number} hallId Meetup hall identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHall(meetupId: number, hallId: number, options: any = {}): RequestArgs {
            // verify required parameter 'meetupId' is not null or undefined
            if (meetupId === null || meetupId === undefined) {
                throw new RequiredError('meetupId','Required parameter meetupId was null or undefined when calling getHall.');
            }
            // verify required parameter 'hallId' is not null or undefined
            if (hallId === null || hallId === undefined) {
                throw new RequiredError('hallId','Required parameter hallId was null or undefined when calling getHall.');
            }
            const localVarPath = `/api/meetup/{meetupId}/hall/{hallId}`
                .replace(`{${"meetupId"}}`, encodeURIComponent(String(meetupId)))
                .replace(`{${"hallId"}}`, encodeURIComponent(String(hallId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listHalls(meetupId: number, options: any = {}): RequestArgs {
            // verify required parameter 'meetupId' is not null or undefined
            if (meetupId === null || meetupId === undefined) {
                throw new RequiredError('meetupId','Required parameter meetupId was null or undefined when calling listHalls.');
            }
            const localVarPath = `/api/meetup/{meetupId}/hall`
                .replace(`{${"meetupId"}}`, encodeURIComponent(String(meetupId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {number} hallId Meetup hall identifier.
         * @param {HallData} hallData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateHall(meetupId: number, hallId: number, hallData: HallData, options: any = {}): RequestArgs {
            // verify required parameter 'meetupId' is not null or undefined
            if (meetupId === null || meetupId === undefined) {
                throw new RequiredError('meetupId','Required parameter meetupId was null or undefined when calling updateHall.');
            }
            // verify required parameter 'hallId' is not null or undefined
            if (hallId === null || hallId === undefined) {
                throw new RequiredError('hallId','Required parameter hallId was null or undefined when calling updateHall.');
            }
            // verify required parameter 'hallData' is not null or undefined
            if (hallData === null || hallData === undefined) {
                throw new RequiredError('hallData','Required parameter hallData was null or undefined when calling updateHall.');
            }
            const localVarPath = `/api/meetup/{meetupId}/hall/{hallId}`
                .replace(`{${"meetupId"}}`, encodeURIComponent(String(meetupId)))
                .replace(`{${"hallId"}}`, encodeURIComponent(String(hallId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"HallData" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(hallData !== undefined ? hallData : {}) : (hallData || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HallApi - functional programming interface
 * @export
 */
export const HallApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {HallData} hallData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createHall(meetupId: number, hallData: HallData, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<HallData> {
            const localVarAxiosArgs = HallApiAxiosParamCreator(configuration).createHall(meetupId, hallData, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {number} hallId Meetup hall identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteHall(meetupId: number, hallId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = HallApiAxiosParamCreator(configuration).deleteHall(meetupId, hallId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {number} hallId Meetup hall identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHall(meetupId: number, hallId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<HallData> {
            const localVarAxiosArgs = HallApiAxiosParamCreator(configuration).getHall(meetupId, hallId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listHalls(meetupId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<HallData>> {
            const localVarAxiosArgs = HallApiAxiosParamCreator(configuration).listHalls(meetupId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {number} hallId Meetup hall identifier.
         * @param {HallData} hallData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateHall(meetupId: number, hallId: number, hallData: HallData, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<HallData> {
            const localVarAxiosArgs = HallApiAxiosParamCreator(configuration).updateHall(meetupId, hallId, hallData, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * HallApi - factory interface
 * @export
 */
export const HallApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {HallData} hallData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createHall(meetupId: number, hallData: HallData, options?: any) {
            return HallApiFp(configuration).createHall(meetupId, hallData, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {number} hallId Meetup hall identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteHall(meetupId: number, hallId: number, options?: any) {
            return HallApiFp(configuration).deleteHall(meetupId, hallId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {number} hallId Meetup hall identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHall(meetupId: number, hallId: number, options?: any) {
            return HallApiFp(configuration).getHall(meetupId, hallId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listHalls(meetupId: number, options?: any) {
            return HallApiFp(configuration).listHalls(meetupId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {number} hallId Meetup hall identifier.
         * @param {HallData} hallData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateHall(meetupId: number, hallId: number, hallData: HallData, options?: any) {
            return HallApiFp(configuration).updateHall(meetupId, hallId, hallData, options)(axios, basePath);
        },
    };
};

/**
 * HallApi - object-oriented interface
 * @export
 * @class HallApi
 * @extends {BaseAPI}
 */
export class HallApi extends BaseAPI {
    /**
     * 
     * @param {number} meetupId Meetup identifier.
     * @param {HallData} hallData 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HallApi
     */
    public createHall(meetupId: number, hallData: HallData, options?: any) {
        return HallApiFp(this.configuration).createHall(meetupId, hallData, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} meetupId Meetup identifier.
     * @param {number} hallId Meetup hall identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HallApi
     */
    public deleteHall(meetupId: number, hallId: number, options?: any) {
        return HallApiFp(this.configuration).deleteHall(meetupId, hallId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} meetupId Meetup identifier.
     * @param {number} hallId Meetup hall identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HallApi
     */
    public getHall(meetupId: number, hallId: number, options?: any) {
        return HallApiFp(this.configuration).getHall(meetupId, hallId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} meetupId Meetup identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HallApi
     */
    public listHalls(meetupId: number, options?: any) {
        return HallApiFp(this.configuration).listHalls(meetupId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} meetupId Meetup identifier.
     * @param {number} hallId Meetup hall identifier.
     * @param {HallData} hallData 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HallApi
     */
    public updateHall(meetupId: number, hallId: number, hallData: HallData, options?: any) {
        return HallApiFp(this.configuration).updateHall(meetupId, hallId, hallData, options)(this.axios, this.basePath);
    }

}


/**
 * HistoryApi - axios parameter creator
 * @export
 */
export const HistoryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} accountId Account identifier.
         * @param {number} chatConfigId Chat config identifier.
         * @param {string} chatClientId Jaicp client identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHistory(accountId: number, chatConfigId: number, chatClientId: string, options: any = {}): RequestArgs {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId','Required parameter accountId was null or undefined when calling getHistory.');
            }
            // verify required parameter 'chatConfigId' is not null or undefined
            if (chatConfigId === null || chatConfigId === undefined) {
                throw new RequiredError('chatConfigId','Required parameter chatConfigId was null or undefined when calling getHistory.');
            }
            // verify required parameter 'chatClientId' is not null or undefined
            if (chatClientId === null || chatClientId === undefined) {
                throw new RequiredError('chatClientId','Required parameter chatClientId was null or undefined when calling getHistory.');
            }
            const localVarPath = `/api/account/{accountId}/history/{chatConfigId}/{chatClientId}`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"chatConfigId"}}`, encodeURIComponent(String(chatConfigId)))
                .replace(`{${"chatClientId"}}`, encodeURIComponent(String(chatClientId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} accountId Account identifier.
         * @param {number} mid Meetup identifier.
         * @param {number} vid Visitor identifier.
         * @param {number} [ccid] Chat config identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listClients(accountId: number, mid: number, vid: number, ccid?: number, options: any = {}): RequestArgs {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId','Required parameter accountId was null or undefined when calling listClients.');
            }
            // verify required parameter 'mid' is not null or undefined
            if (mid === null || mid === undefined) {
                throw new RequiredError('mid','Required parameter mid was null or undefined when calling listClients.');
            }
            // verify required parameter 'vid' is not null or undefined
            if (vid === null || vid === undefined) {
                throw new RequiredError('vid','Required parameter vid was null or undefined when calling listClients.');
            }
            const localVarPath = `/api/account/{accountId}/client`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (mid !== undefined) {
                localVarQueryParameter['mid'] = mid;
            }

            if (ccid !== undefined) {
                localVarQueryParameter['ccid'] = ccid;
            }

            if (vid !== undefined) {
                localVarQueryParameter['vid'] = vid;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<WriteHistoryData>} writeHistoryData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveHistory(writeHistoryData: Array<WriteHistoryData>, options: any = {}): RequestArgs {
            // verify required parameter 'writeHistoryData' is not null or undefined
            if (writeHistoryData === null || writeHistoryData === undefined) {
                throw new RequiredError('writeHistoryData','Required parameter writeHistoryData was null or undefined when calling saveHistory.');
            }
            const localVarPath = `/api/history`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"Array&lt;WriteHistoryData&gt;" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(writeHistoryData !== undefined ? writeHistoryData : {}) : (writeHistoryData || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {VoteResultData} voteResultData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveVoteResult(voteResultData: VoteResultData, options: any = {}): RequestArgs {
            // verify required parameter 'voteResultData' is not null or undefined
            if (voteResultData === null || voteResultData === undefined) {
                throw new RequiredError('voteResultData','Required parameter voteResultData was null or undefined when calling saveVoteResult.');
            }
            const localVarPath = `/api/save-vote-result`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"VoteResultData" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(voteResultData !== undefined ? voteResultData : {}) : (voteResultData || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HistoryApi - functional programming interface
 * @export
 */
export const HistoryApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} accountId Account identifier.
         * @param {number} chatConfigId Chat config identifier.
         * @param {string} chatClientId Jaicp client identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHistory(accountId: number, chatConfigId: number, chatClientId: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChatMessagesData> {
            const localVarAxiosArgs = HistoryApiAxiosParamCreator(configuration).getHistory(accountId, chatConfigId, chatClientId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} accountId Account identifier.
         * @param {number} mid Meetup identifier.
         * @param {number} vid Visitor identifier.
         * @param {number} [ccid] Chat config identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listClients(accountId: number, mid: number, vid: number, ccid?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ChatClientData>> {
            const localVarAxiosArgs = HistoryApiAxiosParamCreator(configuration).listClients(accountId, mid, vid, ccid, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {Array<WriteHistoryData>} writeHistoryData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveHistory(writeHistoryData: Array<WriteHistoryData>, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = HistoryApiAxiosParamCreator(configuration).saveHistory(writeHistoryData, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {VoteResultData} voteResultData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveVoteResult(voteResultData: VoteResultData, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = HistoryApiAxiosParamCreator(configuration).saveVoteResult(voteResultData, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * HistoryApi - factory interface
 * @export
 */
export const HistoryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {number} accountId Account identifier.
         * @param {number} chatConfigId Chat config identifier.
         * @param {string} chatClientId Jaicp client identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHistory(accountId: number, chatConfigId: number, chatClientId: string, options?: any) {
            return HistoryApiFp(configuration).getHistory(accountId, chatConfigId, chatClientId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} accountId Account identifier.
         * @param {number} mid Meetup identifier.
         * @param {number} vid Visitor identifier.
         * @param {number} [ccid] Chat config identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listClients(accountId: number, mid: number, vid: number, ccid?: number, options?: any) {
            return HistoryApiFp(configuration).listClients(accountId, mid, vid, ccid, options)(axios, basePath);
        },
        /**
         * 
         * @param {Array<WriteHistoryData>} writeHistoryData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveHistory(writeHistoryData: Array<WriteHistoryData>, options?: any) {
            return HistoryApiFp(configuration).saveHistory(writeHistoryData, options)(axios, basePath);
        },
        /**
         * 
         * @param {VoteResultData} voteResultData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveVoteResult(voteResultData: VoteResultData, options?: any) {
            return HistoryApiFp(configuration).saveVoteResult(voteResultData, options)(axios, basePath);
        },
    };
};

/**
 * HistoryApi - object-oriented interface
 * @export
 * @class HistoryApi
 * @extends {BaseAPI}
 */
export class HistoryApi extends BaseAPI {
    /**
     * 
     * @param {number} accountId Account identifier.
     * @param {number} chatConfigId Chat config identifier.
     * @param {string} chatClientId Jaicp client identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HistoryApi
     */
    public getHistory(accountId: number, chatConfigId: number, chatClientId: string, options?: any) {
        return HistoryApiFp(this.configuration).getHistory(accountId, chatConfigId, chatClientId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} accountId Account identifier.
     * @param {number} mid Meetup identifier.
     * @param {number} vid Visitor identifier.
     * @param {number} [ccid] Chat config identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HistoryApi
     */
    public listClients(accountId: number, mid: number, vid: number, ccid?: number, options?: any) {
        return HistoryApiFp(this.configuration).listClients(accountId, mid, vid, ccid, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {Array<WriteHistoryData>} writeHistoryData 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HistoryApi
     */
    public saveHistory(writeHistoryData: Array<WriteHistoryData>, options?: any) {
        return HistoryApiFp(this.configuration).saveHistory(writeHistoryData, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {VoteResultData} voteResultData 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HistoryApi
     */
    public saveVoteResult(voteResultData: VoteResultData, options?: any) {
        return HistoryApiFp(this.configuration).saveVoteResult(voteResultData, options)(this.axios, this.basePath);
    }

}


/**
 * ITTestApi - axios parameter creator
 * @export
 */
export const ITTestApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} recipient Email of a recipient.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkEmailForTest(recipient: string, options: any = {}): RequestArgs {
            // verify required parameter 'recipient' is not null or undefined
            if (recipient === null || recipient === undefined) {
                throw new RequiredError('recipient','Required parameter recipient was null or undefined when calling checkEmailForTest.');
            }
            const localVarPath = `/api-test/check-email`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (recipient !== undefined) {
                localVarQueryParameter['recipient'] = recipient;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} scenarioId Test scenario identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestTestSession(scenarioId: string, options: any = {}): RequestArgs {
            // verify required parameter 'scenarioId' is not null or undefined
            if (scenarioId === null || scenarioId === undefined) {
                throw new RequiredError('scenarioId','Required parameter scenarioId was null or undefined when calling requestTestSession.');
            }
            const localVarPath = `/api-test/test-session`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (scenarioId !== undefined) {
                localVarQueryParameter['scenarioId'] = scenarioId;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ITTestApi - functional programming interface
 * @export
 */
export const ITTestApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} recipient Email of a recipient.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkEmailForTest(recipient: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmailInfoData> {
            const localVarAxiosArgs = ITTestApiAxiosParamCreator(configuration).checkEmailForTest(recipient, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} scenarioId Test scenario identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestTestSession(scenarioId: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SessionInfoData> {
            const localVarAxiosArgs = ITTestApiAxiosParamCreator(configuration).requestTestSession(scenarioId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ITTestApi - factory interface
 * @export
 */
export const ITTestApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {string} recipient Email of a recipient.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkEmailForTest(recipient: string, options?: any) {
            return ITTestApiFp(configuration).checkEmailForTest(recipient, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} scenarioId Test scenario identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestTestSession(scenarioId: string, options?: any) {
            return ITTestApiFp(configuration).requestTestSession(scenarioId, options)(axios, basePath);
        },
    };
};

/**
 * ITTestApi - object-oriented interface
 * @export
 * @class ITTestApi
 * @extends {BaseAPI}
 */
export class ITTestApi extends BaseAPI {
    /**
     * 
     * @param {string} recipient Email of a recipient.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ITTestApi
     */
    public checkEmailForTest(recipient: string, options?: any) {
        return ITTestApiFp(this.configuration).checkEmailForTest(recipient, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} scenarioId Test scenario identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ITTestApi
     */
    public requestTestSession(scenarioId: string, options?: any) {
        return ITTestApiFp(this.configuration).requestTestSession(scenarioId, options)(this.axios, this.basePath);
    }

}


/**
 * ImageApi - axios parameter creator
 * @export
 */
export const ImageApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} imageId Badge identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteImage(imageId: string, options: any = {}): RequestArgs {
            // verify required parameter 'imageId' is not null or undefined
            if (imageId === null || imageId === undefined) {
                throw new RequiredError('imageId','Required parameter imageId was null or undefined when calling deleteImage.');
            }
            const localVarPath = `/api/image/{imageId}`
                .replace(`{${"imageId"}}`, encodeURIComponent(String(imageId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} mid Meetup identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        encodings(mid: number, options: any = {}): RequestArgs {
            // verify required parameter 'mid' is not null or undefined
            if (mid === null || mid === undefined) {
                throw new RequiredError('mid','Required parameter mid was null or undefined when calling encodings.');
            }
            const localVarPath = `/api/image/encodings`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (mid !== undefined) {
                localVarQueryParameter['mid'] = mid;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} imageId Badge identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getImage(imageId: string, options: any = {}): RequestArgs {
            // verify required parameter 'imageId' is not null or undefined
            if (imageId === null || imageId === undefined) {
                throw new RequiredError('imageId','Required parameter imageId was null or undefined when calling getImage.');
            }
            const localVarPath = `/api/image/{imageId}`
                .replace(`{${"imageId"}}`, encodeURIComponent(String(imageId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} qrToken qrToken.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getQR(qrToken: string, options: any = {}): RequestArgs {
            // verify required parameter 'qrToken' is not null or undefined
            if (qrToken === null || qrToken === undefined) {
                throw new RequiredError('qrToken','Required parameter qrToken was null or undefined when calling getQR.');
            }
            const localVarPath = `/api/qr/{qrToken}`
                .replace(`{${"qrToken"}}`, encodeURIComponent(String(qrToken)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} qrToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getQR2(qrToken: string, options: any = {}): RequestArgs {
            // verify required parameter 'qrToken' is not null or undefined
            if (qrToken === null || qrToken === undefined) {
                throw new RequiredError('qrToken','Required parameter qrToken was null or undefined when calling getQR2.');
            }
            const localVarPath = `/api/qr`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (qrToken !== undefined) {
                localVarQueryParameter['qrToken'] = qrToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {boolean} [showAll] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listImages(showAll?: boolean, options: any = {}): RequestArgs {
            const localVarPath = `/api/image`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (showAll !== undefined) {
                localVarQueryParameter['showAll'] = showAll;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} imageId Badge identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        similarity(imageId: string, options: any = {}): RequestArgs {
            // verify required parameter 'imageId' is not null or undefined
            if (imageId === null || imageId === undefined) {
                throw new RequiredError('imageId','Required parameter imageId was null or undefined when calling similarity.');
            }
            const localVarPath = `/api/image/{imageId}/sim`
                .replace(`{${"imageId"}}`, encodeURIComponent(String(imageId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [mid] Meetup identifier.
         * @param {number} [vid] Visitor identifier.
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadImage(mid?: number, vid?: number, file?: any, options: any = {}): RequestArgs {
            const localVarPath = `/api/image`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (mid !== undefined) {
                localVarQueryParameter['mid'] = mid;
            }

            if (vid !== undefined) {
                localVarQueryParameter['vid'] = vid;
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ImageApi - functional programming interface
 * @export
 */
export const ImageApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} imageId Badge identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteImage(imageId: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = ImageApiAxiosParamCreator(configuration).deleteImage(imageId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} mid Meetup identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        encodings(mid: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FaceEncodingData>> {
            const localVarAxiosArgs = ImageApiAxiosParamCreator(configuration).encodings(mid, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} imageId Badge identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getImage(imageId: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any> {
            const localVarAxiosArgs = ImageApiAxiosParamCreator(configuration).getImage(imageId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} qrToken qrToken.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getQR(qrToken: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any> {
            const localVarAxiosArgs = ImageApiAxiosParamCreator(configuration).getQR(qrToken, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} qrToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getQR2(qrToken: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any> {
            const localVarAxiosArgs = ImageApiAxiosParamCreator(configuration).getQR2(qrToken, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {boolean} [showAll] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listImages(showAll?: boolean, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ImageData>> {
            const localVarAxiosArgs = ImageApiAxiosParamCreator(configuration).listImages(showAll, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} imageId Badge identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        similarity(imageId: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object> {
            const localVarAxiosArgs = ImageApiAxiosParamCreator(configuration).similarity(imageId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} [mid] Meetup identifier.
         * @param {number} [vid] Visitor identifier.
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadImage(mid?: number, vid?: number, file?: any, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ImageData> {
            const localVarAxiosArgs = ImageApiAxiosParamCreator(configuration).uploadImage(mid, vid, file, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ImageApi - factory interface
 * @export
 */
export const ImageApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {string} imageId Badge identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteImage(imageId: string, options?: any) {
            return ImageApiFp(configuration).deleteImage(imageId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} mid Meetup identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        encodings(mid: number, options?: any) {
            return ImageApiFp(configuration).encodings(mid, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} imageId Badge identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getImage(imageId: string, options?: any) {
            return ImageApiFp(configuration).getImage(imageId, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} qrToken qrToken.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getQR(qrToken: string, options?: any) {
            return ImageApiFp(configuration).getQR(qrToken, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} qrToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getQR2(qrToken: string, options?: any) {
            return ImageApiFp(configuration).getQR2(qrToken, options)(axios, basePath);
        },
        /**
         * 
         * @param {boolean} [showAll] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listImages(showAll?: boolean, options?: any) {
            return ImageApiFp(configuration).listImages(showAll, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} imageId Badge identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        similarity(imageId: string, options?: any) {
            return ImageApiFp(configuration).similarity(imageId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} [mid] Meetup identifier.
         * @param {number} [vid] Visitor identifier.
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadImage(mid?: number, vid?: number, file?: any, options?: any) {
            return ImageApiFp(configuration).uploadImage(mid, vid, file, options)(axios, basePath);
        },
    };
};

/**
 * ImageApi - object-oriented interface
 * @export
 * @class ImageApi
 * @extends {BaseAPI}
 */
export class ImageApi extends BaseAPI {
    /**
     * 
     * @param {string} imageId Badge identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImageApi
     */
    public deleteImage(imageId: string, options?: any) {
        return ImageApiFp(this.configuration).deleteImage(imageId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} mid Meetup identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImageApi
     */
    public encodings(mid: number, options?: any) {
        return ImageApiFp(this.configuration).encodings(mid, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} imageId Badge identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImageApi
     */
    public getImage(imageId: string, options?: any) {
        return ImageApiFp(this.configuration).getImage(imageId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} qrToken qrToken.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImageApi
     */
    public getQR(qrToken: string, options?: any) {
        return ImageApiFp(this.configuration).getQR(qrToken, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} qrToken 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImageApi
     */
    public getQR2(qrToken: string, options?: any) {
        return ImageApiFp(this.configuration).getQR2(qrToken, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {boolean} [showAll] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImageApi
     */
    public listImages(showAll?: boolean, options?: any) {
        return ImageApiFp(this.configuration).listImages(showAll, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} imageId Badge identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImageApi
     */
    public similarity(imageId: string, options?: any) {
        return ImageApiFp(this.configuration).similarity(imageId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} [mid] Meetup identifier.
     * @param {number} [vid] Visitor identifier.
     * @param {any} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImageApi
     */
    public uploadImage(mid?: number, vid?: number, file?: any, options?: any) {
        return ImageApiFp(this.configuration).uploadImage(mid, vid, file, options)(this.axios, this.basePath);
    }

}


/**
 * JobApi - axios parameter creator
 * @export
 */
export const JobApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listJobs(options: any = {}): RequestArgs {
            const localVarPath = `/api-test/jobs`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} jobId Job identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runJob(jobId: string, options: any = {}): RequestArgs {
            // verify required parameter 'jobId' is not null or undefined
            if (jobId === null || jobId === undefined) {
                throw new RequiredError('jobId','Required parameter jobId was null or undefined when calling runJob.');
            }
            const localVarPath = `/api-test/jobs/{jobId}/run`
                .replace(`{${"jobId"}}`, encodeURIComponent(String(jobId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * JobApi - functional programming interface
 * @export
 */
export const JobApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listJobs(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>> {
            const localVarAxiosArgs = JobApiAxiosParamCreator(configuration).listJobs(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} jobId Job identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runJob(jobId: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = JobApiAxiosParamCreator(configuration).runJob(jobId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * JobApi - factory interface
 * @export
 */
export const JobApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listJobs(options?: any) {
            return JobApiFp(configuration).listJobs(options)(axios, basePath);
        },
        /**
         * 
         * @param {string} jobId Job identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runJob(jobId: string, options?: any) {
            return JobApiFp(configuration).runJob(jobId, options)(axios, basePath);
        },
    };
};

/**
 * JobApi - object-oriented interface
 * @export
 * @class JobApi
 * @extends {BaseAPI}
 */
export class JobApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobApi
     */
    public listJobs(options?: any) {
        return JobApiFp(this.configuration).listJobs(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} jobId Job identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobApi
     */
    public runJob(jobId: string, options?: any) {
        return JobApiFp(this.configuration).runJob(jobId, options)(this.axios, this.basePath);
    }

}


/**
 * MeetupApi - axios parameter creator
 * @export
 */
export const MeetupApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {MeetupData} meetupData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMeetup(meetupData: MeetupData, options: any = {}): RequestArgs {
            // verify required parameter 'meetupData' is not null or undefined
            if (meetupData === null || meetupData === undefined) {
                throw new RequiredError('meetupData','Required parameter meetupData was null or undefined when calling createMeetup.');
            }
            const localVarPath = `/api/meetup`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"MeetupData" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(meetupData !== undefined ? meetupData : {}) : (meetupData || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMeetup(meetupId: number, options: any = {}): RequestArgs {
            // verify required parameter 'meetupId' is not null or undefined
            if (meetupId === null || meetupId === undefined) {
                throw new RequiredError('meetupId','Required parameter meetupId was null or undefined when calling deleteMeetup.');
            }
            const localVarPath = `/api/meetup/{meetupId}`
                .replace(`{${"meetupId"}}`, encodeURIComponent(String(meetupId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeskReport(meetupId: number, options: any = {}): RequestArgs {
            // verify required parameter 'meetupId' is not null or undefined
            if (meetupId === null || meetupId === undefined) {
                throw new RequiredError('meetupId','Required parameter meetupId was null or undefined when calling getDeskReport.');
            }
            const localVarPath = `/api/meetup/{meetupId}/deskreport`
                .replace(`{${"meetupId"}}`, encodeURIComponent(String(meetupId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMeetup(meetupId: number, options: any = {}): RequestArgs {
            // verify required parameter 'meetupId' is not null or undefined
            if (meetupId === null || meetupId === undefined) {
                throw new RequiredError('meetupId','Required parameter meetupId was null or undefined when calling getMeetup.');
            }
            const localVarPath = `/api/meetup/{meetupId}`
                .replace(`{${"meetupId"}}`, encodeURIComponent(String(meetupId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReport(meetupId: number, options: any = {}): RequestArgs {
            // verify required parameter 'meetupId' is not null or undefined
            if (meetupId === null || meetupId === undefined) {
                throw new RequiredError('meetupId','Required parameter meetupId was null or undefined when calling getReport.');
            }
            const localVarPath = `/api/meetup/{meetupId}/report`
                .replace(`{${"meetupId"}}`, encodeURIComponent(String(meetupId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {boolean} [showAll] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMeetups(showAll?: boolean, options: any = {}): RequestArgs {
            const localVarPath = `/api/meetup`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (showAll !== undefined) {
                localVarQueryParameter['showAll'] = showAll;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {MeetupData} meetupData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMeetup(meetupId: number, meetupData: MeetupData, options: any = {}): RequestArgs {
            // verify required parameter 'meetupId' is not null or undefined
            if (meetupId === null || meetupId === undefined) {
                throw new RequiredError('meetupId','Required parameter meetupId was null or undefined when calling updateMeetup.');
            }
            // verify required parameter 'meetupData' is not null or undefined
            if (meetupData === null || meetupData === undefined) {
                throw new RequiredError('meetupData','Required parameter meetupData was null or undefined when calling updateMeetup.');
            }
            const localVarPath = `/api/meetup/{meetupId}`
                .replace(`{${"meetupId"}}`, encodeURIComponent(String(meetupId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"MeetupData" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(meetupData !== undefined ? meetupData : {}) : (meetupData || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MeetupApi - functional programming interface
 * @export
 */
export const MeetupApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {MeetupData} meetupData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMeetup(meetupData: MeetupData, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<MeetupData> {
            const localVarAxiosArgs = MeetupApiAxiosParamCreator(configuration).createMeetup(meetupData, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMeetup(meetupId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = MeetupApiAxiosParamCreator(configuration).deleteMeetup(meetupId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeskReport(meetupId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeskReportData> {
            const localVarAxiosArgs = MeetupApiAxiosParamCreator(configuration).getDeskReport(meetupId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMeetup(meetupId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<MeetupData> {
            const localVarAxiosArgs = MeetupApiAxiosParamCreator(configuration).getMeetup(meetupId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReport(meetupId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReportData> {
            const localVarAxiosArgs = MeetupApiAxiosParamCreator(configuration).getReport(meetupId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {boolean} [showAll] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMeetups(showAll?: boolean, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MeetupData>> {
            const localVarAxiosArgs = MeetupApiAxiosParamCreator(configuration).listMeetups(showAll, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {MeetupData} meetupData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMeetup(meetupId: number, meetupData: MeetupData, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<MeetupData> {
            const localVarAxiosArgs = MeetupApiAxiosParamCreator(configuration).updateMeetup(meetupId, meetupData, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * MeetupApi - factory interface
 * @export
 */
export const MeetupApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {MeetupData} meetupData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMeetup(meetupData: MeetupData, options?: any) {
            return MeetupApiFp(configuration).createMeetup(meetupData, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMeetup(meetupId: number, options?: any) {
            return MeetupApiFp(configuration).deleteMeetup(meetupId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeskReport(meetupId: number, options?: any) {
            return MeetupApiFp(configuration).getDeskReport(meetupId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMeetup(meetupId: number, options?: any) {
            return MeetupApiFp(configuration).getMeetup(meetupId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReport(meetupId: number, options?: any) {
            return MeetupApiFp(configuration).getReport(meetupId, options)(axios, basePath);
        },
        /**
         * 
         * @param {boolean} [showAll] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMeetups(showAll?: boolean, options?: any) {
            return MeetupApiFp(configuration).listMeetups(showAll, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {MeetupData} meetupData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMeetup(meetupId: number, meetupData: MeetupData, options?: any) {
            return MeetupApiFp(configuration).updateMeetup(meetupId, meetupData, options)(axios, basePath);
        },
    };
};

/**
 * MeetupApi - object-oriented interface
 * @export
 * @class MeetupApi
 * @extends {BaseAPI}
 */
export class MeetupApi extends BaseAPI {
    /**
     * 
     * @param {MeetupData} meetupData 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeetupApi
     */
    public createMeetup(meetupData: MeetupData, options?: any) {
        return MeetupApiFp(this.configuration).createMeetup(meetupData, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} meetupId Meetup identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeetupApi
     */
    public deleteMeetup(meetupId: number, options?: any) {
        return MeetupApiFp(this.configuration).deleteMeetup(meetupId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} meetupId Meetup identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeetupApi
     */
    public getDeskReport(meetupId: number, options?: any) {
        return MeetupApiFp(this.configuration).getDeskReport(meetupId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} meetupId Meetup identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeetupApi
     */
    public getMeetup(meetupId: number, options?: any) {
        return MeetupApiFp(this.configuration).getMeetup(meetupId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} meetupId Meetup identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeetupApi
     */
    public getReport(meetupId: number, options?: any) {
        return MeetupApiFp(this.configuration).getReport(meetupId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {boolean} [showAll] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeetupApi
     */
    public listMeetups(showAll?: boolean, options?: any) {
        return MeetupApiFp(this.configuration).listMeetups(showAll, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} meetupId Meetup identifier.
     * @param {MeetupData} meetupData 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeetupApi
     */
    public updateMeetup(meetupId: number, meetupData: MeetupData, options?: any) {
        return MeetupApiFp(this.configuration).updateMeetup(meetupId, meetupData, options)(this.axios, this.basePath);
    }

}


/**
 * MerchApi - axios parameter creator
 * @export
 */
export const MerchApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {MerchData} merchData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMerch(meetupId: number, merchData: MerchData, options: any = {}): RequestArgs {
            // verify required parameter 'meetupId' is not null or undefined
            if (meetupId === null || meetupId === undefined) {
                throw new RequiredError('meetupId','Required parameter meetupId was null or undefined when calling createMerch.');
            }
            // verify required parameter 'merchData' is not null or undefined
            if (merchData === null || merchData === undefined) {
                throw new RequiredError('merchData','Required parameter merchData was null or undefined when calling createMerch.');
            }
            const localVarPath = `/api/meetup/{meetupId}/merch`
                .replace(`{${"meetupId"}}`, encodeURIComponent(String(meetupId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"MerchData" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(merchData !== undefined ? merchData : {}) : (merchData || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {PurchaseRequestData} purchaseRequestData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPurchaseLink(meetupId: number, purchaseRequestData: PurchaseRequestData, options: any = {}): RequestArgs {
            // verify required parameter 'meetupId' is not null or undefined
            if (meetupId === null || meetupId === undefined) {
                throw new RequiredError('meetupId','Required parameter meetupId was null or undefined when calling createPurchaseLink.');
            }
            // verify required parameter 'purchaseRequestData' is not null or undefined
            if (purchaseRequestData === null || purchaseRequestData === undefined) {
                throw new RequiredError('purchaseRequestData','Required parameter purchaseRequestData was null or undefined when calling createPurchaseLink.');
            }
            const localVarPath = `/api/meetup/{meetupId}/purchase-link`
                .replace(`{${"meetupId"}}`, encodeURIComponent(String(meetupId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"PurchaseRequestData" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(purchaseRequestData !== undefined ? purchaseRequestData : {}) : (purchaseRequestData || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {number} merchId Product identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMerch(meetupId: number, merchId: number, options: any = {}): RequestArgs {
            // verify required parameter 'meetupId' is not null or undefined
            if (meetupId === null || meetupId === undefined) {
                throw new RequiredError('meetupId','Required parameter meetupId was null or undefined when calling deleteMerch.');
            }
            // verify required parameter 'merchId' is not null or undefined
            if (merchId === null || merchId === undefined) {
                throw new RequiredError('merchId','Required parameter merchId was null or undefined when calling deleteMerch.');
            }
            const localVarPath = `/api/meetup/{meetupId}/merch/{merchId}`
                .replace(`{${"meetupId"}}`, encodeURIComponent(String(meetupId)))
                .replace(`{${"merchId"}}`, encodeURIComponent(String(merchId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {number} merchId Product identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMerch(meetupId: number, merchId: number, options: any = {}): RequestArgs {
            // verify required parameter 'meetupId' is not null or undefined
            if (meetupId === null || meetupId === undefined) {
                throw new RequiredError('meetupId','Required parameter meetupId was null or undefined when calling getMerch.');
            }
            // verify required parameter 'merchId' is not null or undefined
            if (merchId === null || merchId === undefined) {
                throw new RequiredError('merchId','Required parameter merchId was null or undefined when calling getMerch.');
            }
            const localVarPath = `/api/meetup/{meetupId}/merch/{merchId}`
                .replace(`{${"meetupId"}}`, encodeURIComponent(String(meetupId)))
                .replace(`{${"merchId"}}`, encodeURIComponent(String(merchId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMerchs(meetupId: number, options: any = {}): RequestArgs {
            // verify required parameter 'meetupId' is not null or undefined
            if (meetupId === null || meetupId === undefined) {
                throw new RequiredError('meetupId','Required parameter meetupId was null or undefined when calling listMerchs.');
            }
            const localVarPath = `/api/meetup/{meetupId}/merch`
                .replace(`{${"meetupId"}}`, encodeURIComponent(String(meetupId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPurchases(meetupId: number, options: any = {}): RequestArgs {
            // verify required parameter 'meetupId' is not null or undefined
            if (meetupId === null || meetupId === undefined) {
                throw new RequiredError('meetupId','Required parameter meetupId was null or undefined when calling listPurchases.');
            }
            const localVarPath = `/api/meetup/{meetupId}/purchase`
                .replace(`{${"meetupId"}}`, encodeURIComponent(String(meetupId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {number} merchId Product identifier.
         * @param {MerchData} merchData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMerch(meetupId: number, merchId: number, merchData: MerchData, options: any = {}): RequestArgs {
            // verify required parameter 'meetupId' is not null or undefined
            if (meetupId === null || meetupId === undefined) {
                throw new RequiredError('meetupId','Required parameter meetupId was null or undefined when calling updateMerch.');
            }
            // verify required parameter 'merchId' is not null or undefined
            if (merchId === null || merchId === undefined) {
                throw new RequiredError('merchId','Required parameter merchId was null or undefined when calling updateMerch.');
            }
            // verify required parameter 'merchData' is not null or undefined
            if (merchData === null || merchData === undefined) {
                throw new RequiredError('merchData','Required parameter merchData was null or undefined when calling updateMerch.');
            }
            const localVarPath = `/api/meetup/{meetupId}/merch/{merchId}`
                .replace(`{${"meetupId"}}`, encodeURIComponent(String(meetupId)))
                .replace(`{${"merchId"}}`, encodeURIComponent(String(merchId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"MerchData" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(merchData !== undefined ? merchData : {}) : (merchData || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MerchApi - functional programming interface
 * @export
 */
export const MerchApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {MerchData} merchData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMerch(meetupId: number, merchData: MerchData, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<MerchData> {
            const localVarAxiosArgs = MerchApiAxiosParamCreator(configuration).createMerch(meetupId, merchData, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {PurchaseRequestData} purchaseRequestData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPurchaseLink(meetupId: number, purchaseRequestData: PurchaseRequestData, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string> {
            const localVarAxiosArgs = MerchApiAxiosParamCreator(configuration).createPurchaseLink(meetupId, purchaseRequestData, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {number} merchId Product identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMerch(meetupId: number, merchId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = MerchApiAxiosParamCreator(configuration).deleteMerch(meetupId, merchId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {number} merchId Product identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMerch(meetupId: number, merchId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<MerchData> {
            const localVarAxiosArgs = MerchApiAxiosParamCreator(configuration).getMerch(meetupId, merchId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMerchs(meetupId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MerchData>> {
            const localVarAxiosArgs = MerchApiAxiosParamCreator(configuration).listMerchs(meetupId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPurchases(meetupId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<MeetupPurchasesData> {
            const localVarAxiosArgs = MerchApiAxiosParamCreator(configuration).listPurchases(meetupId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {number} merchId Product identifier.
         * @param {MerchData} merchData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMerch(meetupId: number, merchId: number, merchData: MerchData, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<MerchData> {
            const localVarAxiosArgs = MerchApiAxiosParamCreator(configuration).updateMerch(meetupId, merchId, merchData, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * MerchApi - factory interface
 * @export
 */
export const MerchApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {MerchData} merchData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMerch(meetupId: number, merchData: MerchData, options?: any) {
            return MerchApiFp(configuration).createMerch(meetupId, merchData, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {PurchaseRequestData} purchaseRequestData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPurchaseLink(meetupId: number, purchaseRequestData: PurchaseRequestData, options?: any) {
            return MerchApiFp(configuration).createPurchaseLink(meetupId, purchaseRequestData, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {number} merchId Product identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMerch(meetupId: number, merchId: number, options?: any) {
            return MerchApiFp(configuration).deleteMerch(meetupId, merchId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {number} merchId Product identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMerch(meetupId: number, merchId: number, options?: any) {
            return MerchApiFp(configuration).getMerch(meetupId, merchId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMerchs(meetupId: number, options?: any) {
            return MerchApiFp(configuration).listMerchs(meetupId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPurchases(meetupId: number, options?: any) {
            return MerchApiFp(configuration).listPurchases(meetupId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {number} merchId Product identifier.
         * @param {MerchData} merchData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMerch(meetupId: number, merchId: number, merchData: MerchData, options?: any) {
            return MerchApiFp(configuration).updateMerch(meetupId, merchId, merchData, options)(axios, basePath);
        },
    };
};

/**
 * MerchApi - object-oriented interface
 * @export
 * @class MerchApi
 * @extends {BaseAPI}
 */
export class MerchApi extends BaseAPI {
    /**
     * 
     * @param {number} meetupId Meetup identifier.
     * @param {MerchData} merchData 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MerchApi
     */
    public createMerch(meetupId: number, merchData: MerchData, options?: any) {
        return MerchApiFp(this.configuration).createMerch(meetupId, merchData, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} meetupId Meetup identifier.
     * @param {PurchaseRequestData} purchaseRequestData 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MerchApi
     */
    public createPurchaseLink(meetupId: number, purchaseRequestData: PurchaseRequestData, options?: any) {
        return MerchApiFp(this.configuration).createPurchaseLink(meetupId, purchaseRequestData, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} meetupId Meetup identifier.
     * @param {number} merchId Product identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MerchApi
     */
    public deleteMerch(meetupId: number, merchId: number, options?: any) {
        return MerchApiFp(this.configuration).deleteMerch(meetupId, merchId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} meetupId Meetup identifier.
     * @param {number} merchId Product identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MerchApi
     */
    public getMerch(meetupId: number, merchId: number, options?: any) {
        return MerchApiFp(this.configuration).getMerch(meetupId, merchId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} meetupId Meetup identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MerchApi
     */
    public listMerchs(meetupId: number, options?: any) {
        return MerchApiFp(this.configuration).listMerchs(meetupId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} meetupId Meetup identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MerchApi
     */
    public listPurchases(meetupId: number, options?: any) {
        return MerchApiFp(this.configuration).listPurchases(meetupId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} meetupId Meetup identifier.
     * @param {number} merchId Product identifier.
     * @param {MerchData} merchData 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MerchApi
     */
    public updateMerch(meetupId: number, merchId: number, merchData: MerchData, options?: any) {
        return MerchApiFp(this.configuration).updateMerch(meetupId, merchId, merchData, options)(this.axios, this.basePath);
    }

}


/**
 * NetworkingApi - axios parameter creator
 * @export
 */
export const NetworkingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {number} visitorId Visitor identifier.
         * @param {number} [direction] 
         * @param {number} [last] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCandidateForVisitor(meetupId: number, visitorId: number, direction?: number, last?: number, options: any = {}): RequestArgs {
            // verify required parameter 'meetupId' is not null or undefined
            if (meetupId === null || meetupId === undefined) {
                throw new RequiredError('meetupId','Required parameter meetupId was null or undefined when calling getCandidateForVisitor.');
            }
            // verify required parameter 'visitorId' is not null or undefined
            if (visitorId === null || visitorId === undefined) {
                throw new RequiredError('visitorId','Required parameter visitorId was null or undefined when calling getCandidateForVisitor.');
            }
            const localVarPath = `/api/meetup/{meetupId}/networking/{visitorId}/next`
                .replace(`{${"meetupId"}}`, encodeURIComponent(String(meetupId)))
                .replace(`{${"visitorId"}}`, encodeURIComponent(String(visitorId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (direction !== undefined) {
                localVarQueryParameter['direction'] = direction;
            }

            if (last !== undefined) {
                localVarQueryParameter['last'] = last;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNetworkingSettings(meetupId: number, options: any = {}): RequestArgs {
            // verify required parameter 'meetupId' is not null or undefined
            if (meetupId === null || meetupId === undefined) {
                throw new RequiredError('meetupId','Required parameter meetupId was null or undefined when calling getNetworkingSettings.');
            }
            const localVarPath = `/api/meetup/{meetupId}/networking`
                .replace(`{${"meetupId"}}`, encodeURIComponent(String(meetupId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {number} visitorId Visitor identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNetworkingVisitor(meetupId: number, visitorId: number, options: any = {}): RequestArgs {
            // verify required parameter 'meetupId' is not null or undefined
            if (meetupId === null || meetupId === undefined) {
                throw new RequiredError('meetupId','Required parameter meetupId was null or undefined when calling getNetworkingVisitor.');
            }
            // verify required parameter 'visitorId' is not null or undefined
            if (visitorId === null || visitorId === undefined) {
                throw new RequiredError('visitorId','Required parameter visitorId was null or undefined when calling getNetworkingVisitor.');
            }
            const localVarPath = `/api/meetup/{meetupId}/networking/{visitorId}`
                .replace(`{${"meetupId"}}`, encodeURIComponent(String(meetupId)))
                .replace(`{${"visitorId"}}`, encodeURIComponent(String(visitorId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {number} visitorId Visitor identifier.
         * @param {number} otherVisitorId Second visitor id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNetworkingVisitorWithStatus(meetupId: number, visitorId: number, otherVisitorId: number, options: any = {}): RequestArgs {
            // verify required parameter 'meetupId' is not null or undefined
            if (meetupId === null || meetupId === undefined) {
                throw new RequiredError('meetupId','Required parameter meetupId was null or undefined when calling getNetworkingVisitorWithStatus.');
            }
            // verify required parameter 'visitorId' is not null or undefined
            if (visitorId === null || visitorId === undefined) {
                throw new RequiredError('visitorId','Required parameter visitorId was null or undefined when calling getNetworkingVisitorWithStatus.');
            }
            // verify required parameter 'otherVisitorId' is not null or undefined
            if (otherVisitorId === null || otherVisitorId === undefined) {
                throw new RequiredError('otherVisitorId','Required parameter otherVisitorId was null or undefined when calling getNetworkingVisitorWithStatus.');
            }
            const localVarPath = `/api/meetup/{meetupId}/networking/{visitorId}/stat/{otherVisitorId}`
                .replace(`{${"meetupId"}}`, encodeURIComponent(String(meetupId)))
                .replace(`{${"visitorId"}}`, encodeURIComponent(String(visitorId)))
                .replace(`{${"otherVisitorId"}}`, encodeURIComponent(String(otherVisitorId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listNetworkingVisitors(meetupId: number, options: any = {}): RequestArgs {
            // verify required parameter 'meetupId' is not null or undefined
            if (meetupId === null || meetupId === undefined) {
                throw new RequiredError('meetupId','Required parameter meetupId was null or undefined when calling listNetworkingVisitors.');
            }
            const localVarPath = `/api/meetup/{meetupId}/networking/visitors`
                .replace(`{${"meetupId"}}`, encodeURIComponent(String(meetupId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {NetworkingContactData} networkingContactData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processContact(meetupId: number, networkingContactData: NetworkingContactData, options: any = {}): RequestArgs {
            // verify required parameter 'meetupId' is not null or undefined
            if (meetupId === null || meetupId === undefined) {
                throw new RequiredError('meetupId','Required parameter meetupId was null or undefined when calling processContact.');
            }
            // verify required parameter 'networkingContactData' is not null or undefined
            if (networkingContactData === null || networkingContactData === undefined) {
                throw new RequiredError('networkingContactData','Required parameter networkingContactData was null or undefined when calling processContact.');
            }
            const localVarPath = `/api/meetup/{meetupId}/networking/contact`
                .replace(`{${"meetupId"}}`, encodeURIComponent(String(meetupId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"NetworkingContactData" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(networkingContactData !== undefined ? networkingContactData : {}) : (networkingContactData || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {NetworkingSettingsData} networkingSettingsData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNetworkingSettings(meetupId: number, networkingSettingsData: NetworkingSettingsData, options: any = {}): RequestArgs {
            // verify required parameter 'meetupId' is not null or undefined
            if (meetupId === null || meetupId === undefined) {
                throw new RequiredError('meetupId','Required parameter meetupId was null or undefined when calling updateNetworkingSettings.');
            }
            // verify required parameter 'networkingSettingsData' is not null or undefined
            if (networkingSettingsData === null || networkingSettingsData === undefined) {
                throw new RequiredError('networkingSettingsData','Required parameter networkingSettingsData was null or undefined when calling updateNetworkingSettings.');
            }
            const localVarPath = `/api/meetup/{meetupId}/networking`
                .replace(`{${"meetupId"}}`, encodeURIComponent(String(meetupId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"NetworkingSettingsData" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(networkingSettingsData !== undefined ? networkingSettingsData : {}) : (networkingSettingsData || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {number} visitorId Visitor identifier.
         * @param {NetworkingVisitorData} networkingVisitorData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNetworkingVisitor(meetupId: number, visitorId: number, networkingVisitorData: NetworkingVisitorData, options: any = {}): RequestArgs {
            // verify required parameter 'meetupId' is not null or undefined
            if (meetupId === null || meetupId === undefined) {
                throw new RequiredError('meetupId','Required parameter meetupId was null or undefined when calling updateNetworkingVisitor.');
            }
            // verify required parameter 'visitorId' is not null or undefined
            if (visitorId === null || visitorId === undefined) {
                throw new RequiredError('visitorId','Required parameter visitorId was null or undefined when calling updateNetworkingVisitor.');
            }
            // verify required parameter 'networkingVisitorData' is not null or undefined
            if (networkingVisitorData === null || networkingVisitorData === undefined) {
                throw new RequiredError('networkingVisitorData','Required parameter networkingVisitorData was null or undefined when calling updateNetworkingVisitor.');
            }
            const localVarPath = `/api/meetup/{meetupId}/networking/{visitorId}`
                .replace(`{${"meetupId"}}`, encodeURIComponent(String(meetupId)))
                .replace(`{${"visitorId"}}`, encodeURIComponent(String(visitorId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"NetworkingVisitorData" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(networkingVisitorData !== undefined ? networkingVisitorData : {}) : (networkingVisitorData || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {number} visitorId Visitor identifier.
         * @param {NetworkingWriteStatData} networkingWriteStatData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        writeStat(meetupId: number, visitorId: number, networkingWriteStatData: NetworkingWriteStatData, options: any = {}): RequestArgs {
            // verify required parameter 'meetupId' is not null or undefined
            if (meetupId === null || meetupId === undefined) {
                throw new RequiredError('meetupId','Required parameter meetupId was null or undefined when calling writeStat.');
            }
            // verify required parameter 'visitorId' is not null or undefined
            if (visitorId === null || visitorId === undefined) {
                throw new RequiredError('visitorId','Required parameter visitorId was null or undefined when calling writeStat.');
            }
            // verify required parameter 'networkingWriteStatData' is not null or undefined
            if (networkingWriteStatData === null || networkingWriteStatData === undefined) {
                throw new RequiredError('networkingWriteStatData','Required parameter networkingWriteStatData was null or undefined when calling writeStat.');
            }
            const localVarPath = `/api/meetup/{meetupId}/networking/{visitorId}/stat`
                .replace(`{${"meetupId"}}`, encodeURIComponent(String(meetupId)))
                .replace(`{${"visitorId"}}`, encodeURIComponent(String(visitorId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"NetworkingWriteStatData" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(networkingWriteStatData !== undefined ? networkingWriteStatData : {}) : (networkingWriteStatData || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NetworkingApi - functional programming interface
 * @export
 */
export const NetworkingApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {number} visitorId Visitor identifier.
         * @param {number} [direction] 
         * @param {number} [last] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCandidateForVisitor(meetupId: number, visitorId: number, direction?: number, last?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<NetworkingCarouselData> {
            const localVarAxiosArgs = NetworkingApiAxiosParamCreator(configuration).getCandidateForVisitor(meetupId, visitorId, direction, last, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNetworkingSettings(meetupId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<NetworkingSettingsData> {
            const localVarAxiosArgs = NetworkingApiAxiosParamCreator(configuration).getNetworkingSettings(meetupId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {number} visitorId Visitor identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNetworkingVisitor(meetupId: number, visitorId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<NetworkingVisitorData> {
            const localVarAxiosArgs = NetworkingApiAxiosParamCreator(configuration).getNetworkingVisitor(meetupId, visitorId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {number} visitorId Visitor identifier.
         * @param {number} otherVisitorId Second visitor id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNetworkingVisitorWithStatus(meetupId: number, visitorId: number, otherVisitorId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<NetworkingVisitorData> {
            const localVarAxiosArgs = NetworkingApiAxiosParamCreator(configuration).getNetworkingVisitorWithStatus(meetupId, visitorId, otherVisitorId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listNetworkingVisitors(meetupId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<NetworkingVisitorData>> {
            const localVarAxiosArgs = NetworkingApiAxiosParamCreator(configuration).listNetworkingVisitors(meetupId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {NetworkingContactData} networkingContactData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processContact(meetupId: number, networkingContactData: NetworkingContactData, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<NetworkingContactData> {
            const localVarAxiosArgs = NetworkingApiAxiosParamCreator(configuration).processContact(meetupId, networkingContactData, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {NetworkingSettingsData} networkingSettingsData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNetworkingSettings(meetupId: number, networkingSettingsData: NetworkingSettingsData, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<NetworkingSettingsData> {
            const localVarAxiosArgs = NetworkingApiAxiosParamCreator(configuration).updateNetworkingSettings(meetupId, networkingSettingsData, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {number} visitorId Visitor identifier.
         * @param {NetworkingVisitorData} networkingVisitorData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNetworkingVisitor(meetupId: number, visitorId: number, networkingVisitorData: NetworkingVisitorData, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<NetworkingVisitorData> {
            const localVarAxiosArgs = NetworkingApiAxiosParamCreator(configuration).updateNetworkingVisitor(meetupId, visitorId, networkingVisitorData, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {number} visitorId Visitor identifier.
         * @param {NetworkingWriteStatData} networkingWriteStatData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        writeStat(meetupId: number, visitorId: number, networkingWriteStatData: NetworkingWriteStatData, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<NetworkingMatchData> {
            const localVarAxiosArgs = NetworkingApiAxiosParamCreator(configuration).writeStat(meetupId, visitorId, networkingWriteStatData, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * NetworkingApi - factory interface
 * @export
 */
export const NetworkingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {number} visitorId Visitor identifier.
         * @param {number} [direction] 
         * @param {number} [last] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCandidateForVisitor(meetupId: number, visitorId: number, direction?: number, last?: number, options?: any) {
            return NetworkingApiFp(configuration).getCandidateForVisitor(meetupId, visitorId, direction, last, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNetworkingSettings(meetupId: number, options?: any) {
            return NetworkingApiFp(configuration).getNetworkingSettings(meetupId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {number} visitorId Visitor identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNetworkingVisitor(meetupId: number, visitorId: number, options?: any) {
            return NetworkingApiFp(configuration).getNetworkingVisitor(meetupId, visitorId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {number} visitorId Visitor identifier.
         * @param {number} otherVisitorId Second visitor id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNetworkingVisitorWithStatus(meetupId: number, visitorId: number, otherVisitorId: number, options?: any) {
            return NetworkingApiFp(configuration).getNetworkingVisitorWithStatus(meetupId, visitorId, otherVisitorId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listNetworkingVisitors(meetupId: number, options?: any) {
            return NetworkingApiFp(configuration).listNetworkingVisitors(meetupId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {NetworkingContactData} networkingContactData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processContact(meetupId: number, networkingContactData: NetworkingContactData, options?: any) {
            return NetworkingApiFp(configuration).processContact(meetupId, networkingContactData, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {NetworkingSettingsData} networkingSettingsData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNetworkingSettings(meetupId: number, networkingSettingsData: NetworkingSettingsData, options?: any) {
            return NetworkingApiFp(configuration).updateNetworkingSettings(meetupId, networkingSettingsData, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {number} visitorId Visitor identifier.
         * @param {NetworkingVisitorData} networkingVisitorData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNetworkingVisitor(meetupId: number, visitorId: number, networkingVisitorData: NetworkingVisitorData, options?: any) {
            return NetworkingApiFp(configuration).updateNetworkingVisitor(meetupId, visitorId, networkingVisitorData, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {number} visitorId Visitor identifier.
         * @param {NetworkingWriteStatData} networkingWriteStatData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        writeStat(meetupId: number, visitorId: number, networkingWriteStatData: NetworkingWriteStatData, options?: any) {
            return NetworkingApiFp(configuration).writeStat(meetupId, visitorId, networkingWriteStatData, options)(axios, basePath);
        },
    };
};

/**
 * NetworkingApi - object-oriented interface
 * @export
 * @class NetworkingApi
 * @extends {BaseAPI}
 */
export class NetworkingApi extends BaseAPI {
    /**
     * 
     * @param {number} meetupId Meetup identifier.
     * @param {number} visitorId Visitor identifier.
     * @param {number} [direction] 
     * @param {number} [last] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NetworkingApi
     */
    public getCandidateForVisitor(meetupId: number, visitorId: number, direction?: number, last?: number, options?: any) {
        return NetworkingApiFp(this.configuration).getCandidateForVisitor(meetupId, visitorId, direction, last, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} meetupId Meetup identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NetworkingApi
     */
    public getNetworkingSettings(meetupId: number, options?: any) {
        return NetworkingApiFp(this.configuration).getNetworkingSettings(meetupId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} meetupId Meetup identifier.
     * @param {number} visitorId Visitor identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NetworkingApi
     */
    public getNetworkingVisitor(meetupId: number, visitorId: number, options?: any) {
        return NetworkingApiFp(this.configuration).getNetworkingVisitor(meetupId, visitorId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} meetupId Meetup identifier.
     * @param {number} visitorId Visitor identifier.
     * @param {number} otherVisitorId Second visitor id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NetworkingApi
     */
    public getNetworkingVisitorWithStatus(meetupId: number, visitorId: number, otherVisitorId: number, options?: any) {
        return NetworkingApiFp(this.configuration).getNetworkingVisitorWithStatus(meetupId, visitorId, otherVisitorId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} meetupId Meetup identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NetworkingApi
     */
    public listNetworkingVisitors(meetupId: number, options?: any) {
        return NetworkingApiFp(this.configuration).listNetworkingVisitors(meetupId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} meetupId Meetup identifier.
     * @param {NetworkingContactData} networkingContactData 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NetworkingApi
     */
    public processContact(meetupId: number, networkingContactData: NetworkingContactData, options?: any) {
        return NetworkingApiFp(this.configuration).processContact(meetupId, networkingContactData, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} meetupId Meetup identifier.
     * @param {NetworkingSettingsData} networkingSettingsData 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NetworkingApi
     */
    public updateNetworkingSettings(meetupId: number, networkingSettingsData: NetworkingSettingsData, options?: any) {
        return NetworkingApiFp(this.configuration).updateNetworkingSettings(meetupId, networkingSettingsData, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} meetupId Meetup identifier.
     * @param {number} visitorId Visitor identifier.
     * @param {NetworkingVisitorData} networkingVisitorData 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NetworkingApi
     */
    public updateNetworkingVisitor(meetupId: number, visitorId: number, networkingVisitorData: NetworkingVisitorData, options?: any) {
        return NetworkingApiFp(this.configuration).updateNetworkingVisitor(meetupId, visitorId, networkingVisitorData, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} meetupId Meetup identifier.
     * @param {number} visitorId Visitor identifier.
     * @param {NetworkingWriteStatData} networkingWriteStatData 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NetworkingApi
     */
    public writeStat(meetupId: number, visitorId: number, networkingWriteStatData: NetworkingWriteStatData, options?: any) {
        return NetworkingApiFp(this.configuration).writeStat(meetupId, visitorId, networkingWriteStatData, options)(this.axios, this.basePath);
    }

}


/**
 * NewsApi - axios parameter creator
 * @export
 */
export const NewsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {RecipientsData} recipientsData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkRecipients(meetupId: number, recipientsData: RecipientsData, options: any = {}): RequestArgs {
            // verify required parameter 'meetupId' is not null or undefined
            if (meetupId === null || meetupId === undefined) {
                throw new RequiredError('meetupId','Required parameter meetupId was null or undefined when calling checkRecipients.');
            }
            // verify required parameter 'recipientsData' is not null or undefined
            if (recipientsData === null || recipientsData === undefined) {
                throw new RequiredError('recipientsData','Required parameter recipientsData was null or undefined when calling checkRecipients.');
            }
            const localVarPath = `/api/meetup/{meetupId}/news/check-recipients`
                .replace(`{${"meetupId"}}`, encodeURIComponent(String(meetupId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"RecipientsData" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(recipientsData !== undefined ? recipientsData : {}) : (recipientsData || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {NewsData} newsData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNews(meetupId: number, newsData: NewsData, options: any = {}): RequestArgs {
            // verify required parameter 'meetupId' is not null or undefined
            if (meetupId === null || meetupId === undefined) {
                throw new RequiredError('meetupId','Required parameter meetupId was null or undefined when calling createNews.');
            }
            // verify required parameter 'newsData' is not null or undefined
            if (newsData === null || newsData === undefined) {
                throw new RequiredError('newsData','Required parameter newsData was null or undefined when calling createNews.');
            }
            const localVarPath = `/api/meetup/{meetupId}/news`
                .replace(`{${"meetupId"}}`, encodeURIComponent(String(meetupId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"NewsData" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(newsData !== undefined ? newsData : {}) : (newsData || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {number} newsId News identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteNews(meetupId: number, newsId: number, options: any = {}): RequestArgs {
            // verify required parameter 'meetupId' is not null or undefined
            if (meetupId === null || meetupId === undefined) {
                throw new RequiredError('meetupId','Required parameter meetupId was null or undefined when calling deleteNews.');
            }
            // verify required parameter 'newsId' is not null or undefined
            if (newsId === null || newsId === undefined) {
                throw new RequiredError('newsId','Required parameter newsId was null or undefined when calling deleteNews.');
            }
            const localVarPath = `/api/meetup/{meetupId}/news/{newsId}`
                .replace(`{${"meetupId"}}`, encodeURIComponent(String(meetupId)))
                .replace(`{${"newsId"}}`, encodeURIComponent(String(newsId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {number} newsId News identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNews(meetupId: number, newsId: number, options: any = {}): RequestArgs {
            // verify required parameter 'meetupId' is not null or undefined
            if (meetupId === null || meetupId === undefined) {
                throw new RequiredError('meetupId','Required parameter meetupId was null or undefined when calling getNews.');
            }
            // verify required parameter 'newsId' is not null or undefined
            if (newsId === null || newsId === undefined) {
                throw new RequiredError('newsId','Required parameter newsId was null or undefined when calling getNews.');
            }
            const localVarPath = `/api/meetup/{meetupId}/news/{newsId}`
                .replace(`{${"meetupId"}}`, encodeURIComponent(String(meetupId)))
                .replace(`{${"newsId"}}`, encodeURIComponent(String(newsId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {number} newsId News identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPoolResults(meetupId: number, newsId: number, options: any = {}): RequestArgs {
            // verify required parameter 'meetupId' is not null or undefined
            if (meetupId === null || meetupId === undefined) {
                throw new RequiredError('meetupId','Required parameter meetupId was null or undefined when calling getPoolResults.');
            }
            // verify required parameter 'newsId' is not null or undefined
            if (newsId === null || newsId === undefined) {
                throw new RequiredError('newsId','Required parameter newsId was null or undefined when calling getPoolResults.');
            }
            const localVarPath = `/api/meetup/{meetupId}/news/{newsId}/pool-results`
                .replace(`{${"meetupId"}}`, encodeURIComponent(String(meetupId)))
                .replace(`{${"newsId"}}`, encodeURIComponent(String(newsId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listNews(meetupId: number, options: any = {}): RequestArgs {
            // verify required parameter 'meetupId' is not null or undefined
            if (meetupId === null || meetupId === undefined) {
                throw new RequiredError('meetupId','Required parameter meetupId was null or undefined when calling listNews.');
            }
            const localVarPath = `/api/meetup/{meetupId}/news`
                .replace(`{${"meetupId"}}`, encodeURIComponent(String(meetupId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {number} newsId News identifier.
         * @param {NewsSendingStatusData} newsSendingStatusData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pushSendingStatus(meetupId: number, newsId: number, newsSendingStatusData: NewsSendingStatusData, options: any = {}): RequestArgs {
            // verify required parameter 'meetupId' is not null or undefined
            if (meetupId === null || meetupId === undefined) {
                throw new RequiredError('meetupId','Required parameter meetupId was null or undefined when calling pushSendingStatus.');
            }
            // verify required parameter 'newsId' is not null or undefined
            if (newsId === null || newsId === undefined) {
                throw new RequiredError('newsId','Required parameter newsId was null or undefined when calling pushSendingStatus.');
            }
            // verify required parameter 'newsSendingStatusData' is not null or undefined
            if (newsSendingStatusData === null || newsSendingStatusData === undefined) {
                throw new RequiredError('newsSendingStatusData','Required parameter newsSendingStatusData was null or undefined when calling pushSendingStatus.');
            }
            const localVarPath = `/api/meetup/{meetupId}/news/{newsId}/sending-status`
                .replace(`{${"meetupId"}}`, encodeURIComponent(String(meetupId)))
                .replace(`{${"newsId"}}`, encodeURIComponent(String(newsId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"NewsSendingStatusData" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(newsSendingStatusData !== undefined ? newsSendingStatusData : {}) : (newsSendingStatusData || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {number} newsId News identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendNewsNow(meetupId: number, newsId: number, options: any = {}): RequestArgs {
            // verify required parameter 'meetupId' is not null or undefined
            if (meetupId === null || meetupId === undefined) {
                throw new RequiredError('meetupId','Required parameter meetupId was null or undefined when calling sendNewsNow.');
            }
            // verify required parameter 'newsId' is not null or undefined
            if (newsId === null || newsId === undefined) {
                throw new RequiredError('newsId','Required parameter newsId was null or undefined when calling sendNewsNow.');
            }
            const localVarPath = `/api/meetup/{meetupId}/news/{newsId}/send-now`
                .replace(`{${"meetupId"}}`, encodeURIComponent(String(meetupId)))
                .replace(`{${"newsId"}}`, encodeURIComponent(String(newsId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {number} newsId News identifier.
         * @param {NewsData} newsData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNews(meetupId: number, newsId: number, newsData: NewsData, options: any = {}): RequestArgs {
            // verify required parameter 'meetupId' is not null or undefined
            if (meetupId === null || meetupId === undefined) {
                throw new RequiredError('meetupId','Required parameter meetupId was null or undefined when calling updateNews.');
            }
            // verify required parameter 'newsId' is not null or undefined
            if (newsId === null || newsId === undefined) {
                throw new RequiredError('newsId','Required parameter newsId was null or undefined when calling updateNews.');
            }
            // verify required parameter 'newsData' is not null or undefined
            if (newsData === null || newsData === undefined) {
                throw new RequiredError('newsData','Required parameter newsData was null or undefined when calling updateNews.');
            }
            const localVarPath = `/api/meetup/{meetupId}/news/{newsId}`
                .replace(`{${"meetupId"}}`, encodeURIComponent(String(meetupId)))
                .replace(`{${"newsId"}}`, encodeURIComponent(String(newsId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"NewsData" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(newsData !== undefined ? newsData : {}) : (newsData || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NewsApi - functional programming interface
 * @export
 */
export const NewsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {RecipientsData} recipientsData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkRecipients(meetupId: number, recipientsData: RecipientsData, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<RecipientsData> {
            const localVarAxiosArgs = NewsApiAxiosParamCreator(configuration).checkRecipients(meetupId, recipientsData, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {NewsData} newsData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNews(meetupId: number, newsData: NewsData, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<NewsData> {
            const localVarAxiosArgs = NewsApiAxiosParamCreator(configuration).createNews(meetupId, newsData, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {number} newsId News identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteNews(meetupId: number, newsId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = NewsApiAxiosParamCreator(configuration).deleteNews(meetupId, newsId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {number} newsId News identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNews(meetupId: number, newsId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<NewsData> {
            const localVarAxiosArgs = NewsApiAxiosParamCreator(configuration).getNews(meetupId, newsId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {number} newsId News identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPoolResults(meetupId: number, newsId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PoolResultsData> {
            const localVarAxiosArgs = NewsApiAxiosParamCreator(configuration).getPoolResults(meetupId, newsId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listNews(meetupId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<NewsData>> {
            const localVarAxiosArgs = NewsApiAxiosParamCreator(configuration).listNews(meetupId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {number} newsId News identifier.
         * @param {NewsSendingStatusData} newsSendingStatusData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pushSendingStatus(meetupId: number, newsId: number, newsSendingStatusData: NewsSendingStatusData, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = NewsApiAxiosParamCreator(configuration).pushSendingStatus(meetupId, newsId, newsSendingStatusData, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {number} newsId News identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendNewsNow(meetupId: number, newsId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = NewsApiAxiosParamCreator(configuration).sendNewsNow(meetupId, newsId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {number} newsId News identifier.
         * @param {NewsData} newsData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNews(meetupId: number, newsId: number, newsData: NewsData, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<NewsData> {
            const localVarAxiosArgs = NewsApiAxiosParamCreator(configuration).updateNews(meetupId, newsId, newsData, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * NewsApi - factory interface
 * @export
 */
export const NewsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {RecipientsData} recipientsData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkRecipients(meetupId: number, recipientsData: RecipientsData, options?: any) {
            return NewsApiFp(configuration).checkRecipients(meetupId, recipientsData, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {NewsData} newsData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNews(meetupId: number, newsData: NewsData, options?: any) {
            return NewsApiFp(configuration).createNews(meetupId, newsData, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {number} newsId News identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteNews(meetupId: number, newsId: number, options?: any) {
            return NewsApiFp(configuration).deleteNews(meetupId, newsId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {number} newsId News identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNews(meetupId: number, newsId: number, options?: any) {
            return NewsApiFp(configuration).getNews(meetupId, newsId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {number} newsId News identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPoolResults(meetupId: number, newsId: number, options?: any) {
            return NewsApiFp(configuration).getPoolResults(meetupId, newsId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listNews(meetupId: number, options?: any) {
            return NewsApiFp(configuration).listNews(meetupId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {number} newsId News identifier.
         * @param {NewsSendingStatusData} newsSendingStatusData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pushSendingStatus(meetupId: number, newsId: number, newsSendingStatusData: NewsSendingStatusData, options?: any) {
            return NewsApiFp(configuration).pushSendingStatus(meetupId, newsId, newsSendingStatusData, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {number} newsId News identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendNewsNow(meetupId: number, newsId: number, options?: any) {
            return NewsApiFp(configuration).sendNewsNow(meetupId, newsId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {number} newsId News identifier.
         * @param {NewsData} newsData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNews(meetupId: number, newsId: number, newsData: NewsData, options?: any) {
            return NewsApiFp(configuration).updateNews(meetupId, newsId, newsData, options)(axios, basePath);
        },
    };
};

/**
 * NewsApi - object-oriented interface
 * @export
 * @class NewsApi
 * @extends {BaseAPI}
 */
export class NewsApi extends BaseAPI {
    /**
     * 
     * @param {number} meetupId Meetup identifier.
     * @param {RecipientsData} recipientsData 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsApi
     */
    public checkRecipients(meetupId: number, recipientsData: RecipientsData, options?: any) {
        return NewsApiFp(this.configuration).checkRecipients(meetupId, recipientsData, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} meetupId Meetup identifier.
     * @param {NewsData} newsData 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsApi
     */
    public createNews(meetupId: number, newsData: NewsData, options?: any) {
        return NewsApiFp(this.configuration).createNews(meetupId, newsData, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} meetupId Meetup identifier.
     * @param {number} newsId News identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsApi
     */
    public deleteNews(meetupId: number, newsId: number, options?: any) {
        return NewsApiFp(this.configuration).deleteNews(meetupId, newsId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} meetupId Meetup identifier.
     * @param {number} newsId News identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsApi
     */
    public getNews(meetupId: number, newsId: number, options?: any) {
        return NewsApiFp(this.configuration).getNews(meetupId, newsId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} meetupId Meetup identifier.
     * @param {number} newsId News identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsApi
     */
    public getPoolResults(meetupId: number, newsId: number, options?: any) {
        return NewsApiFp(this.configuration).getPoolResults(meetupId, newsId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} meetupId Meetup identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsApi
     */
    public listNews(meetupId: number, options?: any) {
        return NewsApiFp(this.configuration).listNews(meetupId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} meetupId Meetup identifier.
     * @param {number} newsId News identifier.
     * @param {NewsSendingStatusData} newsSendingStatusData 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsApi
     */
    public pushSendingStatus(meetupId: number, newsId: number, newsSendingStatusData: NewsSendingStatusData, options?: any) {
        return NewsApiFp(this.configuration).pushSendingStatus(meetupId, newsId, newsSendingStatusData, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} meetupId Meetup identifier.
     * @param {number} newsId News identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsApi
     */
    public sendNewsNow(meetupId: number, newsId: number, options?: any) {
        return NewsApiFp(this.configuration).sendNewsNow(meetupId, newsId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} meetupId Meetup identifier.
     * @param {number} newsId News identifier.
     * @param {NewsData} newsData 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsApi
     */
    public updateNews(meetupId: number, newsId: number, newsData: NewsData, options?: any) {
        return NewsApiFp(this.configuration).updateNews(meetupId, newsId, newsData, options)(this.axios, this.basePath);
    }

}


/**
 * NotificationApi - axios parameter creator
 * @export
 */
export const NotificationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [timestamp] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteNotifications(timestamp?: string, options: any = {}): RequestArgs {
            const localVarPath = `/api/notification`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (timestamp !== undefined) {
                localVarQueryParameter['timestamp'] = timestamp;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [timestamp] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listNotifications(timestamp?: string, options: any = {}): RequestArgs {
            const localVarPath = `/api/notification`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (timestamp !== undefined) {
                localVarQueryParameter['timestamp'] = timestamp;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NotificationApi - functional programming interface
 * @export
 */
export const NotificationApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [timestamp] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteNotifications(timestamp?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = NotificationApiAxiosParamCreator(configuration).deleteNotifications(timestamp, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [timestamp] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listNotifications(timestamp?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<NotificationData>> {
            const localVarAxiosArgs = NotificationApiAxiosParamCreator(configuration).listNotifications(timestamp, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * NotificationApi - factory interface
 * @export
 */
export const NotificationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {string} [timestamp] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteNotifications(timestamp?: string, options?: any) {
            return NotificationApiFp(configuration).deleteNotifications(timestamp, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} [timestamp] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listNotifications(timestamp?: string, options?: any) {
            return NotificationApiFp(configuration).listNotifications(timestamp, options)(axios, basePath);
        },
    };
};

/**
 * NotificationApi - object-oriented interface
 * @export
 * @class NotificationApi
 * @extends {BaseAPI}
 */
export class NotificationApi extends BaseAPI {
    /**
     * 
     * @param {string} [timestamp] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationApi
     */
    public deleteNotifications(timestamp?: string, options?: any) {
        return NotificationApiFp(this.configuration).deleteNotifications(timestamp, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} [timestamp] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationApi
     */
    public listNotifications(timestamp?: string, options?: any) {
        return NotificationApiFp(this.configuration).listNotifications(timestamp, options)(this.axios, this.basePath);
    }

}


/**
 * ProductReportApi - axios parameter creator
 * @export
 */
export const ProductReportApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ActiveClientsRequestData} activeClientsRequestData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActiveClientsReport(activeClientsRequestData: ActiveClientsRequestData, options: any = {}): RequestArgs {
            // verify required parameter 'activeClientsRequestData' is not null or undefined
            if (activeClientsRequestData === null || activeClientsRequestData === undefined) {
                throw new RequiredError('activeClientsRequestData','Required parameter activeClientsRequestData was null or undefined when calling getActiveClientsReport.');
            }
            const localVarPath = `/api/report/clients/active-clients`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"ActiveClientsRequestData" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(activeClientsRequestData !== undefined ? activeClientsRequestData : {}) : (activeClientsRequestData || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DateReportRequestData} dateReportRequestData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActiveDeskQuantityReport(dateReportRequestData: DateReportRequestData, options: any = {}): RequestArgs {
            // verify required parameter 'dateReportRequestData' is not null or undefined
            if (dateReportRequestData === null || dateReportRequestData === undefined) {
                throw new RequiredError('dateReportRequestData','Required parameter dateReportRequestData was null or undefined when calling getActiveDeskQuantityReport.');
            }
            const localVarPath = `/api/report/desks`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"DateReportRequestData" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(dateReportRequestData !== undefined ? dateReportRequestData : {}) : (dateReportRequestData || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DateReportRequestData} dateReportRequestData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChannelsReport(dateReportRequestData: DateReportRequestData, options: any = {}): RequestArgs {
            // verify required parameter 'dateReportRequestData' is not null or undefined
            if (dateReportRequestData === null || dateReportRequestData === undefined) {
                throw new RequiredError('dateReportRequestData','Required parameter dateReportRequestData was null or undefined when calling getChannelsReport.');
            }
            const localVarPath = `/api/report/channels`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"DateReportRequestData" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(dateReportRequestData !== undefined ? dateReportRequestData : {}) : (dateReportRequestData || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DateReportRequestData} dateReportRequestData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaymentsReport(dateReportRequestData: DateReportRequestData, options: any = {}): RequestArgs {
            // verify required parameter 'dateReportRequestData' is not null or undefined
            if (dateReportRequestData === null || dateReportRequestData === undefined) {
                throw new RequiredError('dateReportRequestData','Required parameter dateReportRequestData was null or undefined when calling getPaymentsReport.');
            }
            const localVarPath = `/api/report/payments`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"DateReportRequestData" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(dateReportRequestData !== undefined ? dateReportRequestData : {}) : (dateReportRequestData || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DateReportRequestData} dateReportRequestData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRegistrationBySourceReport(dateReportRequestData: DateReportRequestData, options: any = {}): RequestArgs {
            // verify required parameter 'dateReportRequestData' is not null or undefined
            if (dateReportRequestData === null || dateReportRequestData === undefined) {
                throw new RequiredError('dateReportRequestData','Required parameter dateReportRequestData was null or undefined when calling getRegistrationBySourceReport.');
            }
            const localVarPath = `/api/report/registration-by-source`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"DateReportRequestData" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(dateReportRequestData !== undefined ? dateReportRequestData : {}) : (dateReportRequestData || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DateReportRequestData} dateReportRequestData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRegistrationsReport(dateReportRequestData: DateReportRequestData, options: any = {}): RequestArgs {
            // verify required parameter 'dateReportRequestData' is not null or undefined
            if (dateReportRequestData === null || dateReportRequestData === undefined) {
                throw new RequiredError('dateReportRequestData','Required parameter dateReportRequestData was null or undefined when calling getRegistrationsReport.');
            }
            const localVarPath = `/api/report/clients/registrations`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"DateReportRequestData" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(dateReportRequestData !== undefined ? dateReportRequestData : {}) : (dateReportRequestData || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProductReportApi - functional programming interface
 * @export
 */
export const ProductReportApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ActiveClientsRequestData} activeClientsRequestData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActiveClientsReport(activeClientsRequestData: ActiveClientsRequestData, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActiveClientsReportData> {
            const localVarAxiosArgs = ProductReportApiAxiosParamCreator(configuration).getActiveClientsReport(activeClientsRequestData, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {DateReportRequestData} dateReportRequestData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActiveDeskQuantityReport(dateReportRequestData: DateReportRequestData, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActiveDesksByClientsData> {
            const localVarAxiosArgs = ProductReportApiAxiosParamCreator(configuration).getActiveDeskQuantityReport(dateReportRequestData, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {DateReportRequestData} dateReportRequestData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChannelsReport(dateReportRequestData: DateReportRequestData, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChannelsReportData> {
            const localVarAxiosArgs = ProductReportApiAxiosParamCreator(configuration).getChannelsReport(dateReportRequestData, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {DateReportRequestData} dateReportRequestData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaymentsReport(dateReportRequestData: DateReportRequestData, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentsReportData> {
            const localVarAxiosArgs = ProductReportApiAxiosParamCreator(configuration).getPaymentsReport(dateReportRequestData, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {DateReportRequestData} dateReportRequestData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRegistrationBySourceReport(dateReportRequestData: DateReportRequestData, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<RegistrationBySourceReportData> {
            const localVarAxiosArgs = ProductReportApiAxiosParamCreator(configuration).getRegistrationBySourceReport(dateReportRequestData, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {DateReportRequestData} dateReportRequestData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRegistrationsReport(dateReportRequestData: DateReportRequestData, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<RegistrationProductReportData> {
            const localVarAxiosArgs = ProductReportApiAxiosParamCreator(configuration).getRegistrationsReport(dateReportRequestData, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ProductReportApi - factory interface
 * @export
 */
export const ProductReportApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {ActiveClientsRequestData} activeClientsRequestData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActiveClientsReport(activeClientsRequestData: ActiveClientsRequestData, options?: any) {
            return ProductReportApiFp(configuration).getActiveClientsReport(activeClientsRequestData, options)(axios, basePath);
        },
        /**
         * 
         * @param {DateReportRequestData} dateReportRequestData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActiveDeskQuantityReport(dateReportRequestData: DateReportRequestData, options?: any) {
            return ProductReportApiFp(configuration).getActiveDeskQuantityReport(dateReportRequestData, options)(axios, basePath);
        },
        /**
         * 
         * @param {DateReportRequestData} dateReportRequestData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChannelsReport(dateReportRequestData: DateReportRequestData, options?: any) {
            return ProductReportApiFp(configuration).getChannelsReport(dateReportRequestData, options)(axios, basePath);
        },
        /**
         * 
         * @param {DateReportRequestData} dateReportRequestData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaymentsReport(dateReportRequestData: DateReportRequestData, options?: any) {
            return ProductReportApiFp(configuration).getPaymentsReport(dateReportRequestData, options)(axios, basePath);
        },
        /**
         * 
         * @param {DateReportRequestData} dateReportRequestData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRegistrationBySourceReport(dateReportRequestData: DateReportRequestData, options?: any) {
            return ProductReportApiFp(configuration).getRegistrationBySourceReport(dateReportRequestData, options)(axios, basePath);
        },
        /**
         * 
         * @param {DateReportRequestData} dateReportRequestData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRegistrationsReport(dateReportRequestData: DateReportRequestData, options?: any) {
            return ProductReportApiFp(configuration).getRegistrationsReport(dateReportRequestData, options)(axios, basePath);
        },
    };
};

/**
 * ProductReportApi - object-oriented interface
 * @export
 * @class ProductReportApi
 * @extends {BaseAPI}
 */
export class ProductReportApi extends BaseAPI {
    /**
     * 
     * @param {ActiveClientsRequestData} activeClientsRequestData 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductReportApi
     */
    public getActiveClientsReport(activeClientsRequestData: ActiveClientsRequestData, options?: any) {
        return ProductReportApiFp(this.configuration).getActiveClientsReport(activeClientsRequestData, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {DateReportRequestData} dateReportRequestData 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductReportApi
     */
    public getActiveDeskQuantityReport(dateReportRequestData: DateReportRequestData, options?: any) {
        return ProductReportApiFp(this.configuration).getActiveDeskQuantityReport(dateReportRequestData, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {DateReportRequestData} dateReportRequestData 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductReportApi
     */
    public getChannelsReport(dateReportRequestData: DateReportRequestData, options?: any) {
        return ProductReportApiFp(this.configuration).getChannelsReport(dateReportRequestData, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {DateReportRequestData} dateReportRequestData 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductReportApi
     */
    public getPaymentsReport(dateReportRequestData: DateReportRequestData, options?: any) {
        return ProductReportApiFp(this.configuration).getPaymentsReport(dateReportRequestData, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {DateReportRequestData} dateReportRequestData 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductReportApi
     */
    public getRegistrationBySourceReport(dateReportRequestData: DateReportRequestData, options?: any) {
        return ProductReportApiFp(this.configuration).getRegistrationBySourceReport(dateReportRequestData, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {DateReportRequestData} dateReportRequestData 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductReportApi
     */
    public getRegistrationsReport(dateReportRequestData: DateReportRequestData, options?: any) {
        return ProductReportApiFp(this.configuration).getRegistrationsReport(dateReportRequestData, options)(this.axios, this.basePath);
    }

}


/**
 * ProfileApi - axios parameter creator
 * @export
 */
export const ProfileApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get current user personal and account data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProfileData(options: any = {}): RequestArgs {
            const localVarPath = `/api/profile`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProfileApi - functional programming interface
 * @export
 */
export const ProfileApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get current user personal and account data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProfileData(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProfileData> {
            const localVarAxiosArgs = ProfileApiAxiosParamCreator(configuration).getProfileData(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ProfileApi - factory interface
 * @export
 */
export const ProfileApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Get current user personal and account data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProfileData(options?: any) {
            return ProfileApiFp(configuration).getProfileData(options)(axios, basePath);
        },
    };
};

/**
 * ProfileApi - object-oriented interface
 * @export
 * @class ProfileApi
 * @extends {BaseAPI}
 */
export class ProfileApi extends BaseAPI {
    /**
     * 
     * @summary Get current user personal and account data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public getProfileData(options?: any) {
        return ProfileApiFp(this.configuration).getProfileData(options)(this.axios, this.basePath);
    }

}


/**
 * ReceptionApi - axios parameter creator
 * @export
 */
export const ReceptionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {DeskData} deskData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDesk(meetupId: number, deskData: DeskData, options: any = {}): RequestArgs {
            // verify required parameter 'meetupId' is not null or undefined
            if (meetupId === null || meetupId === undefined) {
                throw new RequiredError('meetupId','Required parameter meetupId was null or undefined when calling createDesk.');
            }
            // verify required parameter 'deskData' is not null or undefined
            if (deskData === null || deskData === undefined) {
                throw new RequiredError('deskData','Required parameter deskData was null or undefined when calling createDesk.');
            }
            const localVarPath = `/api/meetup/{meetupId}/desk`
                .replace(`{${"meetupId"}}`, encodeURIComponent(String(meetupId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"DeskData" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(deskData !== undefined ? deskData : {}) : (deskData || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {number} deskId Desk identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDesk(meetupId: number, deskId: number, options: any = {}): RequestArgs {
            // verify required parameter 'meetupId' is not null or undefined
            if (meetupId === null || meetupId === undefined) {
                throw new RequiredError('meetupId','Required parameter meetupId was null or undefined when calling deleteDesk.');
            }
            // verify required parameter 'deskId' is not null or undefined
            if (deskId === null || deskId === undefined) {
                throw new RequiredError('deskId','Required parameter deskId was null or undefined when calling deleteDesk.');
            }
            const localVarPath = `/api/meetup/{meetupId}/desk/{deskId}`
                .replace(`{${"meetupId"}}`, encodeURIComponent(String(meetupId)))
                .replace(`{${"deskId"}}`, encodeURIComponent(String(deskId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {number} deskId Desk identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDesk(meetupId: number, deskId: number, options: any = {}): RequestArgs {
            // verify required parameter 'meetupId' is not null or undefined
            if (meetupId === null || meetupId === undefined) {
                throw new RequiredError('meetupId','Required parameter meetupId was null or undefined when calling getDesk.');
            }
            // verify required parameter 'deskId' is not null or undefined
            if (deskId === null || deskId === undefined) {
                throw new RequiredError('deskId','Required parameter deskId was null or undefined when calling getDesk.');
            }
            const localVarPath = `/api/meetup/{meetupId}/desk/{deskId}`
                .replace(`{${"meetupId"}}`, encodeURIComponent(String(meetupId)))
                .replace(`{${"deskId"}}`, encodeURIComponent(String(deskId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDeskLogs(meetupId: number, options: any = {}): RequestArgs {
            // verify required parameter 'meetupId' is not null or undefined
            if (meetupId === null || meetupId === undefined) {
                throw new RequiredError('meetupId','Required parameter meetupId was null or undefined when calling listDeskLogs.');
            }
            const localVarPath = `/api/meetup/{meetupId}/desk/logs`
                .replace(`{${"meetupId"}}`, encodeURIComponent(String(meetupId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDesks(meetupId: number, options: any = {}): RequestArgs {
            // verify required parameter 'meetupId' is not null or undefined
            if (meetupId === null || meetupId === undefined) {
                throw new RequiredError('meetupId','Required parameter meetupId was null or undefined when calling listDesks.');
            }
            const localVarPath = `/api/meetup/{meetupId}/desk`
                .replace(`{${"meetupId"}}`, encodeURIComponent(String(meetupId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        receptionInfo(options: any = {}): RequestArgs {
            const localVarPath = `/api/reception`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {LoginRequestData} loginRequestData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        receptionLogin(loginRequestData: LoginRequestData, options: any = {}): RequestArgs {
            // verify required parameter 'loginRequestData' is not null or undefined
            if (loginRequestData === null || loginRequestData === undefined) {
                throw new RequiredError('loginRequestData','Required parameter loginRequestData was null or undefined when calling receptionLogin.');
            }
            const localVarPath = `/api/reception/login`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"LoginRequestData" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(loginRequestData !== undefined ? loginRequestData : {}) : (loginRequestData || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {number} deskId Desk identifier.
         * @param {DeskData} deskData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDesk(meetupId: number, deskId: number, deskData: DeskData, options: any = {}): RequestArgs {
            // verify required parameter 'meetupId' is not null or undefined
            if (meetupId === null || meetupId === undefined) {
                throw new RequiredError('meetupId','Required parameter meetupId was null or undefined when calling updateDesk.');
            }
            // verify required parameter 'deskId' is not null or undefined
            if (deskId === null || deskId === undefined) {
                throw new RequiredError('deskId','Required parameter deskId was null or undefined when calling updateDesk.');
            }
            // verify required parameter 'deskData' is not null or undefined
            if (deskData === null || deskData === undefined) {
                throw new RequiredError('deskData','Required parameter deskData was null or undefined when calling updateDesk.');
            }
            const localVarPath = `/api/meetup/{meetupId}/desk/{deskId}`
                .replace(`{${"meetupId"}}`, encodeURIComponent(String(meetupId)))
                .replace(`{${"deskId"}}`, encodeURIComponent(String(deskId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"DeskData" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(deskData !== undefined ? deskData : {}) : (deskData || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {number} deskId Desk identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDeskPin(meetupId: number, deskId: number, options: any = {}): RequestArgs {
            // verify required parameter 'meetupId' is not null or undefined
            if (meetupId === null || meetupId === undefined) {
                throw new RequiredError('meetupId','Required parameter meetupId was null or undefined when calling updateDeskPin.');
            }
            // verify required parameter 'deskId' is not null or undefined
            if (deskId === null || deskId === undefined) {
                throw new RequiredError('deskId','Required parameter deskId was null or undefined when calling updateDeskPin.');
            }
            const localVarPath = `/api/meetup/{meetupId}/desk/{deskId}/updatePin`
                .replace(`{${"meetupId"}}`, encodeURIComponent(String(meetupId)))
                .replace(`{${"deskId"}}`, encodeURIComponent(String(deskId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDesksPins(meetupId: number, options: any = {}): RequestArgs {
            // verify required parameter 'meetupId' is not null or undefined
            if (meetupId === null || meetupId === undefined) {
                throw new RequiredError('meetupId','Required parameter meetupId was null or undefined when calling updateDesksPins.');
            }
            const localVarPath = `/api/meetup/{meetupId}/desk/updatePins`
                .replace(`{${"meetupId"}}`, encodeURIComponent(String(meetupId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReceptionApi - functional programming interface
 * @export
 */
export const ReceptionApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {DeskData} deskData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDesk(meetupId: number, deskData: DeskData, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeskData> {
            const localVarAxiosArgs = ReceptionApiAxiosParamCreator(configuration).createDesk(meetupId, deskData, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {number} deskId Desk identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDesk(meetupId: number, deskId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = ReceptionApiAxiosParamCreator(configuration).deleteDesk(meetupId, deskId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {number} deskId Desk identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDesk(meetupId: number, deskId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeskData> {
            const localVarAxiosArgs = ReceptionApiAxiosParamCreator(configuration).getDesk(meetupId, deskId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDeskLogs(meetupId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DeskLogData>> {
            const localVarAxiosArgs = ReceptionApiAxiosParamCreator(configuration).listDeskLogs(meetupId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDesks(meetupId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DeskData>> {
            const localVarAxiosArgs = ReceptionApiAxiosParamCreator(configuration).listDesks(meetupId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        receptionInfo(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReceptionData> {
            const localVarAxiosArgs = ReceptionApiAxiosParamCreator(configuration).receptionInfo(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {LoginRequestData} loginRequestData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        receptionLogin(loginRequestData: LoginRequestData, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SessionInfoData> {
            const localVarAxiosArgs = ReceptionApiAxiosParamCreator(configuration).receptionLogin(loginRequestData, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {number} deskId Desk identifier.
         * @param {DeskData} deskData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDesk(meetupId: number, deskId: number, deskData: DeskData, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeskData> {
            const localVarAxiosArgs = ReceptionApiAxiosParamCreator(configuration).updateDesk(meetupId, deskId, deskData, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {number} deskId Desk identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDeskPin(meetupId: number, deskId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeskData> {
            const localVarAxiosArgs = ReceptionApiAxiosParamCreator(configuration).updateDeskPin(meetupId, deskId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDesksPins(meetupId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DeskData>> {
            const localVarAxiosArgs = ReceptionApiAxiosParamCreator(configuration).updateDesksPins(meetupId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ReceptionApi - factory interface
 * @export
 */
export const ReceptionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {DeskData} deskData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDesk(meetupId: number, deskData: DeskData, options?: any) {
            return ReceptionApiFp(configuration).createDesk(meetupId, deskData, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {number} deskId Desk identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDesk(meetupId: number, deskId: number, options?: any) {
            return ReceptionApiFp(configuration).deleteDesk(meetupId, deskId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {number} deskId Desk identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDesk(meetupId: number, deskId: number, options?: any) {
            return ReceptionApiFp(configuration).getDesk(meetupId, deskId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDeskLogs(meetupId: number, options?: any) {
            return ReceptionApiFp(configuration).listDeskLogs(meetupId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDesks(meetupId: number, options?: any) {
            return ReceptionApiFp(configuration).listDesks(meetupId, options)(axios, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        receptionInfo(options?: any) {
            return ReceptionApiFp(configuration).receptionInfo(options)(axios, basePath);
        },
        /**
         * 
         * @param {LoginRequestData} loginRequestData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        receptionLogin(loginRequestData: LoginRequestData, options?: any) {
            return ReceptionApiFp(configuration).receptionLogin(loginRequestData, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {number} deskId Desk identifier.
         * @param {DeskData} deskData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDesk(meetupId: number, deskId: number, deskData: DeskData, options?: any) {
            return ReceptionApiFp(configuration).updateDesk(meetupId, deskId, deskData, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {number} deskId Desk identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDeskPin(meetupId: number, deskId: number, options?: any) {
            return ReceptionApiFp(configuration).updateDeskPin(meetupId, deskId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDesksPins(meetupId: number, options?: any) {
            return ReceptionApiFp(configuration).updateDesksPins(meetupId, options)(axios, basePath);
        },
    };
};

/**
 * ReceptionApi - object-oriented interface
 * @export
 * @class ReceptionApi
 * @extends {BaseAPI}
 */
export class ReceptionApi extends BaseAPI {
    /**
     * 
     * @param {number} meetupId Meetup identifier.
     * @param {DeskData} deskData 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReceptionApi
     */
    public createDesk(meetupId: number, deskData: DeskData, options?: any) {
        return ReceptionApiFp(this.configuration).createDesk(meetupId, deskData, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} meetupId Meetup identifier.
     * @param {number} deskId Desk identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReceptionApi
     */
    public deleteDesk(meetupId: number, deskId: number, options?: any) {
        return ReceptionApiFp(this.configuration).deleteDesk(meetupId, deskId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} meetupId Meetup identifier.
     * @param {number} deskId Desk identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReceptionApi
     */
    public getDesk(meetupId: number, deskId: number, options?: any) {
        return ReceptionApiFp(this.configuration).getDesk(meetupId, deskId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} meetupId Meetup identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReceptionApi
     */
    public listDeskLogs(meetupId: number, options?: any) {
        return ReceptionApiFp(this.configuration).listDeskLogs(meetupId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} meetupId Meetup identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReceptionApi
     */
    public listDesks(meetupId: number, options?: any) {
        return ReceptionApiFp(this.configuration).listDesks(meetupId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReceptionApi
     */
    public receptionInfo(options?: any) {
        return ReceptionApiFp(this.configuration).receptionInfo(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {LoginRequestData} loginRequestData 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReceptionApi
     */
    public receptionLogin(loginRequestData: LoginRequestData, options?: any) {
        return ReceptionApiFp(this.configuration).receptionLogin(loginRequestData, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} meetupId Meetup identifier.
     * @param {number} deskId Desk identifier.
     * @param {DeskData} deskData 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReceptionApi
     */
    public updateDesk(meetupId: number, deskId: number, deskData: DeskData, options?: any) {
        return ReceptionApiFp(this.configuration).updateDesk(meetupId, deskId, deskData, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} meetupId Meetup identifier.
     * @param {number} deskId Desk identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReceptionApi
     */
    public updateDeskPin(meetupId: number, deskId: number, options?: any) {
        return ReceptionApiFp(this.configuration).updateDeskPin(meetupId, deskId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} meetupId Meetup identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReceptionApi
     */
    public updateDesksPins(meetupId: number, options?: any) {
        return ReceptionApiFp(this.configuration).updateDesksPins(meetupId, options)(this.axios, this.basePath);
    }

}


/**
 * RegFormApi - axios parameter creator
 * @export
 */
export const RegFormApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} token Access token.
         * @param {string} visitorToken Visitor token.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelVisitorByToken(token: string, visitorToken: string, options: any = {}): RequestArgs {
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError('token','Required parameter token was null or undefined when calling cancelVisitorByToken.');
            }
            // verify required parameter 'visitorToken' is not null or undefined
            if (visitorToken === null || visitorToken === undefined) {
                throw new RequiredError('visitorToken','Required parameter visitorToken was null or undefined when calling cancelVisitorByToken.');
            }
            const localVarPath = `/api/form/{token}/{visitorToken}`
                .replace(`{${"token"}}`, encodeURIComponent(String(token)))
                .replace(`{${"visitorToken"}}`, encodeURIComponent(String(visitorToken)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} token Access token.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getForm(token: string, options: any = {}): RequestArgs {
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError('token','Required parameter token was null or undefined when calling getForm.');
            }
            const localVarPath = `/api/form/{token}`
                .replace(`{${"token"}}`, encodeURIComponent(String(token)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} token Access token.
         * @param {string} visitorToken Visitor token.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVisitorByToken(token: string, visitorToken: string, options: any = {}): RequestArgs {
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError('token','Required parameter token was null or undefined when calling getVisitorByToken.');
            }
            // verify required parameter 'visitorToken' is not null or undefined
            if (visitorToken === null || visitorToken === undefined) {
                throw new RequiredError('visitorToken','Required parameter visitorToken was null or undefined when calling getVisitorByToken.');
            }
            const localVarPath = `/api/form/{token}/{visitorToken}`
                .replace(`{${"token"}}`, encodeURIComponent(String(token)))
                .replace(`{${"visitorToken"}}`, encodeURIComponent(String(visitorToken)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} token Access token.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendForm(token: string, options: any = {}): RequestArgs {
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError('token','Required parameter token was null or undefined when calling sendForm.');
            }
            const localVarPath = `/api/form/{token}`
                .replace(`{${"token"}}`, encodeURIComponent(String(token)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} token Access token.
         * @param {string} visitorToken Visitor token.
         * @param {VisitorData} visitorData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateVisitorByToken(token: string, visitorToken: string, visitorData: VisitorData, options: any = {}): RequestArgs {
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError('token','Required parameter token was null or undefined when calling updateVisitorByToken.');
            }
            // verify required parameter 'visitorToken' is not null or undefined
            if (visitorToken === null || visitorToken === undefined) {
                throw new RequiredError('visitorToken','Required parameter visitorToken was null or undefined when calling updateVisitorByToken.');
            }
            // verify required parameter 'visitorData' is not null or undefined
            if (visitorData === null || visitorData === undefined) {
                throw new RequiredError('visitorData','Required parameter visitorData was null or undefined when calling updateVisitorByToken.');
            }
            const localVarPath = `/api/form/{token}/{visitorToken}`
                .replace(`{${"token"}}`, encodeURIComponent(String(token)))
                .replace(`{${"visitorToken"}}`, encodeURIComponent(String(visitorToken)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"VisitorData" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(visitorData !== undefined ? visitorData : {}) : (visitorData || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RegFormApi - functional programming interface
 * @export
 */
export const RegFormApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} token Access token.
         * @param {string} visitorToken Visitor token.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelVisitorByToken(token: string, visitorToken: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = RegFormApiAxiosParamCreator(configuration).cancelVisitorByToken(token, visitorToken, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} token Access token.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getForm(token: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<RegFormData> {
            const localVarAxiosArgs = RegFormApiAxiosParamCreator(configuration).getForm(token, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} token Access token.
         * @param {string} visitorToken Visitor token.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVisitorByToken(token: string, visitorToken: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<VisitorData> {
            const localVarAxiosArgs = RegFormApiAxiosParamCreator(configuration).getVisitorByToken(token, visitorToken, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} token Access token.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendForm(token: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<VisitorData> {
            const localVarAxiosArgs = RegFormApiAxiosParamCreator(configuration).sendForm(token, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} token Access token.
         * @param {string} visitorToken Visitor token.
         * @param {VisitorData} visitorData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateVisitorByToken(token: string, visitorToken: string, visitorData: VisitorData, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<VisitorData> {
            const localVarAxiosArgs = RegFormApiAxiosParamCreator(configuration).updateVisitorByToken(token, visitorToken, visitorData, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * RegFormApi - factory interface
 * @export
 */
export const RegFormApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {string} token Access token.
         * @param {string} visitorToken Visitor token.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelVisitorByToken(token: string, visitorToken: string, options?: any) {
            return RegFormApiFp(configuration).cancelVisitorByToken(token, visitorToken, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} token Access token.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getForm(token: string, options?: any) {
            return RegFormApiFp(configuration).getForm(token, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} token Access token.
         * @param {string} visitorToken Visitor token.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVisitorByToken(token: string, visitorToken: string, options?: any) {
            return RegFormApiFp(configuration).getVisitorByToken(token, visitorToken, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} token Access token.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendForm(token: string, options?: any) {
            return RegFormApiFp(configuration).sendForm(token, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} token Access token.
         * @param {string} visitorToken Visitor token.
         * @param {VisitorData} visitorData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateVisitorByToken(token: string, visitorToken: string, visitorData: VisitorData, options?: any) {
            return RegFormApiFp(configuration).updateVisitorByToken(token, visitorToken, visitorData, options)(axios, basePath);
        },
    };
};

/**
 * RegFormApi - object-oriented interface
 * @export
 * @class RegFormApi
 * @extends {BaseAPI}
 */
export class RegFormApi extends BaseAPI {
    /**
     * 
     * @param {string} token Access token.
     * @param {string} visitorToken Visitor token.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegFormApi
     */
    public cancelVisitorByToken(token: string, visitorToken: string, options?: any) {
        return RegFormApiFp(this.configuration).cancelVisitorByToken(token, visitorToken, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} token Access token.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegFormApi
     */
    public getForm(token: string, options?: any) {
        return RegFormApiFp(this.configuration).getForm(token, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} token Access token.
     * @param {string} visitorToken Visitor token.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegFormApi
     */
    public getVisitorByToken(token: string, visitorToken: string, options?: any) {
        return RegFormApiFp(this.configuration).getVisitorByToken(token, visitorToken, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} token Access token.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegFormApi
     */
    public sendForm(token: string, options?: any) {
        return RegFormApiFp(this.configuration).sendForm(token, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} token Access token.
     * @param {string} visitorToken Visitor token.
     * @param {VisitorData} visitorData 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegFormApi
     */
    public updateVisitorByToken(token: string, visitorToken: string, visitorData: VisitorData, options?: any) {
        return RegFormApiFp(this.configuration).updateVisitorByToken(token, visitorToken, visitorData, options)(this.axios, this.basePath);
    }

}


/**
 * SpeakerApi - axios parameter creator
 * @export
 */
export const SpeakerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {SpeakerData} speakerData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSpeaker(meetupId: number, speakerData: SpeakerData, options: any = {}): RequestArgs {
            // verify required parameter 'meetupId' is not null or undefined
            if (meetupId === null || meetupId === undefined) {
                throw new RequiredError('meetupId','Required parameter meetupId was null or undefined when calling createSpeaker.');
            }
            // verify required parameter 'speakerData' is not null or undefined
            if (speakerData === null || speakerData === undefined) {
                throw new RequiredError('speakerData','Required parameter speakerData was null or undefined when calling createSpeaker.');
            }
            const localVarPath = `/api/meetup/{meetupId}/speaker`
                .replace(`{${"meetupId"}}`, encodeURIComponent(String(meetupId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"SpeakerData" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(speakerData !== undefined ? speakerData : {}) : (speakerData || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {number} speakerId Meetup speaker identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSpeaker(meetupId: number, speakerId: number, options: any = {}): RequestArgs {
            // verify required parameter 'meetupId' is not null or undefined
            if (meetupId === null || meetupId === undefined) {
                throw new RequiredError('meetupId','Required parameter meetupId was null or undefined when calling deleteSpeaker.');
            }
            // verify required parameter 'speakerId' is not null or undefined
            if (speakerId === null || speakerId === undefined) {
                throw new RequiredError('speakerId','Required parameter speakerId was null or undefined when calling deleteSpeaker.');
            }
            const localVarPath = `/api/meetup/{meetupId}/speaker/{speakerId}`
                .replace(`{${"meetupId"}}`, encodeURIComponent(String(meetupId)))
                .replace(`{${"speakerId"}}`, encodeURIComponent(String(speakerId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {number} speakerId Meetup speaker identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSpeaker(meetupId: number, speakerId: number, options: any = {}): RequestArgs {
            // verify required parameter 'meetupId' is not null or undefined
            if (meetupId === null || meetupId === undefined) {
                throw new RequiredError('meetupId','Required parameter meetupId was null or undefined when calling getSpeaker.');
            }
            // verify required parameter 'speakerId' is not null or undefined
            if (speakerId === null || speakerId === undefined) {
                throw new RequiredError('speakerId','Required parameter speakerId was null or undefined when calling getSpeaker.');
            }
            const localVarPath = `/api/meetup/{meetupId}/speaker/{speakerId}`
                .replace(`{${"meetupId"}}`, encodeURIComponent(String(meetupId)))
                .replace(`{${"speakerId"}}`, encodeURIComponent(String(speakerId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSpeakers(meetupId: number, options: any = {}): RequestArgs {
            // verify required parameter 'meetupId' is not null or undefined
            if (meetupId === null || meetupId === undefined) {
                throw new RequiredError('meetupId','Required parameter meetupId was null or undefined when calling listSpeakers.');
            }
            const localVarPath = `/api/meetup/{meetupId}/speaker`
                .replace(`{${"meetupId"}}`, encodeURIComponent(String(meetupId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {number} speakerId Meetup speaker identifier.
         * @param {SpeakerData} speakerData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSpeaker(meetupId: number, speakerId: number, speakerData: SpeakerData, options: any = {}): RequestArgs {
            // verify required parameter 'meetupId' is not null or undefined
            if (meetupId === null || meetupId === undefined) {
                throw new RequiredError('meetupId','Required parameter meetupId was null or undefined when calling updateSpeaker.');
            }
            // verify required parameter 'speakerId' is not null or undefined
            if (speakerId === null || speakerId === undefined) {
                throw new RequiredError('speakerId','Required parameter speakerId was null or undefined when calling updateSpeaker.');
            }
            // verify required parameter 'speakerData' is not null or undefined
            if (speakerData === null || speakerData === undefined) {
                throw new RequiredError('speakerData','Required parameter speakerData was null or undefined when calling updateSpeaker.');
            }
            const localVarPath = `/api/meetup/{meetupId}/speaker/{speakerId}`
                .replace(`{${"meetupId"}}`, encodeURIComponent(String(meetupId)))
                .replace(`{${"speakerId"}}`, encodeURIComponent(String(speakerId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"SpeakerData" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(speakerData !== undefined ? speakerData : {}) : (speakerData || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SpeakerApi - functional programming interface
 * @export
 */
export const SpeakerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {SpeakerData} speakerData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSpeaker(meetupId: number, speakerData: SpeakerData, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SpeakerData> {
            const localVarAxiosArgs = SpeakerApiAxiosParamCreator(configuration).createSpeaker(meetupId, speakerData, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {number} speakerId Meetup speaker identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSpeaker(meetupId: number, speakerId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = SpeakerApiAxiosParamCreator(configuration).deleteSpeaker(meetupId, speakerId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {number} speakerId Meetup speaker identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSpeaker(meetupId: number, speakerId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SpeakerData> {
            const localVarAxiosArgs = SpeakerApiAxiosParamCreator(configuration).getSpeaker(meetupId, speakerId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSpeakers(meetupId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SpeakerData>> {
            const localVarAxiosArgs = SpeakerApiAxiosParamCreator(configuration).listSpeakers(meetupId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {number} speakerId Meetup speaker identifier.
         * @param {SpeakerData} speakerData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSpeaker(meetupId: number, speakerId: number, speakerData: SpeakerData, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SpeakerData> {
            const localVarAxiosArgs = SpeakerApiAxiosParamCreator(configuration).updateSpeaker(meetupId, speakerId, speakerData, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * SpeakerApi - factory interface
 * @export
 */
export const SpeakerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {SpeakerData} speakerData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSpeaker(meetupId: number, speakerData: SpeakerData, options?: any) {
            return SpeakerApiFp(configuration).createSpeaker(meetupId, speakerData, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {number} speakerId Meetup speaker identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSpeaker(meetupId: number, speakerId: number, options?: any) {
            return SpeakerApiFp(configuration).deleteSpeaker(meetupId, speakerId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {number} speakerId Meetup speaker identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSpeaker(meetupId: number, speakerId: number, options?: any) {
            return SpeakerApiFp(configuration).getSpeaker(meetupId, speakerId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSpeakers(meetupId: number, options?: any) {
            return SpeakerApiFp(configuration).listSpeakers(meetupId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {number} speakerId Meetup speaker identifier.
         * @param {SpeakerData} speakerData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSpeaker(meetupId: number, speakerId: number, speakerData: SpeakerData, options?: any) {
            return SpeakerApiFp(configuration).updateSpeaker(meetupId, speakerId, speakerData, options)(axios, basePath);
        },
    };
};

/**
 * SpeakerApi - object-oriented interface
 * @export
 * @class SpeakerApi
 * @extends {BaseAPI}
 */
export class SpeakerApi extends BaseAPI {
    /**
     * 
     * @param {number} meetupId Meetup identifier.
     * @param {SpeakerData} speakerData 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SpeakerApi
     */
    public createSpeaker(meetupId: number, speakerData: SpeakerData, options?: any) {
        return SpeakerApiFp(this.configuration).createSpeaker(meetupId, speakerData, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} meetupId Meetup identifier.
     * @param {number} speakerId Meetup speaker identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SpeakerApi
     */
    public deleteSpeaker(meetupId: number, speakerId: number, options?: any) {
        return SpeakerApiFp(this.configuration).deleteSpeaker(meetupId, speakerId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} meetupId Meetup identifier.
     * @param {number} speakerId Meetup speaker identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SpeakerApi
     */
    public getSpeaker(meetupId: number, speakerId: number, options?: any) {
        return SpeakerApiFp(this.configuration).getSpeaker(meetupId, speakerId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} meetupId Meetup identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SpeakerApi
     */
    public listSpeakers(meetupId: number, options?: any) {
        return SpeakerApiFp(this.configuration).listSpeakers(meetupId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} meetupId Meetup identifier.
     * @param {number} speakerId Meetup speaker identifier.
     * @param {SpeakerData} speakerData 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SpeakerApi
     */
    public updateSpeaker(meetupId: number, speakerId: number, speakerData: SpeakerData, options?: any) {
        return SpeakerApiFp(this.configuration).updateSpeaker(meetupId, speakerId, speakerData, options)(this.axios, this.basePath);
    }

}


/**
 * TestApi - axios parameter creator
 * @export
 */
export const TestApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} aid Account identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateNotification(aid: number, options: any = {}): RequestArgs {
            // verify required parameter 'aid' is not null or undefined
            if (aid === null || aid === undefined) {
                throw new RequiredError('aid','Required parameter aid was null or undefined when calling generateNotification.');
            }
            const localVarPath = `/api-test/notifications`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (aid !== undefined) {
                localVarQueryParameter['aid'] = aid;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Test email
         * @param {TestEmailRequestData} testEmailRequestData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testEmail(testEmailRequestData: TestEmailRequestData, options: any = {}): RequestArgs {
            // verify required parameter 'testEmailRequestData' is not null or undefined
            if (testEmailRequestData === null || testEmailRequestData === undefined) {
                throw new RequiredError('testEmailRequestData','Required parameter testEmailRequestData was null or undefined when calling testEmail.');
            }
            const localVarPath = `/api-test/email`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"TestEmailRequestData" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(testEmailRequestData !== undefined ? testEmailRequestData : {}) : (testEmailRequestData || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Test sms
         * @param {TestSmsRequestData} testSmsRequestData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testSms(testSmsRequestData: TestSmsRequestData, options: any = {}): RequestArgs {
            // verify required parameter 'testSmsRequestData' is not null or undefined
            if (testSmsRequestData === null || testSmsRequestData === undefined) {
                throw new RequiredError('testSmsRequestData','Required parameter testSmsRequestData was null or undefined when calling testSms.');
            }
            const localVarPath = `/api-test/sms`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"TestSmsRequestData" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(testSmsRequestData !== undefined ? testSmsRequestData : {}) : (testSmsRequestData || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TestApi - functional programming interface
 * @export
 */
export const TestApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} aid Account identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateNotification(aid: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = TestApiAxiosParamCreator(configuration).generateNotification(aid, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Test email
         * @param {TestEmailRequestData} testEmailRequestData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testEmail(testEmailRequestData: TestEmailRequestData, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<TestEmailData> {
            const localVarAxiosArgs = TestApiAxiosParamCreator(configuration).testEmail(testEmailRequestData, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Test sms
         * @param {TestSmsRequestData} testSmsRequestData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testSms(testSmsRequestData: TestSmsRequestData, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<TestSmsData> {
            const localVarAxiosArgs = TestApiAxiosParamCreator(configuration).testSms(testSmsRequestData, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * TestApi - factory interface
 * @export
 */
export const TestApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {number} aid Account identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateNotification(aid: number, options?: any) {
            return TestApiFp(configuration).generateNotification(aid, options)(axios, basePath);
        },
        /**
         * 
         * @summary Test email
         * @param {TestEmailRequestData} testEmailRequestData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testEmail(testEmailRequestData: TestEmailRequestData, options?: any) {
            return TestApiFp(configuration).testEmail(testEmailRequestData, options)(axios, basePath);
        },
        /**
         * 
         * @summary Test sms
         * @param {TestSmsRequestData} testSmsRequestData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testSms(testSmsRequestData: TestSmsRequestData, options?: any) {
            return TestApiFp(configuration).testSms(testSmsRequestData, options)(axios, basePath);
        },
    };
};

/**
 * TestApi - object-oriented interface
 * @export
 * @class TestApi
 * @extends {BaseAPI}
 */
export class TestApi extends BaseAPI {
    /**
     * 
     * @param {number} aid Account identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestApi
     */
    public generateNotification(aid: number, options?: any) {
        return TestApiFp(this.configuration).generateNotification(aid, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Test email
     * @param {TestEmailRequestData} testEmailRequestData 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestApi
     */
    public testEmail(testEmailRequestData: TestEmailRequestData, options?: any) {
        return TestApiFp(this.configuration).testEmail(testEmailRequestData, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Test sms
     * @param {TestSmsRequestData} testSmsRequestData 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestApi
     */
    public testSms(testSmsRequestData: TestSmsRequestData, options?: any) {
        return TestApiFp(this.configuration).testSms(testSmsRequestData, options)(this.axios, this.basePath);
    }

}


/**
 * TimeSlotApi - axios parameter creator
 * @export
 */
export const TimeSlotApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {TimeSlotData} timeSlotData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTimeSlot(meetupId: number, timeSlotData: TimeSlotData, options: any = {}): RequestArgs {
            // verify required parameter 'meetupId' is not null or undefined
            if (meetupId === null || meetupId === undefined) {
                throw new RequiredError('meetupId','Required parameter meetupId was null or undefined when calling createTimeSlot.');
            }
            // verify required parameter 'timeSlotData' is not null or undefined
            if (timeSlotData === null || timeSlotData === undefined) {
                throw new RequiredError('timeSlotData','Required parameter timeSlotData was null or undefined when calling createTimeSlot.');
            }
            const localVarPath = `/api/meetup/{meetupId}/time-slot`
                .replace(`{${"meetupId"}}`, encodeURIComponent(String(meetupId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"TimeSlotData" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(timeSlotData !== undefined ? timeSlotData : {}) : (timeSlotData || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {number} timeSlotId Meetup TimeSlot identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTimeSlot(meetupId: number, timeSlotId: number, options: any = {}): RequestArgs {
            // verify required parameter 'meetupId' is not null or undefined
            if (meetupId === null || meetupId === undefined) {
                throw new RequiredError('meetupId','Required parameter meetupId was null or undefined when calling deleteTimeSlot.');
            }
            // verify required parameter 'timeSlotId' is not null or undefined
            if (timeSlotId === null || timeSlotId === undefined) {
                throw new RequiredError('timeSlotId','Required parameter timeSlotId was null or undefined when calling deleteTimeSlot.');
            }
            const localVarPath = `/api/meetup/{meetupId}/time-slot/{timeSlotId}`
                .replace(`{${"meetupId"}}`, encodeURIComponent(String(meetupId)))
                .replace(`{${"timeSlotId"}}`, encodeURIComponent(String(timeSlotId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {number} timeSlotId Meetup TimeSlot identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTimeSlot(meetupId: number, timeSlotId: number, options: any = {}): RequestArgs {
            // verify required parameter 'meetupId' is not null or undefined
            if (meetupId === null || meetupId === undefined) {
                throw new RequiredError('meetupId','Required parameter meetupId was null or undefined when calling getTimeSlot.');
            }
            // verify required parameter 'timeSlotId' is not null or undefined
            if (timeSlotId === null || timeSlotId === undefined) {
                throw new RequiredError('timeSlotId','Required parameter timeSlotId was null or undefined when calling getTimeSlot.');
            }
            const localVarPath = `/api/meetup/{meetupId}/time-slot/{timeSlotId}`
                .replace(`{${"meetupId"}}`, encodeURIComponent(String(meetupId)))
                .replace(`{${"timeSlotId"}}`, encodeURIComponent(String(timeSlotId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTimeSlots(meetupId: number, options: any = {}): RequestArgs {
            // verify required parameter 'meetupId' is not null or undefined
            if (meetupId === null || meetupId === undefined) {
                throw new RequiredError('meetupId','Required parameter meetupId was null or undefined when calling listTimeSlots.');
            }
            const localVarPath = `/api/meetup/{meetupId}/time-slot`
                .replace(`{${"meetupId"}}`, encodeURIComponent(String(meetupId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {number} timeSlotId Meetup TimeSlot identifier.
         * @param {TimeSlotData} timeSlotData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTimeSlot(meetupId: number, timeSlotId: number, timeSlotData: TimeSlotData, options: any = {}): RequestArgs {
            // verify required parameter 'meetupId' is not null or undefined
            if (meetupId === null || meetupId === undefined) {
                throw new RequiredError('meetupId','Required parameter meetupId was null or undefined when calling updateTimeSlot.');
            }
            // verify required parameter 'timeSlotId' is not null or undefined
            if (timeSlotId === null || timeSlotId === undefined) {
                throw new RequiredError('timeSlotId','Required parameter timeSlotId was null or undefined when calling updateTimeSlot.');
            }
            // verify required parameter 'timeSlotData' is not null or undefined
            if (timeSlotData === null || timeSlotData === undefined) {
                throw new RequiredError('timeSlotData','Required parameter timeSlotData was null or undefined when calling updateTimeSlot.');
            }
            const localVarPath = `/api/meetup/{meetupId}/time-slot/{timeSlotId}`
                .replace(`{${"meetupId"}}`, encodeURIComponent(String(meetupId)))
                .replace(`{${"timeSlotId"}}`, encodeURIComponent(String(timeSlotId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"TimeSlotData" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(timeSlotData !== undefined ? timeSlotData : {}) : (timeSlotData || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TimeSlotApi - functional programming interface
 * @export
 */
export const TimeSlotApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {TimeSlotData} timeSlotData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTimeSlot(meetupId: number, timeSlotData: TimeSlotData, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<TimeSlotData> {
            const localVarAxiosArgs = TimeSlotApiAxiosParamCreator(configuration).createTimeSlot(meetupId, timeSlotData, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {number} timeSlotId Meetup TimeSlot identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTimeSlot(meetupId: number, timeSlotId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = TimeSlotApiAxiosParamCreator(configuration).deleteTimeSlot(meetupId, timeSlotId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {number} timeSlotId Meetup TimeSlot identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTimeSlot(meetupId: number, timeSlotId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<TimeSlotData> {
            const localVarAxiosArgs = TimeSlotApiAxiosParamCreator(configuration).getTimeSlot(meetupId, timeSlotId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTimeSlots(meetupId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TimeSlotData>> {
            const localVarAxiosArgs = TimeSlotApiAxiosParamCreator(configuration).listTimeSlots(meetupId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {number} timeSlotId Meetup TimeSlot identifier.
         * @param {TimeSlotData} timeSlotData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTimeSlot(meetupId: number, timeSlotId: number, timeSlotData: TimeSlotData, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<TimeSlotData> {
            const localVarAxiosArgs = TimeSlotApiAxiosParamCreator(configuration).updateTimeSlot(meetupId, timeSlotId, timeSlotData, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * TimeSlotApi - factory interface
 * @export
 */
export const TimeSlotApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {TimeSlotData} timeSlotData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTimeSlot(meetupId: number, timeSlotData: TimeSlotData, options?: any) {
            return TimeSlotApiFp(configuration).createTimeSlot(meetupId, timeSlotData, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {number} timeSlotId Meetup TimeSlot identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTimeSlot(meetupId: number, timeSlotId: number, options?: any) {
            return TimeSlotApiFp(configuration).deleteTimeSlot(meetupId, timeSlotId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {number} timeSlotId Meetup TimeSlot identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTimeSlot(meetupId: number, timeSlotId: number, options?: any) {
            return TimeSlotApiFp(configuration).getTimeSlot(meetupId, timeSlotId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTimeSlots(meetupId: number, options?: any) {
            return TimeSlotApiFp(configuration).listTimeSlots(meetupId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {number} timeSlotId Meetup TimeSlot identifier.
         * @param {TimeSlotData} timeSlotData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTimeSlot(meetupId: number, timeSlotId: number, timeSlotData: TimeSlotData, options?: any) {
            return TimeSlotApiFp(configuration).updateTimeSlot(meetupId, timeSlotId, timeSlotData, options)(axios, basePath);
        },
    };
};

/**
 * TimeSlotApi - object-oriented interface
 * @export
 * @class TimeSlotApi
 * @extends {BaseAPI}
 */
export class TimeSlotApi extends BaseAPI {
    /**
     * 
     * @param {number} meetupId Meetup identifier.
     * @param {TimeSlotData} timeSlotData 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimeSlotApi
     */
    public createTimeSlot(meetupId: number, timeSlotData: TimeSlotData, options?: any) {
        return TimeSlotApiFp(this.configuration).createTimeSlot(meetupId, timeSlotData, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} meetupId Meetup identifier.
     * @param {number} timeSlotId Meetup TimeSlot identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimeSlotApi
     */
    public deleteTimeSlot(meetupId: number, timeSlotId: number, options?: any) {
        return TimeSlotApiFp(this.configuration).deleteTimeSlot(meetupId, timeSlotId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} meetupId Meetup identifier.
     * @param {number} timeSlotId Meetup TimeSlot identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimeSlotApi
     */
    public getTimeSlot(meetupId: number, timeSlotId: number, options?: any) {
        return TimeSlotApiFp(this.configuration).getTimeSlot(meetupId, timeSlotId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} meetupId Meetup identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimeSlotApi
     */
    public listTimeSlots(meetupId: number, options?: any) {
        return TimeSlotApiFp(this.configuration).listTimeSlots(meetupId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} meetupId Meetup identifier.
     * @param {number} timeSlotId Meetup TimeSlot identifier.
     * @param {TimeSlotData} timeSlotData 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimeSlotApi
     */
    public updateTimeSlot(meetupId: number, timeSlotId: number, timeSlotData: TimeSlotData, options?: any) {
        return TimeSlotApiFp(this.configuration).updateTimeSlot(meetupId, timeSlotId, timeSlotData, options)(this.axios, this.basePath);
    }

}


/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {UserData} userData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUser(userData: UserData, options: any = {}): RequestArgs {
            // verify required parameter 'userData' is not null or undefined
            if (userData === null || userData === undefined) {
                throw new RequiredError('userData','Required parameter userData was null or undefined when calling createUser.');
            }
            const localVarPath = `/api/user`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"UserData" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(userData !== undefined ? userData : {}) : (userData || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId User identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser(userId: number, options: any = {}): RequestArgs {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling deleteUser.');
            }
            const localVarPath = `/api/user/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId User identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser(userId: number, options: any = {}): RequestArgs {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling getUser.');
            }
            const localVarPath = `/api/user/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUsers(options: any = {}): RequestArgs {
            const localVarPath = `/api/user`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId User identifier.
         * @param {UserData} userData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser(userId: number, userData: UserData, options: any = {}): RequestArgs {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling updateUser.');
            }
            // verify required parameter 'userData' is not null or undefined
            if (userData === null || userData === undefined) {
                throw new RequiredError('userData','Required parameter userData was null or undefined when calling updateUser.');
            }
            const localVarPath = `/api/user/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"UserData" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(userData !== undefined ? userData : {}) : (userData || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {UserData} userData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUser(userData: UserData, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserData> {
            const localVarAxiosArgs = UserApiAxiosParamCreator(configuration).createUser(userData, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} userId User identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser(userId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = UserApiAxiosParamCreator(configuration).deleteUser(userId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} userId User identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser(userId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserData> {
            const localVarAxiosArgs = UserApiAxiosParamCreator(configuration).getUser(userId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUsers(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserData>> {
            const localVarAxiosArgs = UserApiAxiosParamCreator(configuration).listUsers(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} userId User identifier.
         * @param {UserData} userData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser(userId: number, userData: UserData, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserData> {
            const localVarAxiosArgs = UserApiAxiosParamCreator(configuration).updateUser(userId, userData, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {UserData} userData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUser(userData: UserData, options?: any) {
            return UserApiFp(configuration).createUser(userData, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} userId User identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser(userId: number, options?: any) {
            return UserApiFp(configuration).deleteUser(userId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} userId User identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser(userId: number, options?: any) {
            return UserApiFp(configuration).getUser(userId, options)(axios, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUsers(options?: any) {
            return UserApiFp(configuration).listUsers(options)(axios, basePath);
        },
        /**
         * 
         * @param {number} userId User identifier.
         * @param {UserData} userData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser(userId: number, userData: UserData, options?: any) {
            return UserApiFp(configuration).updateUser(userId, userData, options)(axios, basePath);
        },
    };
};

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * 
     * @param {UserData} userData 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public createUser(userData: UserData, options?: any) {
        return UserApiFp(this.configuration).createUser(userData, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} userId User identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public deleteUser(userId: number, options?: any) {
        return UserApiFp(this.configuration).deleteUser(userId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} userId User identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getUser(userId: number, options?: any) {
        return UserApiFp(this.configuration).getUser(userId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public listUsers(options?: any) {
        return UserApiFp(this.configuration).listUsers(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} userId User identifier.
     * @param {UserData} userData 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public updateUser(userId: number, userData: UserData, options?: any) {
        return UserApiFp(this.configuration).updateUser(userId, userData, options)(this.axios, this.basePath);
    }

}


/**
 * VisitorApi - axios parameter creator
 * @export
 */
export const VisitorApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {number} visitorId Visitor identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkin(meetupId: number, visitorId: number, options: any = {}): RequestArgs {
            // verify required parameter 'meetupId' is not null or undefined
            if (meetupId === null || meetupId === undefined) {
                throw new RequiredError('meetupId','Required parameter meetupId was null or undefined when calling checkin.');
            }
            // verify required parameter 'visitorId' is not null or undefined
            if (visitorId === null || visitorId === undefined) {
                throw new RequiredError('visitorId','Required parameter visitorId was null or undefined when calling checkin.');
            }
            const localVarPath = `/api/meetup/{meetupId}/visitor/{visitorId}/checkin`
                .replace(`{${"meetupId"}}`, encodeURIComponent(String(meetupId)))
                .replace(`{${"visitorId"}}`, encodeURIComponent(String(visitorId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {VisitorData} visitorData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createVisitor(meetupId: number, visitorData: VisitorData, options: any = {}): RequestArgs {
            // verify required parameter 'meetupId' is not null or undefined
            if (meetupId === null || meetupId === undefined) {
                throw new RequiredError('meetupId','Required parameter meetupId was null or undefined when calling createVisitor.');
            }
            // verify required parameter 'visitorData' is not null or undefined
            if (visitorData === null || visitorData === undefined) {
                throw new RequiredError('visitorData','Required parameter visitorData was null or undefined when calling createVisitor.');
            }
            const localVarPath = `/api/meetup/{meetupId}/visitor`
                .replace(`{${"meetupId"}}`, encodeURIComponent(String(meetupId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"VisitorData" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(visitorData !== undefined ? visitorData : {}) : (visitorData || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {number} visitorId Visitor identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteVisitor(meetupId: number, visitorId: number, options: any = {}): RequestArgs {
            // verify required parameter 'meetupId' is not null or undefined
            if (meetupId === null || meetupId === undefined) {
                throw new RequiredError('meetupId','Required parameter meetupId was null or undefined when calling deleteVisitor.');
            }
            // verify required parameter 'visitorId' is not null or undefined
            if (visitorId === null || visitorId === undefined) {
                throw new RequiredError('visitorId','Required parameter visitorId was null or undefined when calling deleteVisitor.');
            }
            const localVarPath = `/api/meetup/{meetupId}/visitor/{visitorId}`
                .replace(`{${"meetupId"}}`, encodeURIComponent(String(meetupId)))
                .replace(`{${"visitorId"}}`, encodeURIComponent(String(visitorId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {Array<number>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteVisitors(meetupId: number, requestBody: Array<number>, options: any = {}): RequestArgs {
            // verify required parameter 'meetupId' is not null or undefined
            if (meetupId === null || meetupId === undefined) {
                throw new RequiredError('meetupId','Required parameter meetupId was null or undefined when calling deleteVisitors.');
            }
            // verify required parameter 'requestBody' is not null or undefined
            if (requestBody === null || requestBody === undefined) {
                throw new RequiredError('requestBody','Required parameter requestBody was null or undefined when calling deleteVisitors.');
            }
            const localVarPath = `/api/meetup/{meetupId}/visitor/delete`
                .replace(`{${"meetupId"}}`, encodeURIComponent(String(meetupId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"Array&lt;number&gt;" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(requestBody !== undefined ? requestBody : {}) : (requestBody || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {number} visitorId Visitor identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllData(meetupId: number, visitorId: number, options: any = {}): RequestArgs {
            // verify required parameter 'meetupId' is not null or undefined
            if (meetupId === null || meetupId === undefined) {
                throw new RequiredError('meetupId','Required parameter meetupId was null or undefined when calling getAllData.');
            }
            // verify required parameter 'visitorId' is not null or undefined
            if (visitorId === null || visitorId === undefined) {
                throw new RequiredError('visitorId','Required parameter visitorId was null or undefined when calling getAllData.');
            }
            const localVarPath = `/api/meetup/{meetupId}/visitor/{visitorId}/render-data`
                .replace(`{${"meetupId"}}`, encodeURIComponent(String(meetupId)))
                .replace(`{${"visitorId"}}`, encodeURIComponent(String(visitorId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} aid Account identifier.
         * @param {number} mid Meetup identifier.
         * @param {number} vid Visitor identifier.
         * @param {number} badgeId Badge identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDataInternal(aid: number, mid: number, vid: number, badgeId: number, options: any = {}): RequestArgs {
            // verify required parameter 'aid' is not null or undefined
            if (aid === null || aid === undefined) {
                throw new RequiredError('aid','Required parameter aid was null or undefined when calling getAllDataInternal.');
            }
            // verify required parameter 'mid' is not null or undefined
            if (mid === null || mid === undefined) {
                throw new RequiredError('mid','Required parameter mid was null or undefined when calling getAllDataInternal.');
            }
            // verify required parameter 'vid' is not null or undefined
            if (vid === null || vid === undefined) {
                throw new RequiredError('vid','Required parameter vid was null or undefined when calling getAllDataInternal.');
            }
            // verify required parameter 'badgeId' is not null or undefined
            if (badgeId === null || badgeId === undefined) {
                throw new RequiredError('badgeId','Required parameter badgeId was null or undefined when calling getAllDataInternal.');
            }
            const localVarPath = `/api-internal/render-data-internal`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (aid !== undefined) {
                localVarQueryParameter['aid'] = aid;
            }

            if (mid !== undefined) {
                localVarQueryParameter['mid'] = mid;
            }

            if (vid !== undefined) {
                localVarQueryParameter['vid'] = vid;
            }

            if (badgeId !== undefined) {
                localVarQueryParameter['badgeId'] = badgeId;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {number} visitorId Visitor identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVisitor(meetupId: number, visitorId: number, options: any = {}): RequestArgs {
            // verify required parameter 'meetupId' is not null or undefined
            if (meetupId === null || meetupId === undefined) {
                throw new RequiredError('meetupId','Required parameter meetupId was null or undefined when calling getVisitor.');
            }
            // verify required parameter 'visitorId' is not null or undefined
            if (visitorId === null || visitorId === undefined) {
                throw new RequiredError('visitorId','Required parameter visitorId was null or undefined when calling getVisitor.');
            }
            const localVarPath = `/api/meetup/{meetupId}/visitor/{visitorId}`
                .replace(`{${"meetupId"}}`, encodeURIComponent(String(meetupId)))
                .replace(`{${"visitorId"}}`, encodeURIComponent(String(visitorId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {string} qrToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVisitorByQRCode(meetupId: number, qrToken: string, options: any = {}): RequestArgs {
            // verify required parameter 'meetupId' is not null or undefined
            if (meetupId === null || meetupId === undefined) {
                throw new RequiredError('meetupId','Required parameter meetupId was null or undefined when calling getVisitorByQRCode.');
            }
            // verify required parameter 'qrToken' is not null or undefined
            if (qrToken === null || qrToken === undefined) {
                throw new RequiredError('qrToken','Required parameter qrToken was null or undefined when calling getVisitorByQRCode.');
            }
            const localVarPath = `/api/meetup/{meetupId}/visitor/find-by-code`
                .replace(`{${"meetupId"}}`, encodeURIComponent(String(meetupId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (qrToken !== undefined) {
                localVarQueryParameter['qrToken'] = qrToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {string} vem Visitor email.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVisitorIdentification(meetupId: number, vem: string, options: any = {}): RequestArgs {
            // verify required parameter 'meetupId' is not null or undefined
            if (meetupId === null || meetupId === undefined) {
                throw new RequiredError('meetupId','Required parameter meetupId was null or undefined when calling getVisitorIdentification.');
            }
            // verify required parameter 'vem' is not null or undefined
            if (vem === null || vem === undefined) {
                throw new RequiredError('vem','Required parameter vem was null or undefined when calling getVisitorIdentification.');
            }
            const localVarPath = `/api/meetup/{meetupId}/visitor/identification`
                .replace(`{${"meetupId"}}`, encodeURIComponent(String(meetupId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (vem !== undefined) {
                localVarQueryParameter['vem'] = vem;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {VisitorImportRequestData} visitorImportRequestData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importVisitors(meetupId: number, visitorImportRequestData: VisitorImportRequestData, options: any = {}): RequestArgs {
            // verify required parameter 'meetupId' is not null or undefined
            if (meetupId === null || meetupId === undefined) {
                throw new RequiredError('meetupId','Required parameter meetupId was null or undefined when calling importVisitors.');
            }
            // verify required parameter 'visitorImportRequestData' is not null or undefined
            if (visitorImportRequestData === null || visitorImportRequestData === undefined) {
                throw new RequiredError('visitorImportRequestData','Required parameter visitorImportRequestData was null or undefined when calling importVisitors.');
            }
            const localVarPath = `/api/meetup/{meetupId}/visitor/import`
                .replace(`{${"meetupId"}}`, encodeURIComponent(String(meetupId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"VisitorImportRequestData" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(visitorImportRequestData !== undefined ? visitorImportRequestData : {}) : (visitorImportRequestData || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {number} [gid] Group identifier.
         * @param {boolean} [_export] Export flag.
         * @param {string} [filter] Filter set for visitor in a form of json object {\&quot;field-name\&quot;: exact-value}.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listVisitors(meetupId: number, gid?: number, _export?: boolean, filter?: string, options: any = {}): RequestArgs {
            // verify required parameter 'meetupId' is not null or undefined
            if (meetupId === null || meetupId === undefined) {
                throw new RequiredError('meetupId','Required parameter meetupId was null or undefined when calling listVisitors.');
            }
            const localVarPath = `/api/meetup/{meetupId}/visitor`
                .replace(`{${"meetupId"}}`, encodeURIComponent(String(meetupId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (gid !== undefined) {
                localVarQueryParameter['gid'] = gid;
            }

            if (_export !== undefined) {
                localVarQueryParameter['export'] = _export;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {number} visitorId Visitor identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        prePrintHook(meetupId: number, visitorId: number, options: any = {}): RequestArgs {
            // verify required parameter 'meetupId' is not null or undefined
            if (meetupId === null || meetupId === undefined) {
                throw new RequiredError('meetupId','Required parameter meetupId was null or undefined when calling prePrintHook.');
            }
            // verify required parameter 'visitorId' is not null or undefined
            if (visitorId === null || visitorId === undefined) {
                throw new RequiredError('visitorId','Required parameter visitorId was null or undefined when calling prePrintHook.');
            }
            const localVarPath = `/api/meetup/{meetupId}/visitor/{visitorId}/pre-print-hook`
                .replace(`{${"meetupId"}}`, encodeURIComponent(String(meetupId)))
                .replace(`{${"visitorId"}}`, encodeURIComponent(String(visitorId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} token Access token.
         * @param {number} mid Meetup identifier.
         * @param {string} qrToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        qrConfirm(token: string, mid: number, qrToken: string, options: any = {}): RequestArgs {
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError('token','Required parameter token was null or undefined when calling qrConfirm.');
            }
            // verify required parameter 'mid' is not null or undefined
            if (mid === null || mid === undefined) {
                throw new RequiredError('mid','Required parameter mid was null or undefined when calling qrConfirm.');
            }
            // verify required parameter 'qrToken' is not null or undefined
            if (qrToken === null || qrToken === undefined) {
                throw new RequiredError('qrToken','Required parameter qrToken was null or undefined when calling qrConfirm.');
            }
            const localVarPath = `/api/qrConfirm/{token}`
                .replace(`{${"token"}}`, encodeURIComponent(String(token)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (mid !== undefined) {
                localVarQueryParameter['mid'] = mid;
            }

            if (qrToken !== undefined) {
                localVarQueryParameter['qrToken'] = qrToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {SendCustomMailData} sendCustomMailData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendCustomMail(meetupId: number, sendCustomMailData: SendCustomMailData, options: any = {}): RequestArgs {
            // verify required parameter 'meetupId' is not null or undefined
            if (meetupId === null || meetupId === undefined) {
                throw new RequiredError('meetupId','Required parameter meetupId was null or undefined when calling sendCustomMail.');
            }
            // verify required parameter 'sendCustomMailData' is not null or undefined
            if (sendCustomMailData === null || sendCustomMailData === undefined) {
                throw new RequiredError('sendCustomMailData','Required parameter sendCustomMailData was null or undefined when calling sendCustomMail.');
            }
            const localVarPath = `/api/meetup/{meetupId}/visitor/send-custom-mail`
                .replace(`{${"meetupId"}}`, encodeURIComponent(String(meetupId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"SendCustomMailData" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(sendCustomMailData !== undefined ? sendCustomMailData : {}) : (sendCustomMailData || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendRegistrationMail(meetupId: number, requestBody: Array<string>, options: any = {}): RequestArgs {
            // verify required parameter 'meetupId' is not null or undefined
            if (meetupId === null || meetupId === undefined) {
                throw new RequiredError('meetupId','Required parameter meetupId was null or undefined when calling sendRegistrationMail.');
            }
            // verify required parameter 'requestBody' is not null or undefined
            if (requestBody === null || requestBody === undefined) {
                throw new RequiredError('requestBody','Required parameter requestBody was null or undefined when calling sendRegistrationMail.');
            }
            const localVarPath = `/api/meetup/{meetupId}/visitor/send-registration-mail`
                .replace(`{${"meetupId"}}`, encodeURIComponent(String(meetupId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"Array&lt;string&gt;" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(requestBody !== undefined ? requestBody : {}) : (requestBody || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {number} visitorId Visitor identifier.
         * @param {VisitorData} visitorData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateVisitor(meetupId: number, visitorId: number, visitorData: VisitorData, options: any = {}): RequestArgs {
            // verify required parameter 'meetupId' is not null or undefined
            if (meetupId === null || meetupId === undefined) {
                throw new RequiredError('meetupId','Required parameter meetupId was null or undefined when calling updateVisitor.');
            }
            // verify required parameter 'visitorId' is not null or undefined
            if (visitorId === null || visitorId === undefined) {
                throw new RequiredError('visitorId','Required parameter visitorId was null or undefined when calling updateVisitor.');
            }
            // verify required parameter 'visitorData' is not null or undefined
            if (visitorData === null || visitorData === undefined) {
                throw new RequiredError('visitorData','Required parameter visitorData was null or undefined when calling updateVisitor.');
            }
            const localVarPath = `/api/meetup/{meetupId}/visitor/{visitorId}`
                .replace(`{${"meetupId"}}`, encodeURIComponent(String(meetupId)))
                .replace(`{${"visitorId"}}`, encodeURIComponent(String(visitorId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"VisitorData" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(visitorData !== undefined ? visitorData : {}) : (visitorData || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {number} visitorId Visitor identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        writeBadgeStatus(meetupId: number, visitorId: number, options: any = {}): RequestArgs {
            // verify required parameter 'meetupId' is not null or undefined
            if (meetupId === null || meetupId === undefined) {
                throw new RequiredError('meetupId','Required parameter meetupId was null or undefined when calling writeBadgeStatus.');
            }
            // verify required parameter 'visitorId' is not null or undefined
            if (visitorId === null || visitorId === undefined) {
                throw new RequiredError('visitorId','Required parameter visitorId was null or undefined when calling writeBadgeStatus.');
            }
            const localVarPath = `/api/meetup/{meetupId}/visitor/{visitorId}/write-badge-status`
                .replace(`{${"meetupId"}}`, encodeURIComponent(String(meetupId)))
                .replace(`{${"visitorId"}}`, encodeURIComponent(String(visitorId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {number} visitorId Visitor identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        writeDeskVisit(meetupId: number, visitorId: number, options: any = {}): RequestArgs {
            // verify required parameter 'meetupId' is not null or undefined
            if (meetupId === null || meetupId === undefined) {
                throw new RequiredError('meetupId','Required parameter meetupId was null or undefined when calling writeDeskVisit.');
            }
            // verify required parameter 'visitorId' is not null or undefined
            if (visitorId === null || visitorId === undefined) {
                throw new RequiredError('visitorId','Required parameter visitorId was null or undefined when calling writeDeskVisit.');
            }
            const localVarPath = `/api/meetup/{meetupId}/visitor/{visitorId}/write-desk-visit`
                .replace(`{${"meetupId"}}`, encodeURIComponent(String(meetupId)))
                .replace(`{${"visitorId"}}`, encodeURIComponent(String(visitorId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VisitorApi - functional programming interface
 * @export
 */
export const VisitorApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {number} visitorId Visitor identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkin(meetupId: number, visitorId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<VisitorData> {
            const localVarAxiosArgs = VisitorApiAxiosParamCreator(configuration).checkin(meetupId, visitorId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {VisitorData} visitorData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createVisitor(meetupId: number, visitorData: VisitorData, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<VisitorData> {
            const localVarAxiosArgs = VisitorApiAxiosParamCreator(configuration).createVisitor(meetupId, visitorData, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {number} visitorId Visitor identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteVisitor(meetupId: number, visitorId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = VisitorApiAxiosParamCreator(configuration).deleteVisitor(meetupId, visitorId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {Array<number>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteVisitors(meetupId: number, requestBody: Array<number>, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = VisitorApiAxiosParamCreator(configuration).deleteVisitors(meetupId, requestBody, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {number} visitorId Visitor identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllData(meetupId: number, visitorId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<VisitorFullData> {
            const localVarAxiosArgs = VisitorApiAxiosParamCreator(configuration).getAllData(meetupId, visitorId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} aid Account identifier.
         * @param {number} mid Meetup identifier.
         * @param {number} vid Visitor identifier.
         * @param {number} badgeId Badge identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDataInternal(aid: number, mid: number, vid: number, badgeId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<VisitorFullData> {
            const localVarAxiosArgs = VisitorApiAxiosParamCreator(configuration).getAllDataInternal(aid, mid, vid, badgeId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {number} visitorId Visitor identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVisitor(meetupId: number, visitorId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<VisitorData> {
            const localVarAxiosArgs = VisitorApiAxiosParamCreator(configuration).getVisitor(meetupId, visitorId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {string} qrToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVisitorByQRCode(meetupId: number, qrToken: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<VisitorData> {
            const localVarAxiosArgs = VisitorApiAxiosParamCreator(configuration).getVisitorByQRCode(meetupId, qrToken, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {string} vem Visitor email.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVisitorIdentification(meetupId: number, vem: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<VisitorIdentificationData> {
            const localVarAxiosArgs = VisitorApiAxiosParamCreator(configuration).getVisitorIdentification(meetupId, vem, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {VisitorImportRequestData} visitorImportRequestData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importVisitors(meetupId: number, visitorImportRequestData: VisitorImportRequestData, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<VisitorImportResultData> {
            const localVarAxiosArgs = VisitorApiAxiosParamCreator(configuration).importVisitors(meetupId, visitorImportRequestData, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {number} [gid] Group identifier.
         * @param {boolean} [_export] Export flag.
         * @param {string} [filter] Filter set for visitor in a form of json object {\&quot;field-name\&quot;: exact-value}.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listVisitors(meetupId: number, gid?: number, _export?: boolean, filter?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<VisitorData>> {
            const localVarAxiosArgs = VisitorApiAxiosParamCreator(configuration).listVisitors(meetupId, gid, _export, filter, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {number} visitorId Visitor identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        prePrintHook(meetupId: number, visitorId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<VisitorData> {
            const localVarAxiosArgs = VisitorApiAxiosParamCreator(configuration).prePrintHook(meetupId, visitorId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} token Access token.
         * @param {number} mid Meetup identifier.
         * @param {string} qrToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        qrConfirm(token: string, mid: number, qrToken: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = VisitorApiAxiosParamCreator(configuration).qrConfirm(token, mid, qrToken, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {SendCustomMailData} sendCustomMailData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendCustomMail(meetupId: number, sendCustomMailData: SendCustomMailData, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = VisitorApiAxiosParamCreator(configuration).sendCustomMail(meetupId, sendCustomMailData, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendRegistrationMail(meetupId: number, requestBody: Array<string>, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = VisitorApiAxiosParamCreator(configuration).sendRegistrationMail(meetupId, requestBody, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {number} visitorId Visitor identifier.
         * @param {VisitorData} visitorData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateVisitor(meetupId: number, visitorId: number, visitorData: VisitorData, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<VisitorData> {
            const localVarAxiosArgs = VisitorApiAxiosParamCreator(configuration).updateVisitor(meetupId, visitorId, visitorData, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {number} visitorId Visitor identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        writeBadgeStatus(meetupId: number, visitorId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = VisitorApiAxiosParamCreator(configuration).writeBadgeStatus(meetupId, visitorId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {number} visitorId Visitor identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        writeDeskVisit(meetupId: number, visitorId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = VisitorApiAxiosParamCreator(configuration).writeDeskVisit(meetupId, visitorId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * VisitorApi - factory interface
 * @export
 */
export const VisitorApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {number} visitorId Visitor identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkin(meetupId: number, visitorId: number, options?: any) {
            return VisitorApiFp(configuration).checkin(meetupId, visitorId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {VisitorData} visitorData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createVisitor(meetupId: number, visitorData: VisitorData, options?: any) {
            return VisitorApiFp(configuration).createVisitor(meetupId, visitorData, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {number} visitorId Visitor identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteVisitor(meetupId: number, visitorId: number, options?: any) {
            return VisitorApiFp(configuration).deleteVisitor(meetupId, visitorId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {Array<number>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteVisitors(meetupId: number, requestBody: Array<number>, options?: any) {
            return VisitorApiFp(configuration).deleteVisitors(meetupId, requestBody, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {number} visitorId Visitor identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllData(meetupId: number, visitorId: number, options?: any) {
            return VisitorApiFp(configuration).getAllData(meetupId, visitorId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} aid Account identifier.
         * @param {number} mid Meetup identifier.
         * @param {number} vid Visitor identifier.
         * @param {number} badgeId Badge identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDataInternal(aid: number, mid: number, vid: number, badgeId: number, options?: any) {
            return VisitorApiFp(configuration).getAllDataInternal(aid, mid, vid, badgeId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {number} visitorId Visitor identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVisitor(meetupId: number, visitorId: number, options?: any) {
            return VisitorApiFp(configuration).getVisitor(meetupId, visitorId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {string} qrToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVisitorByQRCode(meetupId: number, qrToken: string, options?: any) {
            return VisitorApiFp(configuration).getVisitorByQRCode(meetupId, qrToken, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {string} vem Visitor email.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVisitorIdentification(meetupId: number, vem: string, options?: any) {
            return VisitorApiFp(configuration).getVisitorIdentification(meetupId, vem, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {VisitorImportRequestData} visitorImportRequestData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importVisitors(meetupId: number, visitorImportRequestData: VisitorImportRequestData, options?: any) {
            return VisitorApiFp(configuration).importVisitors(meetupId, visitorImportRequestData, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {number} [gid] Group identifier.
         * @param {boolean} [_export] Export flag.
         * @param {string} [filter] Filter set for visitor in a form of json object {\&quot;field-name\&quot;: exact-value}.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listVisitors(meetupId: number, gid?: number, _export?: boolean, filter?: string, options?: any) {
            return VisitorApiFp(configuration).listVisitors(meetupId, gid, _export, filter, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {number} visitorId Visitor identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        prePrintHook(meetupId: number, visitorId: number, options?: any) {
            return VisitorApiFp(configuration).prePrintHook(meetupId, visitorId, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} token Access token.
         * @param {number} mid Meetup identifier.
         * @param {string} qrToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        qrConfirm(token: string, mid: number, qrToken: string, options?: any) {
            return VisitorApiFp(configuration).qrConfirm(token, mid, qrToken, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {SendCustomMailData} sendCustomMailData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendCustomMail(meetupId: number, sendCustomMailData: SendCustomMailData, options?: any) {
            return VisitorApiFp(configuration).sendCustomMail(meetupId, sendCustomMailData, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendRegistrationMail(meetupId: number, requestBody: Array<string>, options?: any) {
            return VisitorApiFp(configuration).sendRegistrationMail(meetupId, requestBody, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {number} visitorId Visitor identifier.
         * @param {VisitorData} visitorData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateVisitor(meetupId: number, visitorId: number, visitorData: VisitorData, options?: any) {
            return VisitorApiFp(configuration).updateVisitor(meetupId, visitorId, visitorData, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {number} visitorId Visitor identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        writeBadgeStatus(meetupId: number, visitorId: number, options?: any) {
            return VisitorApiFp(configuration).writeBadgeStatus(meetupId, visitorId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} meetupId Meetup identifier.
         * @param {number} visitorId Visitor identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        writeDeskVisit(meetupId: number, visitorId: number, options?: any) {
            return VisitorApiFp(configuration).writeDeskVisit(meetupId, visitorId, options)(axios, basePath);
        },
    };
};

/**
 * VisitorApi - object-oriented interface
 * @export
 * @class VisitorApi
 * @extends {BaseAPI}
 */
export class VisitorApi extends BaseAPI {
    /**
     * 
     * @param {number} meetupId Meetup identifier.
     * @param {number} visitorId Visitor identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VisitorApi
     */
    public checkin(meetupId: number, visitorId: number, options?: any) {
        return VisitorApiFp(this.configuration).checkin(meetupId, visitorId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} meetupId Meetup identifier.
     * @param {VisitorData} visitorData 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VisitorApi
     */
    public createVisitor(meetupId: number, visitorData: VisitorData, options?: any) {
        return VisitorApiFp(this.configuration).createVisitor(meetupId, visitorData, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} meetupId Meetup identifier.
     * @param {number} visitorId Visitor identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VisitorApi
     */
    public deleteVisitor(meetupId: number, visitorId: number, options?: any) {
        return VisitorApiFp(this.configuration).deleteVisitor(meetupId, visitorId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} meetupId Meetup identifier.
     * @param {Array<number>} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VisitorApi
     */
    public deleteVisitors(meetupId: number, requestBody: Array<number>, options?: any) {
        return VisitorApiFp(this.configuration).deleteVisitors(meetupId, requestBody, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} meetupId Meetup identifier.
     * @param {number} visitorId Visitor identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VisitorApi
     */
    public getAllData(meetupId: number, visitorId: number, options?: any) {
        return VisitorApiFp(this.configuration).getAllData(meetupId, visitorId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} aid Account identifier.
     * @param {number} mid Meetup identifier.
     * @param {number} vid Visitor identifier.
     * @param {number} badgeId Badge identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VisitorApi
     */
    public getAllDataInternal(aid: number, mid: number, vid: number, badgeId: number, options?: any) {
        return VisitorApiFp(this.configuration).getAllDataInternal(aid, mid, vid, badgeId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} meetupId Meetup identifier.
     * @param {number} visitorId Visitor identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VisitorApi
     */
    public getVisitor(meetupId: number, visitorId: number, options?: any) {
        return VisitorApiFp(this.configuration).getVisitor(meetupId, visitorId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} meetupId Meetup identifier.
     * @param {string} qrToken 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VisitorApi
     */
    public getVisitorByQRCode(meetupId: number, qrToken: string, options?: any) {
        return VisitorApiFp(this.configuration).getVisitorByQRCode(meetupId, qrToken, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} meetupId Meetup identifier.
     * @param {string} vem Visitor email.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VisitorApi
     */
    public getVisitorIdentification(meetupId: number, vem: string, options?: any) {
        return VisitorApiFp(this.configuration).getVisitorIdentification(meetupId, vem, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} meetupId Meetup identifier.
     * @param {VisitorImportRequestData} visitorImportRequestData 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VisitorApi
     */
    public importVisitors(meetupId: number, visitorImportRequestData: VisitorImportRequestData, options?: any) {
        return VisitorApiFp(this.configuration).importVisitors(meetupId, visitorImportRequestData, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} meetupId Meetup identifier.
     * @param {number} [gid] Group identifier.
     * @param {boolean} [_export] Export flag.
     * @param {string} [filter] Filter set for visitor in a form of json object {\&quot;field-name\&quot;: exact-value}.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VisitorApi
     */
    public listVisitors(meetupId: number, gid?: number, _export?: boolean, filter?: string, options?: any) {
        return VisitorApiFp(this.configuration).listVisitors(meetupId, gid, _export, filter, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} meetupId Meetup identifier.
     * @param {number} visitorId Visitor identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VisitorApi
     */
    public prePrintHook(meetupId: number, visitorId: number, options?: any) {
        return VisitorApiFp(this.configuration).prePrintHook(meetupId, visitorId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} token Access token.
     * @param {number} mid Meetup identifier.
     * @param {string} qrToken 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VisitorApi
     */
    public qrConfirm(token: string, mid: number, qrToken: string, options?: any) {
        return VisitorApiFp(this.configuration).qrConfirm(token, mid, qrToken, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} meetupId Meetup identifier.
     * @param {SendCustomMailData} sendCustomMailData 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VisitorApi
     */
    public sendCustomMail(meetupId: number, sendCustomMailData: SendCustomMailData, options?: any) {
        return VisitorApiFp(this.configuration).sendCustomMail(meetupId, sendCustomMailData, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} meetupId Meetup identifier.
     * @param {Array<string>} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VisitorApi
     */
    public sendRegistrationMail(meetupId: number, requestBody: Array<string>, options?: any) {
        return VisitorApiFp(this.configuration).sendRegistrationMail(meetupId, requestBody, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} meetupId Meetup identifier.
     * @param {number} visitorId Visitor identifier.
     * @param {VisitorData} visitorData 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VisitorApi
     */
    public updateVisitor(meetupId: number, visitorId: number, visitorData: VisitorData, options?: any) {
        return VisitorApiFp(this.configuration).updateVisitor(meetupId, visitorId, visitorData, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} meetupId Meetup identifier.
     * @param {number} visitorId Visitor identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VisitorApi
     */
    public writeBadgeStatus(meetupId: number, visitorId: number, options?: any) {
        return VisitorApiFp(this.configuration).writeBadgeStatus(meetupId, visitorId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} meetupId Meetup identifier.
     * @param {number} visitorId Visitor identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VisitorApi
     */
    public writeDeskVisit(meetupId: number, visitorId: number, options?: any) {
        return VisitorApiFp(this.configuration).writeDeskVisit(meetupId, visitorId, options)(this.axios, this.basePath);
    }

}


